<app-default-layout
  [title]="'<h1>Pre-sale activities</h1>'"
  [showBackToLanding]="true"
  [secondLevelNavItems]="[
    { url: '/pre-sale-activities', label: 'Flash Pre-sale Activities' | translate, active: true },
    { url: '/san-health', label: 'SAN Health Assessments' | translate }
  ]"
>
  <div class="container content">
    <div class="row">
      <div class="col-md-3 side-content">
        <div class="d-none d-lg-block" [innerHtml]="sideContent"></div>
        <a
          class="btn btn-outline-primary pt-10 mt-25"
          [routerLink]="['/previous-pre-sale-activities']"
        >
          {{ 'Previous pre-sales activities' | translate }}
          <span class="arrow"><img src="../../../../assets/images/arrow-right-blue.svg" /></span>
        </a>
      </div>
      <div class="col-md-9">
        <div [innerHtml]="bodyContent"></div>
        <div class="title">Complete your pre-sales activities below.</div>
        <ps-meeting-form></ps-meeting-form>
        <ps-assessment-form></ps-assessment-form>
        <div class="form-container">
          <div class="mb-20 mt-20 pt-20">
            <h2>
              Not sure if your potential client is NEW or has not purchased or upgraded an IBM
              FlashSystem array over the prior *36 months ?
            </h2>
            <p>*(Between January 1, 2019 and December 31, 2021)</p>
            <p>&nbsp;</p>
            <p>Complete the fields below and we will confirm&hellip;</p>
          </div>
          <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Name of customer' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="companyName"
                    type="text"
                    style="width: 100%"
                    placeholder="Customer name"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Country' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="country"
                    type="text"
                    style="width: 100%"
                    placeholder="Name of country"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-sm-12">
                <button class="btn btn-primary" type="submit">
                  {{ 'Submit' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="note-text" [innerHtml]="disclaimer"></div>
        <div class="row">
          <div class="col-md-4">
            <div class="link-box">
              <div class="title">Eligible products</div>
              <div class="text" [innerHtml]="bottomLeftBoxContent"></div>
              <div class="icon">
                <i class="far fa-file-download"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="link-box">
              <div class="title">Terms</div>
              <div class="text" [innerHtml]="bottomMiddleBoxContent"></div>
              <div class="icon">
                <i class="fas fa-external-link"></i>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="link-box">
              <div class="title">Help?</div>
              <div class="text" [innerHtml]="bottomRightBoxContent"></div>
              <div class="icon">
                <i class="far fa-envelope"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
