import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { LayoutService } from '../../../../app/layout/layout.service';
import { User } from 'src/app/models/user';
import { FormBuilder, Validators } from '@angular/forms';
import { SupportService } from 'src/app/support/support.service';
import { YearRequirementList } from 'src/app/account/points-summary-models';
import { AccountService } from 'src/app/account/account.service';
import { UserService } from 'src/app/user/user.service';
import { ContentService } from 'src/app/content/content.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  user: User = this.settingsProvder.settings.data.user;
  bannerContent: Content;
  bodyContent: string;

  sectionOneMainContent: string;
  sectionOneSideContent: string;
  sectionTwoMainContentHeader: string;
  sectionTwoMainContentFooter: string;
  sectionTwoMainContentForm: string;
  sectionTwoMainContentImage: string;
  sectionTwoSideContent: string;
  sectionThreeMainContent: string;
  sectionThreeSideContent: string;
  sectionTwoSecondMainContentImage: string;

  yearRequirements: YearRequirementList[];

  currentYear = new Date().getFullYear();

  contactUsForm = this.formBuilder.group({
    companyName: [null, Validators.required],
    country: [null, Validators.required],
  });

  carouselBanners: any[];

  constructor(
    private dialogService: DialogService,
    private settingsProvder: SettingsProvider,
    private accountService: AccountService,
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private userService: UserService,
    private contentService: ContentService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const subTitleField = this.baseContent.fields.find((f) => f.name === 'Subtitle');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const sectionOneMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section One Main Content',
    );
    const sectionOneSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section One Side Content',
    );
    const sectionTwoMainContentHeaderField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content Header',
    );
    const sectionTwoMainContentFooterField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content Footer',
    );
    const sectionTwoMainContentFormField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content Form',
    );
    const sectionTwoMainContentImageField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Side Content Image',
    );
    const sectionTwoSecondMainContentImageField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content Image',
    );
    const sectionTwoSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Side Content',
    );
    const sectionThreeMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Three Main Content',
    );
    const sectionThreeSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Three Side Content',
    );
    const bannerListField = this.baseContent.fields.find((f) => f.name === 'Banner List');

    this.sectionOneMainContent = sectionOneMainContentField.value.value;
    this.sectionOneSideContent = sectionOneSideContentField.value.value;
    this.sectionTwoMainContentHeader = sectionTwoMainContentHeaderField.value.value;
    this.sectionTwoMainContentFooter = sectionTwoMainContentFooterField.value.value;
    this.sectionTwoMainContentForm = sectionTwoMainContentFormField.value.value;
    this.sectionTwoMainContentImage = sectionTwoMainContentImageField.value.url;
    this.sectionTwoSideContent = sectionTwoSideContentField.value.value;
    this.sectionThreeMainContent = sectionThreeMainContentField.value.value;
    this.sectionThreeSideContent = sectionThreeSideContentField.value.value;
    this.sectionTwoSecondMainContentImage = sectionTwoSecondMainContentImageField.value.url;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      subTitle: subTitleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.contentService
      .getContentById(bannerListField.value.value, this.translateService.currentLang, true)
      .subscribe((response) => {
        const content = response.data.contentById;

        this.carouselBanners = content.children.map((childContent) => {
          const image = childContent.fields.find((f) => f.name === 'Banner Image');
          const altText = childContent.fields.find((f) => f.name === 'Image Alternative Text');

          return {
            image: image?.value.url,
            url: childContent.properties.url?.value,
            target: childContent.properties.target?.value,
            altText: altText?.value.value,
          };
        });
      });

    this.accountService.getYearRequirements().then((yearRequirements) => {
      this.yearRequirements = yearRequirements;
    });
  }

  onSubmit() {
    if (this.contactUsForm.valid) {
      this.supportService
        .submitHelpRequest(this.contactUsForm.value, 'flash/customer-check')
        .subscribe((data) => {
          this.dialogService.openModal('Your Contact Form has been submitted.');
          this.contactUsForm.reset();
        });
    } else {
      const controlkeys = Object.keys(this.contactUsForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.contactUsForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          errorFieldMessages.push(`${label} is required.`);
        }
      }

      this.dialogService.openModal(errorFieldMessages, '');
    }
  }

  videoClicked(e): void {
    this.userService.watchVideo();
  }
}
