import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IsLoadingService } from '@service-work/is-loading';
import { Content } from 'src/app/models/content.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

import { ApplicationOptionItem } from '../models/application-form-options';
import { CardUpdate, MasterCardAddress } from '../models/card-update';
import { filter, take } from 'rxjs/operators';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { UserKyiAccount } from 'src/app/core/model/user/user-kyi-account';
import { UserKyiAccountDetail } from 'src/app/core/model/user/user-kyi-account-detail';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { LayoutService } from 'src/app/layout/layout.service';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';

@Component({
  selector: 'app-report-lost',
  templateUrl: './report-lost.component.html',
  styleUrls: ['./report-lost.component.scss'],
})
export class ReportLostComponent implements OnInit {
  @Input()
  baseContent: Content;

  public isLoading$ = this.isLoadingService.isLoading$;
  public loading: boolean;
  reportSuccessful: boolean;
  body: string;
  lostTypes: any = [];
  states: ApplicationOptionItem[];
  submitForm: CardUpdate;
  address: MasterCardAddress;
  reportMissingForm = new FormGroup({
    cardStatus: new FormControl('', Validators.required),
    addressLine1: new FormControl('', Validators.required),
    addressLine2: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl(''),
    postalCode: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
  });

  constructor(
    private layoutService: LayoutService,
    private bankingRest: BankingRestService,
    private dialogService: DialogNgbService,
    private isLoadingService: IsLoadingService,
    private bankingStore: BankingStoreService,
  ) {}

  ngOnInit(): void {
    this.bankingRest.getMissingTypes().subscribe((data) => {
      console.log(data);
      this.lostTypes = data;
    });

    this.bankingRest.getApplicationOptions().then((options) => {
      this.reportMissingForm.controls.country.patchValue(options.countryName);
      this.states = options.states;
      if (options.countryName == 'United States' || options.countryName === 'Canada') {
        this.reportMissingForm.controls.state.setValidators(Validators.required);
      } else {
        this.reportMissingForm.controls.state.patchValue('Other');
      }
    });
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: '',
      imageUrl: bannerField.value.url,
    });
  }

  onSubmit() {
    this.loading = true;
    if (this.reportMissingForm.valid) {
      const form = this.reportMissingForm.getRawValue();

      this.bankingStore.userAccounts$
        .pipe(filter(Boolean), take(1))
        .subscribe((userAccounts: UserKyiAccount[]) => {
          const activeUserAccount = userAccounts.find(
            (userAccount) => userAccount.statusName === 'MasterCardAccountActive',
          );

          if (activeUserAccount) {
            this.bankingStore.currentUserAccountDetail$
              .pipe(filter(Boolean), take(1))
              .subscribe((userAccountDetail: UserKyiAccountDetail) => {
                this.address = {
                  address1: form.addressLine1,
                  address2: form.addressLine2,
                  postCode: form.postalCode,
                  state: form.state,
                  city: form.city,
                  countryCode: form.country,
                };
                this.submitForm = {
                  cardStatus: form.cardStatus,
                  cardType: userAccountDetail.type,
                  countryId: form.country.value,
                  state: [form.state],
                  address: this.address,
                };
                this.bankingRest
                  .reportMissing(this.submitForm, userAccountDetail.id)
                  .subscribe((data) => {
                    if (data) {
                      this.loading = false;
                      this.reportSuccessful = true;
                    } else {
                      this.loading = false;
                      this.dialogService.openNotification([
                        'An error has occurred, please contact serve@kyirewards.com for further assistance',
                      ]);
                    }
                  });
              });

            this.bankingStore.getUserAccountDetail(activeUserAccount.id, true);
          } else {
            this.dialogService.openNotification([
              'We do not have an Active card allocated to you, please contact serve@kyirewards.com for further assistance',
            ]);
          }
        });

      this.bankingStore.getUserAccounts();
    } else {
      this.loading = false;
      let messages: string[] = [];
      Object.keys(this.reportMissingForm.controls).forEach((key) => {
        if (!this.reportMissingForm.get(key).valid) {
          let firstLetter = key.substring(0, 1).toLocaleUpperCase();
          let nameWithoutFirst = key.substring(1, key.length);
          let name = firstLetter + nameWithoutFirst;
          messages.push(name + ' is required;');
        }
      });
      this.dialogService.openValidation(messages, 'Please check the application');
    }
  }
}
