import { Component, Input, OnInit } from '@angular/core';
import { Content } from 'src/app/models/content.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { filter, take } from 'rxjs/operators';
import { UserKyiAccount } from 'src/app/core/model/user/user-kyi-account';
import { LayoutService } from 'src/app/layout/layout.service';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';

@Component({
  selector: 'app-view-pin',
  templateUrl: './view-pin.component.html',
  styleUrls: ['./view-pin.component.scss'],
})
export class ViewPinComponent implements OnInit {
  @Input()
  baseContent: Content;

  body: string;
  pinImage: string;
  accountId: number;
  public updating = false;

  constructor(
    private layoutService: LayoutService,
    private bankingStore: BankingStoreService,
    private dialogService: DialogService,
    private bankingRest: BankingRestService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  viewPin() {
    this.updating = true;

    this.bankingStore.userAccounts$
      .pipe(filter(Boolean), take(1))
      .subscribe((userAccounts: UserKyiAccount[]) => {
        const activeUserAccount = userAccounts.find(
          (userAccount) => userAccount.statusName === 'MasterCardAccountActive',
        );

        if (activeUserAccount) {
          this.bankingRest.getPin(activeUserAccount.id).then((data) => {
            if (data) {
              this.updating = false;
              if (data.pinImage) {
                this.pinImage = data.pinImage;
                this.dialogService.openModal(
                  '<img src="data:image/jpeg;base64,' + this.pinImage + '"/>',
                );
              } else {
                this.dialogService.openModal(
                  'Unfortunately we cannot display your PIN at this time',
                );
              }
            } else {
              this.updating = false;
              this.dialogService.openModal('Unfortunately we cannot display your PIN at this time');
            }
          });
        } else {
          this.dialogService.openModal('Unfortunately we cannot display your PIN at this time');
        }

        this.updating = false;
      });

    this.bankingStore.getUserAccounts();
  }
}
