import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { DefaultTemplateComponent } from '../layout/default-template/default-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselSliderComponent } from './components/carousel-slider/carousel-slider.component';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [HomeComponent, CarouselSliderComponent, HomeLayoutComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgbCarouselModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
