<div class="layout-boxed content">
  <form [formGroup]="requestReplacementForm" (ngSubmit)="onSubmit()" *ngIf="!requestSuccessful">
    <div class="row">
      <div class="col-sm-12">
        <h2>{{ 'Request Replacement Card' | translate }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" [innerHtml]="body"></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ 'Address Line 1' | translate }} *</label>
          <input class="form-control" type="text" formControlName="addressLine1" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ 'Address Line 2' | translate }} *</label>
          <input class="form-control" type="text" formControlName="addressLine2" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ 'City' | translate }} *</label>
          <input class="form-control" type="text" formControlName="city" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ 'State' | translate }}</label>
          <select formControlName="state" class="form-control">
            <option disabled>{{ 'Select' | translate }}</option>
            <option value="{{ state.key }}" *ngFor="let state of states">{{ state.value }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ 'Zip / Postal Code' | translate }} *</label>
          <input class="form-control" type="text" formControlName="postalCode" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ 'Country' | translate }}</label>
          <input class="form-control" type="text" formControlName="country" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <button type="submit" class="btn btn-primary">{{ 'Request' | translate }}</button>
      </div>
    </div>
  </form>
  <div *ngIf="requestSuccessful">
    <p>{{ 'Requesting new card complete' | translate }}</p>
  </div>
</div>
