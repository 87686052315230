<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Congratulations!<br />
    You have successfully claimed a sale.
  </h4>
</div>

<div class="modal-body m-3 d-flex flex-column justify-content-center">
  <span [innerHtml]="charityDonationDialogContent"></span>
  <form [formGroup]="formGroup">
    <div class="form-group">
      <label>{{ 'Choose an amount to donate' | translate }}:</label>
      <input class="form-control" type="text" formControlName="amount" placeholder="USD" />
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-start align-items-center">
  <button
    class="btn btn-primary submit-button"
    type="submit"
    (click)="donate()"
    [disabled]="!formGroup.valid || donatedPoints > saleAction.points"
  >
    {{ 'Submit' | translate }}
    <span class="arrow">→</span>
  </button>
  <button class="btn btn-inverse" type="button" (click)="close()">
    {{ 'Cancel' | translate }}
  </button>
</div>
