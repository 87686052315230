<div class="modal-header m-3 d-flex flex-column justify-content-center">Privacy Policy</div>
<div class="modal-body m-3 d-flex flex-column justify-content-center">
  <p>What Is the Information We Collect and Use?</p>
  <p>
    IBM Flash Rewards Incentive uses information that we collect to operate, maintain and provide
    you with features and function that enhance your learning experience. We may also use the
    information to diagnose and resolve technical problems, maintain security, personalize content,
    and remember information to help you efficiently use your account. The information we collect
    will only be used for the purposes of administering the KYI offering.
  </p>
  <p>What is the Information You Provide?</p>
  <p>
    Information about you such as your name, email address, location, company name, and for select
    regions tax identifier number is used in this application. This information along with education
    completion status received from IBM Training and IBM Certification database will be recorded,
    and used for the sole purpose of administering the Flash Rewards Incentives. Course data will be
    stored for a period up to three years.
  </p>
  <p>
    IBM uses a 3rd party agency MotivForce to administer the Flash Rewards Incentives. MotivForce
    and their staff follow all IBM privacy policies. If you have any questions regarding the use of
    your data you can contact us direct at
    <a href="mailto:flash@kyirewards.com">flash@kyirewards.com</a> OR complete the ‘Contact Us’ page
    submission form.
  </p>
  <p>You have:</p>
  <ul>
    <li>Right to a copy of PI data</li>
    <li>Right to Request Deletion of PI data</li>
    <li>
      Right to be free from Discrimination, including information on any financial incentive schemes
      in place
    </li>
  </ul>
</div>
<div class="modal-footer d-flex justify-content-end">
  <div class="d-flex justify-content-end">
    <button class="btn" type="button" (click)="activeModal.close()">
      {{ 'Close' | translate }}
    </button>
  </div>
</div>
