import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsRestService } from '../api/rest/settings-rest.service';
import { IsLoadingService } from '@service-work/is-loading';
import { Settings } from 'src/app/entities/settings';
import { MxLoggerService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class SettingsStoreService {
  private readonly _settings = new BehaviorSubject<Settings>(null);
  readonly settings$ = this._settings.asObservable();

  constructor(
    private settingsRest: SettingsRestService,
    private isLoadingService: IsLoadingService,
  ) {}

  get settings(): Settings {
    return this._settings.value;
  }

  getUserSettings(): void {
    this.isLoadingService.add(
      this.settingsRest.getUserSettings().subscribe((settings: Settings) => {
        this._settings.next(settings);
      }),
    );
  }
}
