<ng-template appLayoutHost></ng-template>

<ng-container *ngIf="loading">
  <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="50">
    <svg:rect x="0" y="0" width="100%" height="40" />
  </content-loader>
  <div class="layout-boxed content">
    <div class="row">
      <div class="col-sm-12">
        <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="40">
          <svg:rect x="0" y="0" width="100%" height="5" />
          <svg:rect x="0" y="10" width="100%" height="5" />
          <svg:rect x="0" y="20" width="100%" height="5" />
          <svg:rect x="0" y="30" width="70%" height="5" />
        </content-loader>
        <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="40">
          <svg:rect x="0" y="0" width="30" height="30" />
        </content-loader>
        <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="80">
          <svg:rect x="0" y="0" width="100%" height="5" />
          <svg:rect x="0" y="10" width="30%" height="5" />
          <svg:rect x="0" y="20" width="100%" height="5" />
          <svg:rect x="0" y="30" width="80%" height="5" />
        </content-loader>
      </div>
    </div>
  </div>
</ng-container>
