import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { skip, take } from 'rxjs/operators';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-login-admin-container',
  templateUrl: './login-admin-container.component.html',
  styleUrls: ['./login-admin-container.component.scss'],
})
export class LoginAdminContainerComponent implements OnInit {
  loading = false;

  formGroup: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required]],
    password: [null, [Validators.required]],
  });

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private mxLogger: MxLoggerService,
    private settingsProvider: SettingsProvider,
  ) {}

  async ngOnInit() {
    try {
      const user = await this.authService.currentUserInfo();
      console.log(user);
      if (user) {
        // already logged in so go to sample page
        console.log('LOGIN PAGE: Redirect Home');
        this.router.navigate(['/']);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async login() {
    const l = await this.authService.federatedSignIn();
  }

  async onSubmit() {
    if (this.formGroup.valid) {
      const formData = this.formGroup.getRawValue();

      this.loading = true;

      await this.authService.signIn(formData.email, formData.password).then(() => {
        window.location.reload();
      });
    } else {
      this.mxLogger.trace('LoginContainer', 'Form is still invalid.');
    }
  }

  async signInWithGoogle() {
    await this.authService.googleSignIn();
  }
}
