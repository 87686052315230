import { Component, Input, OnInit } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent extends AbstractDialogComponent {
  constructor(public activeModal: NgbActiveModal) {
    super();
  }
}
