import { Component, Input, OnInit } from '@angular/core';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';
import { ContentService } from 'src/app/content/content.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from 'src/app/layout/layout.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SupportService } from 'src/app/support/support.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-brocade-page',
  templateUrl: './presales-brocade-page.component.html',
  styleUrls: ['./presales-brocade-page.component.scss'],
})
export class PresalesBrocadePageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  bodyContent: string;
  sideContent: string;
  bottomLeftBoxContent: string;
  bottomMiddleBoxContent: string;
  bottomRightBoxContent: string;
  disclaimer: string;

  contactUsForm = this.formBuilder.group({
    companyName: [null, Validators.required],
    country: [null, Validators.required],
  });

  constructor(
    private layoutService: LayoutService,
    private contentService: ContentService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyContent = this.baseContent.fields.find((f) => f.name === 'Body');
    const mobileBannerField = this.baseContent.fields.find((f) => f.name === 'Mobile Banner');
    const tableBannerField = this.baseContent.fields.find((f) => f.name === 'Tablet Banner');
    const sideContent = this.baseContent.fields.find((f) => f.name === 'Side Content');
    const bottomLeftBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Left Box Content',
    );
    const bottomMiddleBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Middle Box Content',
    );
    const bottomRightBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Right Box Content',
    );
    const disclaimer = this.baseContent.fields.find((f) => f.name === 'Disclaimer ');

    this.bodyContent = bodyContent.value.value;
    this.sideContent = sideContent.value.value;
    this.bottomLeftBoxContent = bottomLeftBoxContent.value.value;
    this.bottomMiddleBoxContent = bottomMiddleBoxContent.value.value;
    this.bottomRightBoxContent = bottomRightBoxContent.value.value;
    this.disclaimer = disclaimer ? disclaimer.value.value : '';

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  onSubmit() {
    if (this.contactUsForm.valid) {
      this.supportService
        .submitHelpRequest(this.contactUsForm.value, 'flash/customer-check')
        .subscribe((data) => {
          this.dialogService.openModal('Your Contact Form has been submitted.');
          this.contactUsForm.reset();
        });
    } else {
      const controlkeys = Object.keys(this.contactUsForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.contactUsForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          errorFieldMessages.push(`${label} is required.`);
        }
      }

      this.dialogService.openModal(errorFieldMessages, '');
    }
  }
}
