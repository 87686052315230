import { Component, OnInit, Input } from '@angular/core';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';
import { LayoutService } from '../layout.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { DialogNgbService, MxLoggerService } from '@motivforce/mx-library-angular';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
})
export class ArticleDetailComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  body: string;
  backLink: string;
  isIncentive: boolean;
  canClaim: boolean;
  submitRequestDialog: string;

  constructor(
    private layoutService: LayoutService,
    private settingsProvider: SettingsProvider,
    private dialog: DialogNgbService,
    private mxLogger: MxLoggerService,
  ) {}

  ngOnInit() {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.claims
    ) {
      const claims = this.settingsProvider.settings.data.claims;
      const canClaim = claims.find((c) => c === 'user.canClaim');
      const nominatedCanClaim = claims.find((c) => c === 'user.nominatedFirmClaim');

      if (canClaim || nominatedCanClaim) {
        this.canClaim = true;
      }
    }
    const imageField = this.baseContent.fields.find((f) => f.name === 'Article Image');
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const detailField = this.baseContent.fields.find((f) => f.name === 'Detail Text');

    this.backLink = this.baseContent.properties.backLink
      ? this.baseContent.properties.backLink.value
      : '..';
    this.isIncentive = this.baseContent.properties.isIncentive
      ? this.baseContent.properties.isIncentive.value
      : false;

    this.submitRequestDialog = this.baseContent.properties.submitRequestDialog
      ? this.baseContent.properties.submitRequestDialog.value
      : null;

    this.body = detailField ? detailField.value.value : '';

    this.layoutService.changeBanner({
      imageUrl: imageField.value.url,
      title: titleField.value.value,
    });
  }

  onSubmitRequest(): void {
    this.dialog.openFeatureDialog(this.submitRequestDialog, [], { size: 'lg' }).then(
      () => {
        this.mxLogger.debug(
          'ArticleDetailComponent',
          'onSubmitRequest() ' + this.submitRequestDialog + ' closed',
        );
      },
      (reason) => {
        this.mxLogger.trace(
          'ArticleDetailComponent',
          'onSubmitRequest() ' + this.submitRequestDialog + ' dismissed',
        );
      },
    );
  }
}
