import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-choose-company',
  templateUrl: './choose-company.component.html',
  styleUrls: ['./choose-company.component.scss'],
})
export class ChooseCompanyComponent implements OnInit {
  baseContent: Content;

  body: string;
  companyList: any[] = [];
  updating: boolean = false;

  chooseCompanyForm = new FormGroup({
    id: new FormControl('', Validators.required),
  });

  constructor(
    private layoutService: LayoutService,
    private settingsProvider: SettingsProvider,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.companies
    ) {
      let companies = this.settingsProvider.settings.data.user.companies;
      if (companies.length > 1) {
        companies.forEach((company) => {
          this.companyList.push(company);
        });
      }
    }

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  onSubmit(): void {
    this.updating = true;

    const form = this.chooseCompanyForm.getRawValue();
    this.userService
      .setPrimaryCompany(form.id)
      .then(() => this.settingsProvider.refreshUserSettings())
      .then(() => this.router.navigate(['/']).finally(() => (this.updating = false)));
  }
}
