import { Component, OnInit, Input } from '@angular/core';
import { Content } from 'src/app/models/content.model';
import { LayoutService } from '../layout.service';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
})
export class DefaultTemplateComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;
  body: SafeHtml;
  constructor(private layoutService: LayoutService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = this.sanitizer.bypassSecurityTrustHtml(bodyField.value.value);

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }
}
