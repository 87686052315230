import { Component, OnInit } from '@angular/core';
import { AbstractDialogComponent, MxLoggerService } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-primary-tax-id-info-dialog',
  templateUrl: './primary-tax-id-info-dialog.component.html',
  styleUrls: ['./primary-tax-id-info-dialog.component.scss'],
})
export class PrimaryTaxIdInfoDialogComponent extends AbstractDialogComponent implements OnInit {
  content: string;

  constructor(public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs.length > 0) {
      this.content = this.inputs[0];
    }
  }
}
