import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { AbstractDialogComponent, MxLoggerService } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportService } from 'src/app/support/support.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';

@Component({
  selector: 'sp-missing-invoices',
  templateUrl: './missing-invoices.component.html',
  styleUrls: ['./missing-invoices.component.scss'],
})
export class MissingInvoicesComponent extends AbstractDialogComponent implements OnInit {
  incentives: any[];
  partNumbers: any[];
  invoices = [
    {
      id: 0,
      label: 'Select',
    },
    {
      id: 1,
      label: '1',
    },
    {
      id: 2,
      label: '2',
    },
    {
      id: 3,
      label: '3',
    },
    {
      id: 4,
      label: '4',
    },
  ];

  invoiceAmountSelected: boolean;
  missingInvoicesForm: FormGroup;
  content: string;

  constructor(
    public fb: FormBuilder,
    private supportService: SupportService,
    public settingsProvider: SettingsProvider,
    private dialogService: DialogService,
    private mxLogger: MxLoggerService,
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  public requestForm = {};

  ngOnInit() {
    this.initMissingInvoicesForm();

    this.missingInvoicesForm.controls.email.patchValue(
      this.settingsProvider.settings.data.user.email,
    );

    this.missingInvoicesForm.controls.name.patchValue(
      `${this.settingsProvider.settings.data.user.firstName} ${this.settingsProvider.settings.data.user.lastName}`,
    );
    let company = this.settingsProvider.settings.data.user.companies.find((c) => c.isPrimary);
    if (company) {
      this.missingInvoicesForm.controls.companyName.patchValue(company.name);
      this.missingInvoicesForm.controls.companyCeid.patchValue(company.organizationNumber);
    }

    this.supportService.getCurrentIncentiveList().then((data) => {
      this.incentives = data;
    });
    if (this.inputs.length > 0) {
      this.content = this.inputs[0];
    }
  }

  initMissingInvoicesForm() {
    return (this.missingInvoicesForm = new FormGroup({
      incentives: new FormControl('', [Validators.required]),
      partNumber: new FormControl('', [Validators.required]),
      companyName: new FormControl(''),
      companyCeid: new FormControl(''),
      email: new FormControl(''),
      name: new FormControl(''),
      invoiceDate: new FormControl('', [Validators.required]),
      endCustomerName: new FormControl('', [Validators.required]),
      serialNumber: new FormControl('', [Validators.required]),
    }));
  }

  incentiveSelected(e) {
    if (e) {
      let incentiveId = this.incentives.find((incentive) => {
        incentive.name === e;
        this.supportService.getCurrentProductsList(incentive.id).then((data) => {
          this.partNumbers = data;
        });
      });
    }
  }

  onSubmit() {
    if (this.missingInvoicesForm.valid) {
      _.merge(this.requestForm, this.missingInvoicesForm.value);
      this.supportService
        .submitHelpRequest(this.requestForm, 'missinginvoices/flash')
        .subscribe((data) => {
          this.dialogService.openModal('Your Missing Invoices request has been submitted.');
          this.initMissingInvoicesForm();
          this.activeModal.close();
        });
    } else {
      this.mxLogger.trace('formGroup:', this.missingInvoicesForm);
      const controlkeys = Object.keys(this.missingInvoicesForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.missingInvoicesForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);
          if (label === 'Part Number') {
            label = 'Eligible Product';
          }

          if (controlkeys[i] === 'email') {
            errorFieldMessages.push(`A valid email address is required.`);
          } else {
            errorFieldMessages.push(`${label} is required.`);
          }
        }
      }

      this.dialogService.openModal(errorFieldMessages, 'Important');
    }
  }

  close(): void {
    this.activeModal.close(null);
  }
}
