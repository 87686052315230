<app-default-layout
  [title]="'<h1>Pre-sale activities</h1>'"
  [showBackToLanding]="true"
  [secondLevelNavItems]="[
    { url: '/pre-sale-activities', label: 'Flash Pre-sale Activities' | translate },
    { url: '/san-health', label: 'SAN Health Assessments' | translate, active: true }
  ]"
>
  <div class="container content">
    <div class="row">
      <div class="col-md-3 side-content">
        <div class="d-none d-lg-block" [innerHtml]="sideContent"></div>
        <a
          class="btn btn-outline-primary pt-10 mt-25"
          [routerLink]="['/previous-pre-sale-activities']"
          [queryParams]="{ showTechnicalAssessments: 1 }"
        >
          {{ 'Previous pre-sales activities' | translate }}
          <span class="arrow"><img src="../../../../assets/images/arrow-right-blue.svg" /></span>
        </a>
      </div>
      <div class="col-md-9">
        <div [innerHtml]="bodyContent"></div>
        <div class="title">Complete your pre-sales activities below.</div>
        <ps-technical-assessment-form></ps-technical-assessment-form>

        <div class="note-text" [innerHtml]="disclaimer"></div>
      </div>
    </div>
  </div>
</app-default-layout>
