import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { UserService } from 'src/app/user/user.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { PreSalesStoreService } from 'src/app/core/store/presales-store.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { MxLoggerService, ReferenceData } from '@motivforce/mx-library-angular';
import { Subject, Observable } from 'rxjs';
import { MeetingDetails } from 'src/app/models/meeting-details';
import { MeetingType } from 'src/app/models/meeting-type';
import { filter, takeUntil, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ProgramPeriod } from 'src/app/models/program-period';
import { ProgramStoreService } from 'src/app/core/store/program-store.service';
import { CompanyStoreService } from 'src/app/core/store/company-store.service';

@Component({
  selector: 'ps-meeting-form',
  templateUrl: './meeting-form.component.html',
  styleUrls: ['./meeting-form.component.scss'],
})
export class MeetingFormComponent implements OnInit {
  user: User;
  submitting: boolean = false;
  isPrc = false;

  meetingForm: FormGroup = this.fb.group({
    userId: [''],
    meetingDate: ['', Validators.required],
    customerName: ['', Validators.required],
    dealRegistrationNumber: ['', Validators.required],
    ibmFirstName: ['', Validators.required],
    ibmLastName: ['', Validators.required],
    salesRepEmail: ['', Validators.required],
    meetingTypeId: ['', Validators.required],
  });

  meetingTypes: MeetingType[];
  programPeriod: ProgramPeriod;

  userTypeahead = (text$: Observable<string>) =>
    text$.pipe(
      filter((searchText) => searchText.length > 2),
      distinctUntilChanged(),
      switchMap((searchText) => this.companyStore.searchUsersInCompany(searchText))
    );

  onDestroy$: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private settingsProvider: SettingsProvider,
    private preSalesStore: PreSalesStoreService,
    private programStore: ProgramStoreService,
    private companyStore: CompanyStoreService,
    private router: Router,
    private mxLogger: MxLoggerService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.user = this.settingsProvider.settings.data.user;

    this.isPrc = this.user?.userRole.name === 'kyi.flash.firm.prc.access';

    this.preSalesStore.meetingTypes$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((meetingTypes: MeetingType[]) => {
        this.meetingTypes = meetingTypes;
      });

    this.preSalesStore.getMeetingTypes();

    this.programStore.currentProgramPeriod$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((programPeriod: ProgramPeriod) => {
        this.programPeriod = programPeriod;
        if (this.programPeriod) {
          this.programPeriod.startDate = '2022-10-01';
          this.programPeriod.endDate = '2022-12-31';
        }
      });

    this.programStore.getCurrentProgramPeriod();
  }

  onSubmit() {
    this.submitting = true;
    if (this.meetingForm.valid) {
      const meetingDetails: MeetingDetails = {
        ...this.meetingForm.value,
        userId: this.meetingForm.value.userId?.id,
      };
      this.preSalesStore.submitMeetingDetails(meetingDetails).then((success) => {
        if (success) {
          this.meetingForm.reset();
        }
        this.submitting = false;
      });
    } else {
      this.mxLogger.trace('formGroup:', this.meetingForm);
      const controlkeys = Object.keys(this.meetingForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.meetingForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          if (controlkeys[i] === 'email') {
            errorFieldMessages.push(`A valid email address is required.`);
          } else {
            errorFieldMessages.push(`${label} is required.`);
          }
        }
      }

      this.dialogService.openModal(errorFieldMessages, 'Important');
      this.submitting = false;
    }
  }

  formatUsername(user: ReferenceData) {
    return user.name;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
