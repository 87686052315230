import { Injectable } from '@angular/core';
import { ApiRestService, BaseSiteRestService, MxLoggerService, ReferenceData } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyRestService extends BaseSiteRestService {
  constructor(private apiRest: ApiRestService, private mxLogger: MxLoggerService) {
    super(environment.api.core.baseUrl, environment.api.core.path.member, 'companies');
  }

  searchUsersInCompany(searchText: string): Observable<ReferenceData[]> {
    return this.apiRest.post(`${this.coreBaseUrl}/members/companies/users/search/`, { searchText });
  }
}
