import { Component, OnInit, Input } from '@angular/core';
import { ParentPanel } from './parent-panel.model';
import { Panel } from '../accordion/panel.model';

@Component({
  selector: 'ui-nested-accordion',
  templateUrl: './nested-accordion.component.html',
  styleUrls: ['./nested-accordion.component.scss']
})
export class NestedAccordionComponent implements OnInit {
  @Input()
  categories: ParentPanel[];

  constructor() { }

  ngOnInit() {}

}
