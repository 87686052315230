import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ContentItem } from '../../entities/content-item';
import { ContentService } from '../content.service';
import { ActivatedRoute } from '@angular/router';
import { ContentHostDirective } from '../content-host.directive';
import mapper from '../shared/type-mapper';
import { DisplayComponent } from '../shared/display.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'm9-content-injector',
  templateUrl: './content-injector.component.html',
  styleUrls: ['./content-injector.component.scss'],
})
export class ContentInjectorComponent implements OnInit {
  public contentItem: ContentItem = null;
  public loading = true;

  @Input()
  public contentField: string;

  private componentMap = mapper;
  componentRef: any;

  @ViewChild(ContentHostDirective, { static: true }) contentHost: ContentHostDirective;

  constructor(
    private route: ActivatedRoute,
    private contentService: ContentService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    // contentService.loadingContent.subscribe(loading => {
    //   this.loading = loading;
    // });
    // contentService.content.subscribe(contentItem => {
    //   this.contentItem = contentItem;
    //   if (this.contentItem) {
    //     this.initDisplayComponent();
    //   }
    // });
  }

  ngOnInit() {}

  initDisplayComponent() {
    console.log(this.contentItem);
    console.log(this.contentField);
    const field = this.contentItem.fields.find(f => f.name === this.contentField);

    console.log(field);

    if (field) {
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.componentMap[field.type].component,
    );

    const viewContainerRef = this.contentHost.viewContainerRef;
    // viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef = componentRef;
    (componentRef.instance as DisplayComponent).field = field;
  }
}
