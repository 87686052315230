<div class="layout-boxed content">
  <div class="row">
    <div class="col-md-12" [innerHtml]="body"></div>
  </div>
  <!--Put status boxes here-->
  <div class="row">
    <div class="col-md-12">
      <app-mastercard-application
        [applicationStatus]="applicationStatus"
      ></app-mastercard-application>
    </div>
  </div>
</div>
