import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { SettingsStoreService } from '../core/store/settings-store.service';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  dateFormatDelimiter = '-';
  dateFormatYearIndex = -1;
  dateFormatMonthIndex = -1;
  dateFormatDayIndex = -1;

  constructor(private settingsStore: SettingsStoreService) {
    super();

    this.settingsStore.settings$.pipe(filter(Boolean), take(1)).subscribe(() => {
      const dateFormat = this.settingsStore.settings.user.program.dateFormat;

      if (dateFormat.includes('.')) {
        this.dateFormatDelimiter = '.';
      } else if (dateFormat.includes('/')) {
        this.dateFormatDelimiter = '/';
      }

      const dateFormatParts = dateFormat.trim().split(this.dateFormatDelimiter);

      this.dateFormatYearIndex = dateFormatParts.findIndex(
        (dateFormatPart) => dateFormatPart === 'yyyy',
      );
      this.dateFormatMonthIndex = dateFormatParts.findIndex(
        (dateFormatPart) => dateFormatPart === 'MM',
      );
      this.dateFormatDayIndex = dateFormatParts.findIndex(
        (dateFormatPart) => dateFormatPart === 'dd',
      );
    });
  }

  parse(value: string): NgbDateStruct {
    let dateStruct: NgbDateStruct;

    if (value) {
      const dateParts = value.trim().split(this.dateFormatDelimiter);
      if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        dateStruct = {
          year:
            toInteger(dateParts[this.dateFormatYearIndex]) > 1000
              ? toInteger(dateParts[this.dateFormatYearIndex])
              : null,
          month: toInteger(dateParts[this.dateFormatMonthIndex]),
          day: toInteger(dateParts[this.dateFormatDayIndex]),
        };
      }
    }

    return dateStruct;
  }

  format(date: NgbDateStruct): string {
    let formattedDate = '';

    if (date) {
      let index = 0;

      while (index < 3) {
        if (index === this.dateFormatDayIndex) {
          formattedDate += `${isNumber(date.day) ? padNumber(date.day) : ''}`;
        } else if (index === this.dateFormatMonthIndex) {
          formattedDate += `${isNumber(date.month) ? padNumber(date.month) : ''}`;
        } else if (index === this.dateFormatYearIndex) {
          formattedDate += `${date.year}`;
        }

        if (index < 2) {
          formattedDate += `${this.dateFormatDelimiter}`;
        }
        index++;
      }
    }

    return formattedDate;
  }
}
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}
