<form [formGroup]="mastercardApplicationForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Title' | translate }} *</label>
        <select formControlName="title" class="form-control">
          <option disabled>{{ 'Select' | translate }}</option>
          <option value="{{ title.key }}" *ngFor="let title of titles">{{ title.value }}</option>
        </select>
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'First Name' | translate }}</label>
        <input
          class="form-control"
          type="text"
          readonly
          formControlName="firstName"
          style="border: 0"
        />
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Last Name' | translate }}</label>
        <input
          class="form-control"
          type="text"
          readonly
          formControlName="lastName"
          style="border: 0"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Address Line 1' | translate }} *</label>
        <input class="form-control" type="text" formControlName="addressLine1" />
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Address Line 2' | translate }}</label>
        <input class="form-control" type="text" formControlName="addressLine2" />
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'City' | translate }} *</label>
        <input class="form-control" type="text" formControlName="city" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'State' | translate }}</label>
        <select formControlName="state" class="form-control">
          <option disabled>{{ 'Select' | translate }}</option>
          <option value="{{ state.key }}" *ngFor="let state of states">{{ state.value }}</option>
        </select>
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Zip / Postal Code' | translate }} *</label>
        <input class="form-control" type="text" formControlName="postalCode" />
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Country' | translate }}</label>
        <input class="form-control" type="text" formControlName="country" readonly />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Phone Number' | translate }} *</label>
        <div class="input-group">
          <input class="input-group-addon form-control" formControlName="phoneCountry" readonly />
          <input class="form-control" type="text" formControlName="phoneNumber" />
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Date of Birth' | translate }} *</label>
        <input class="form-control" type="date" formControlName="dateOfBirth" />
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="form-group">
        <label>{{ 'Email' | translate }} *</label>
        <input class="form-control" type="text" readonly formControlName="email" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 col-sm-12">
      <div class="form-group">
        <input type="checkbox" formControlName="acceptTerms" />&nbsp;
        <label class="form-check-label" [innerHtml]="'Accept Flash MC Terms' | translate"> </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 col-sm-12">
      <button type="submit" class="btn btn-primary">
        {{ 'Submit' | translate }}
        <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
      </button>
    </div>
  </div>
</form>
