<ngb-accordion [closeOthers]="true">
    <ngb-panel *ngFor="let category of categories; let index = index" id="static-{{index}}" title="{{category.heading}}">
      <ng-template ngbPanelContent>
          <ngb-accordion>
              <ngb-panel *ngFor=" let panel of category.panels; let index2 = index" id="static2-{{index2}}" >
                <ng-template ngbPanelTitle>
                    {{ panel.heading }}
                </ng-template>
                <ng-template ngbPanelContent>
                    <div [innerHTML]="panel.body"></div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>