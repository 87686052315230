import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';
import { UserStoreService } from '../../core/store/user-store.service';

@Component({
  selector: 'app-download-statement',
  templateUrl: './download-statement.component.html',
  styleUrls: ['./download-statement.component.scss'],
})
export class DownloadStatementComponent implements OnInit {
  locale;

  quarters = [
    {
      startDate: '2021-01-01',
      endDate: '2021-03-31',
    },
    {
      startDate: '2021-04-01',
      endDate: '2021-06-30',
    },
    {
      startDate: '2021-07-01',
      endDate: '2021-09-30',
    },
    {
      startDate: '2021-10-01',
      endDate: '2021-12-31',
    },
  ];

  formGroup = this.formBuilder.group({
    quarterIndex: [null, Validators.required],
    fileType: ['CSV'],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userStore: UserStoreService,
    private translateService: TranslateService,
    private mxLogger: MxLoggerService,
  ) {}

  ngOnInit(): void {
    this.locale = this.translateService.currentLang
      ? this.translateService.currentLang.substring(
          0,
          this.translateService.currentLang.indexOf('-') > 0
            ? this.translateService.currentLang.indexOf('-')
            : undefined,
        )
      : this.translateService.defaultLang.substring(
          0,
          this.translateService.defaultLang.indexOf('-') > 0
            ? this.translateService.defaultLang.indexOf('-')
            : undefined,
        );
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.mxLogger.debug(
        'DownloadStatementComponent',
        'onSubmit() this.formGroup.value.fileType=',
        this.formGroup.value.fileType,
      );
      this.mxLogger.debug(
        'DownloadStatementComponent',
        'onSubmit() this.quarters[this.formGroup.value.quarterIndex]=',
        this.quarters[this.formGroup.value.quarterIndex],
      );

      this.userStore.downloadStatement(
        this.formGroup.controls.fileType.value,
        this.quarters[this.formGroup.value.quarterIndex].startDate,
        this.quarters[this.formGroup.value.quarterIndex].endDate,
      );
    }
  }
}
