import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DialogComponent } from './dialog/components/dialog/dialog.component';
import { MissingInvoicesComponent } from './dialog/components/missing-invoices/missing-invoices.component';
import { DialogNgbService, GenericListModule } from '@motivforce/mx-library-angular';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgbDropdown,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopNavComponent } from './layout/top-nav/top-nav.component';

import { TopFooterComponent } from './layout/top-footer/top-footer.component';
import { RouterModule } from '@angular/router';
import { UiModule } from '../ui/ui.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { AccountStatementPageComponent } from '../account/account-statement-page/account-statement-page.component';
import { MemberProfilePageComponent } from '../user/member-profile-page/member-profile-page.component';
import { MoreNavComponent } from './layout/more-nav/more-nav/more-nav.component';
import { PrimaryTaxIdInfoDialogComponent } from './dialog/components/primary-tax-id-info-dialog/primary-tax-id-info-dialog.component';
import { PrivacyDialogComponentComponent } from './dialog/components/privacy-dialog-component/privacy-dialog-component.component';
import { TermsAndConditionsDialogComponentComponent } from './dialog/components/terms-and-conditions-dialog-component/terms-and-conditions-dialog-component.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { LowerNavComponent } from './layout/lower-nav/lower-nav.component';
import { LowerFooterComponent } from './layout/lower-footer/lower-footer.component';
import { TopBannerComponent } from './layout/top-banner/top-banner.component';

@NgModule({
  declarations: [
    DialogComponent,
    PrimaryTaxIdInfoDialogComponent,
    PrivacyDialogComponentComponent,
    TermsAndConditionsDialogComponentComponent,
    MissingInvoicesComponent,
    DefaultLayoutComponent,
    TopNavComponent,
    LowerNavComponent,
    LowerFooterComponent,
    TopBannerComponent,
    TopFooterComponent,
    MemberProfilePageComponent,
    MoreNavComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    UiModule,
    BrowserModule,
    NgbModalModule,
    ReactiveFormsModule,
    GenericListModule,
    FormsModule,
    TranslateModule,
    ContentLoaderModule,
    FontAwesomeModule,
    NgbDropdownModule,
  ],
  exports: [
    TranslateModule,
    PrimaryTaxIdInfoDialogComponent,
    PrivacyDialogComponentComponent,
    TermsAndConditionsDialogComponentComponent,
    MissingInvoicesComponent,
    TopNavComponent,
    LowerNavComponent,
    LowerFooterComponent,
    TopBannerComponent,
    TopFooterComponent,
    DefaultLayoutComponent,
    MemberProfilePageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(private dialog: DialogNgbService) {
    this.dialog.registerFeatureDialog('primaryTaxIdInfoDialog', PrimaryTaxIdInfoDialogComponent);
    this.dialog.registerFeatureDialog(
      'termsAndConditionsDialog',
      TermsAndConditionsDialogComponentComponent,
    );
    this.dialog.registerFeatureDialog('privacyDialog', PrivacyDialogComponentComponent);
    this.dialog.registerFeatureDialog('missingInvoiceDialog', MissingInvoicesComponent);
  }
}
