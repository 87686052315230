import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AccordionComponent } from './accordion/accordion.component';
import { NestedAccordionComponent } from './nested-accordion/nested-accordion.component';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';



@NgModule({
  declarations: [AccordionComponent, NestedAccordionComponent, ModalBasicComponent],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    AccordionComponent,
    NestedAccordionComponent,
  ],
})
export class UiModule { }
