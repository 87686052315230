import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IsLoadingService } from '@service-work/is-loading';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { Content } from 'src/app/models/content.model';
import { TransactionSearch } from '../models/transaction-search';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { UserKyiAccount } from 'src/app/core/model/user/user-kyi-account';
import { Subject } from 'rxjs';
import { UserKyiAccountDetail } from 'src/app/core/model/user/user-kyi-account-detail';
import { AccountService } from 'src/app/account/account.service';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  @Input()
  baseContent: Content;

  public isLoading$ = this.isLoadingService.isLoading$;

  body: string;
  topSectionSideTitle: string;
  topSectionMainContent: string;
  transactionSectionSideTitle: string;
  transactionsSectionMainContent: string;

  transactionSearch: TransactionSearch = {
    fromDate: new Date(2017, 0, 1),
    toDate: new Date(),
    pageSize: 40,
    startIndex: 1,
    sortBy: 'create_time',
    sortFlag: 'desc',
    search: '',
    status: null,
  };
  transactions: any = [];

  transactionSearchForm = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    status: new FormControl(''),
  });

  selectedAccountId = null;
  usedAccounts: UserKyiAccount[];
  userAccountDetail: UserKyiAccountDetail;

  expiryDaysOffset = 28;
  expiryDateOffset = new Date();
  expiring = false;
  expired = false;
  hasPoints = false;
  hasBalance = false;
  expiryDate: Date;

  private readonly onDestroy$ = new Subject<void>();

  //TODO Add expired card request link if the card is expiring within 28 days

  constructor(
    private layoutService: LayoutService,
    private isLoadingService: IsLoadingService,
    public settingsStore: SettingsStoreService,
    private bankingStore: BankingStoreService,
    private accountService: AccountService,
  ) {
    this.expiryDateOffset.setDate(this.expiryDateOffset.getDate() + this.expiryDaysOffset);
  }

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const topSectionSideTitleField = this.baseContent.fields.find(
      (f) => f.name === 'Top Section Side Title',
    );
    const topSectionMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Top Section Main Content',
    );
    const transactionSectionSideTitleField = this.baseContent.fields.find(
      (f) => f.name === 'Transactions Section Side Title',
    );
    const transactionsSectionMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Transactions Section Main Content',
    );

    this.body = bodyField.value.value;
    this.topSectionSideTitle = topSectionSideTitleField.value.value;
    this.topSectionMainContent = topSectionMainContentField.value.value;
    this.transactionSectionSideTitle = transactionSectionSideTitleField.value.value;
    this.transactionsSectionMainContent = transactionsSectionMainContentField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.bankingStore.currentUserAccountDetail$
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe((userAccountDetail: UserKyiAccountDetail) => {
        this.userAccountDetail = userAccountDetail;
        this.userAccountDetail.status = this.mapAccountStatus(this.userAccountDetail.status);

        const today = new Date();
        this.expiryDate = new Date(this.userAccountDetail.security.expiryDate);
        console.log(this.expiryDate);
        this.expired = this.expiryDate.getTime() < today.getTime();
        console.log('Expired: ' + this.expired);
        this.expiring =
          this.expiryDate.getTime() > today.getTime() &&
          this.expiryDate.getTime() < this.expiryDateOffset.getTime();

        if (this.expired) {
          this.hasBalance = parseInt(this.userAccountDetail.availableBalance, 10) > 0;
          this.accountService.getPointsSummary().then((pointsSummary) => {
            this.hasPoints =
              pointsSummary &&
              (pointsSummary.incentiveAvailableBalance >= 140 ||
                pointsSummary.incentivePendingPoints >= 140);

            console.log('HasPoints: ' + this.hasPoints);
          });
        }
      });

    this.bankingStore.userAccounts$
      .pipe(filter(Boolean), take(1))
      .subscribe((userAccounts: any[]) => {
        this.usedAccounts = userAccounts;
      });

    this.bankingStore.getUserAccounts();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  mapAccountStatus(accountStatus: string): string {
    if (accountStatus == '0') {
      return 'Inactive';
    }
    if (accountStatus == '1') {
      return 'Active';
    }
    if (accountStatus == '2') {
      return 'Lost';
    }
    if (accountStatus == '3') {
      return 'Stolen';
    }
    if (accountStatus == '4') {
      return 'Closed';
    }
    if (accountStatus == '5') {
      return 'Suspended';
    }
    if (accountStatus == '6') {
      return 'Prohibited';
    }
    if (accountStatus == '7') {
      return 'Expired';
    }
    if (accountStatus == '8') {
      return 'Pending';
    }
    if (accountStatus == '9') {
      return 'Blocked';
    }
    if (accountStatus == '10') {
      return 'Refer';
    }
    if (accountStatus == '11') {
      return 'Capture Card';
    }
    if (accountStatus == '12') {
      return 'Pending';
    }
  }

  searchTransactions(searchModel: TransactionSearch): any {
    this.bankingStore.currentTransactions$
      .pipe(filter(Boolean), take(1))
      .subscribe((searchResult) => {
        this.transactions = searchResult;
      });

    this.bankingStore.searchTransactions(searchModel);
  }

  updateSearchModel(searchRequest: any) {
    let fromDate = new Date(2017, 0, 1);
    let toDate = new Date();
    let status = null;
    if (searchRequest.fromDate) {
      fromDate = searchRequest.fromDate;
    }
    if (searchRequest.toDate) {
      toDate = searchRequest.toDate;
    }
    if (searchRequest.status) {
      status = searchRequest.status;
    }
    this.transactionSearch = {
      fromDate: fromDate,
      toDate: toDate,
      pageSize: 40,
      startIndex: 1,
      sortBy: 'create_time',
      sortFlag: 'desc',
      status: status,
      search: '',
      accountId: this.selectedAccountId,
    };

    this.searchTransactions(this.transactionSearch);
  }

  onSubmit(): void {
    const searchRequest = this.transactionSearchForm.getRawValue();
    this.updateSearchModel(searchRequest);
  }

  selectAccount(): void {
    if (this.selectedAccountId)
      this.bankingStore.getUserAccountDetail(this.selectedAccountId, true);
  }

  requestCard(): void {}
}
