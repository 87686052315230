<app-default-layout [showMastercardHeader]="true" [currentMastercardPage]="'Documents'">
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="sideTitle"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="row">
          <div class="col-sm-12" [innerHtml]="body"></div>
        </div>
        <div class="form-box">
          <form [formGroup]="mastercardUploadDocumentForm" (ngSubmit)="onSubmit()">
            <div class="mb-20" [innerHtml]="formTitle"></div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>{{ 'Document type' | translate }} *</label>
                  <select formControlName="typeId" class="form-control">
                    <option disabled>{{ 'Select' | translate }}</option>
                    <option value="{{ idType.key }}" *ngFor="let idType of idTypes">
                      {{ idType.value }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input
                    type="file"
                    (change)="handleDocumentUpload($event)"
                    name="uploadDocumentFile"
                    formControlName="uploadDocumentFile"
                  />
                  <input
                    type="hidden"
                    id="uploadDocumentImagePath"
                    name="uploadDocumentImagePath"
                    formControlName="uploadDocumentImagePath"
                    value=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <p>
                  Now click the Upload button to complete the process of uploading your supporting
                  document.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary">
                  {{ 'Upload Document' | translate }}
                  <span class="arrow"><img src="../../../../assets/images/upload-icon.svg" /></span>
                </button>
              </div>
            </div>
          </form>
          <br />
          <div class="row">
            <div class="col-sm-12">
              <p>
                If you need to add a different type of identification to what you have previously
                declared, please click the button below to return to user identification
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <a [routerLink]="'/user-identification'" class="btn btn-inverse">
                {{ 'Re-upload user identification' | translate }}
                <span class="arrow"
                  ><img src="../../../../assets/images/arrow-right-blue.svg"
                /></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
