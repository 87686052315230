import { Injectable } from '@angular/core';
import {
  ApiRestService,
  BaseSiteRestService,
  MxLoggerService,
  SearchResult,
} from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { AssessmentDetails } from 'src/app/models/assessment-details';
import { AssessmentType } from 'src/app/models/assessment-type';
import { ConfirmationDetailsInput } from 'src/app/models/confirmation-details-input';
import { MeetingDetails } from 'src/app/models/meeting-details';
import { MeetingType } from 'src/app/models/meeting-type';
import { environment } from 'src/environments/environment';
import { UserKyiAssessmentGroup } from '../../model/user/user-kyi-assessment-group';
import { UserKyiAssessmentGroupSearch } from '../../model/user/user-kyi-assessment-group-search';
import { UserKyiMeetingGroup } from '../../model/user/user-kyi-meeting-group';
import { UserKyiMeetingGroupSearch } from '../../model/user/user-kyi-meeting-group-search';
import { UserKyiTechnicalAssessmentGroup } from '../../model/user/user-kyi-technical-assessment-group';

@Injectable({
  providedIn: 'root',
})
export class PreSalesRestService extends BaseSiteRestService {
  constructor(private apiRest: ApiRestService, private mxLogger: MxLoggerService) {
    super(environment.api.core.baseUrl, environment.api.core.path.member, 'kyi/presales');
  }

  getMeetingTypes(): Observable<MeetingType[]> {
    return this.apiRest.get<MeetingType[]>(this.memberBaseUrl + '/meeting-types');
  }

  submitMeetingDetails(md: MeetingDetails): Observable<any> {
    return this.apiRest.post<any>(this.memberBaseUrl + '/meeting-details', md);
  }

  confirmMeetingDetails(details: ConfirmationDetailsInput) {
    return this.apiRest.post<any>(
      this.memberBaseUrl + `/meeting-details/confirm/${details.meetingId}/`,
      details,
    );
  }

  getAssessmentTypes(): Observable<AssessmentType[]> {
    return this.apiRest.get<AssessmentType[]>(this.memberBaseUrl + '/assessment-types');
  }

  submitAssessmentDetails(ad: AssessmentDetails): Observable<any> {
    return this.apiRest.post<any>(this.memberBaseUrl + '/assessment-details', ad);
  }

  confirmAssessmentDetails(details: ConfirmationDetailsInput) {
    return this.apiRest.post<any>(
      this.memberBaseUrl + `/assessment-details/confirm/${details.assessmentId}/`,
      details,
    );
  }

  getTechnicalAssessmentTypes(): Observable<AssessmentType[]> {
    return this.apiRest.get<AssessmentType[]>(this.memberBaseUrl + '/technical-assessment-types');
  }

  submitTechnicalAssessmentPresignedUrl(fileName: string, fileContentType: string): Observable<any> {
    return this.apiRest.post<any>(this.memberBaseUrl + '/technical-assessment/presigned-url', {
      fileName,
      fileContentType,
    });
  }

  uploadTechnicalAssessmentFile(file: File, presignedUrl: string): Observable<any> {
    return this.apiRest.put<any>(presignedUrl, file);
  }

  submitTechnicalAssessmentDetails(ad: AssessmentDetails): Observable<any> {
    return this.apiRest.post<any>(this.memberBaseUrl + '/technical-assessment', ad);
  }

  confirmTechnicalAssessmentDetails(details: ConfirmationDetailsInput) {
    return this.apiRest.post<any>(
      this.memberBaseUrl + `/technical-assessment/confirm/${details.technicalAssessmentId}/`,
      details,
    );
  }

  searchUserMeetingGroups(
    search: UserKyiMeetingGroupSearch,
  ): Observable<SearchResult<UserKyiMeetingGroup>> {
    return this.apiRest.post<SearchResult<UserKyiMeetingGroup>>(
      this.memberBaseUrl + '/meeting-details/search',
      search,
    );
  }

  searchUserAssessmentGroups(
    search: UserKyiAssessmentGroupSearch,
  ): Observable<SearchResult<UserKyiAssessmentGroup>> {
    return this.apiRest.post<SearchResult<UserKyiAssessmentGroup>>(
      this.memberBaseUrl + '/assessment-details/search',
      search,
    );
  }

  searchTechnicalAssessmentGroups(
    search: UserKyiAssessmentGroupSearch,
  ): Observable<SearchResult<UserKyiTechnicalAssessmentGroup>> {
    return this.apiRest.post<SearchResult<UserKyiTechnicalAssessmentGroup>>(
      this.memberBaseUrl + '/technical-assessment/search',
      search,
    );
  }
}
