import { ApiRestService } from '@motivforce/mx-library-angular';
import { environment } from 'src/environments/environment';

export class BaseSiteRestService {
  apiBaseUrl: string;
  memberBaseUrl: string;

  constructor(endpoint: string, protected apiRestService: ApiRestService) {
    this.apiBaseUrl = `${environment.api.core.baseUrl}/${endpoint}`;
    this.memberBaseUrl = `${environment.api.core.path.member}/clients/${endpoint}`;
  }
}
