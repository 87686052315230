import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { Month } from '../../sales-claim/sales-result';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserKyiSaleGroup } from '../../core/model/user/user-kyi-sale-group';
import { GenericListColumn, MxLoggerService } from '@motivforce/mx-library-angular';
import { Subject } from 'rxjs';
import {
  DatePipe,
  DOCUMENT,
  FormStyle,
  getLocaleMonthNames,
  TranslationWidth,
} from '@angular/common';
import { GenericListState } from '@motivforce/mx-library-angular/lib/generic-list/model/generic-list-state';
import { takeUntil, distinctUntilChanged, debounceTime, skip, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PreSalesStoreService } from 'src/app/core/store/presales-store.service';
import { UserKyiAssessmentGroupSearch } from 'src/app/core/model/user/user-kyi-assessment-group-search';
import { UserKyiAssessmentGroup } from 'src/app/core/model/user/user-kyi-assessment-group';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';

@Component({
  selector: 'previously-submitted-assessments',
  templateUrl: './previously-submitted-assessments.component.html',
  styleUrls: ['./previously-submitted-assessments.component.scss'],
})
export class PreviouslySubmittedAssessmentsComponent implements OnInit, OnDestroy, AfterViewInit {
  months: Month[] = [
    {
      id: 1,
      value: 'Jan',
    },
    {
      id: 2,
      value: 'Feb',
    },
    {
      id: 3,
      value: 'Mar',
    },
    {
      id: 4,
      value: 'Apr',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'Jun',
    },
    {
      id: 7,
      value: 'Jul',
    },
    {
      id: 8,
      value: 'Aug',
    },
    {
      id: 9,
      value: 'Sep',
    },
    {
      id: 10,
      value: 'Oct',
    },
    {
      id: 11,
      value: 'Nov',
    },
    {
      id: 12,
      value: 'Dec',
    },
  ];

  formGroup: FormGroup = this.formBuilder.group({
    month: [null],
    endCustomerName: [null],
  });

  assessmentGroupSearch: UserKyiAssessmentGroupSearch = {
    month: null,
    endCustomerName: null,
    pageSize: 10,
    pageNumber: 1,
    orderBy: null,
  };

  resultsLength: number;
  dataProvider: UserKyiAssessmentGroup[];

  assessmentListColumnsProvider: GenericListColumn[] = [
    {
      columnNameDisplayKey: 'Date of assessment',
      sortable: false,
      fieldName: 'assessmentDate',
      labelFunction: (value: UserKyiAssessmentGroup) =>
        this.datePipe.transform(value.assessmentDate, 'MM/dd/yyyy'),
      width: '80px',
    },
    {
      columnNameDisplayKey: 'End customer',
      sortable: false,
      fieldName: 'customerName',
      width: '15%',
    },
    {
      columnNameDisplayKey: 'Type of assessment',
      sortable: false,
      fieldName: 'assessmentTypeName',
      width: '120px',
    },
    {
      columnNameDisplayKey: 'Email of IBM Sales Represenative',
      sortable: false,
      fieldName: 'ibmEmail',
      width: '120px',
    },
    {
      columnNameDisplayKey: 'Status',
      sortable: false,
      fieldName: 'status',
      labelFunction: (value: UserKyiAssessmentGroup) => {
        if (value.isApproved === true && value.approvedByClient === true) return 'Approved';
        else if (value.approvedByClient === false)
          return `Rejected (${value.rejectedByClientReason})`;
        else if (value.isApproved === false) return `Rejected`;
        else return 'Pending';
      },
      width: '140px',
    },
  ];

  onDestroy$: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private mxLogger: MxLoggerService,
    private presalesStore: PreSalesStoreService,
    private translateService: TranslateService,
    private settingsStore: SettingsStoreService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    const locale = this.translateService.currentLang
      ? this.translateService.currentLang.substring(
          0,
          this.translateService.currentLang.indexOf('-') > 0
            ? this.translateService.currentLang.indexOf('-')
            : undefined,
        )
      : this.translateService.defaultLang.substring(
          0,
          this.translateService.defaultLang.indexOf('-') > 0
            ? this.translateService.defaultLang.indexOf('-')
            : undefined,
        );

    this.months = getLocaleMonthNames(locale, FormStyle.Standalone, TranslationWidth.Wide).map(
      (value, index) => {
        return { id: index + 1, value };
      },
    );

    this.presalesStore.currentAssessmentGroupSearchResult$
      .pipe(skip(1), takeUntil(this.onDestroy$))
      .subscribe((searchResult) => {
        this.resultsLength = searchResult.statistics.totalRecords;
        this.dataProvider = searchResult.results;

        this.updatePagingLabels();
      });

    this.searchAssessments();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.updatePagingLabels();
  }

  updatePagingLabels(): void {
    const pageSizeBeforeElement = this.document.body.querySelector(
      '.page-options-size div:nth-child(1)',
    );
    pageSizeBeforeElement.innerHTML = 'Items per page:';

    const pageSizeAfterElement = this.document.body.querySelector(
      '.page-options-size div:nth-child(3)',
    );

    pageSizeAfterElement.innerHTML = this.resultsLength
      ? (this.assessmentGroupSearch.pageNumber - 1) * this.assessmentGroupSearch.pageSize +
        1 +
        '-' +
        this.assessmentGroupSearch.pageNumber * this.assessmentGroupSearch.pageSize +
        ' of ' +
        this.resultsLength +
        ' items.'
      : '';
  }

  searchAssessments(): void {
    const assessmentGroupSearch = { ...this.assessmentGroupSearch, ...this.formGroup.value };
    this.presalesStore.searchUserAssessmentGroups(assessmentGroupSearch);
  }

  onListStateChanged(event: GenericListState): void {
    this.assessmentGroupSearch.orderBy =
      event.sortColumn && event.sortColumn !== ''
        ? {
            fields: [
              { sortBy: event.sortColumn, sortDirection: event.sortDirection as 'asc' | 'desc' },
            ],
          }
        : null;

    this.assessmentGroupSearch.pageSize = event.pageSize;
    this.assessmentGroupSearch.pageNumber = event.page;

    this.searchAssessments();
  }
}
