<div class="admin-panel" *ngIf="showAdminPanel">
  <div class="filter-button" *ngIf="!expanded" (click)="expand()">
    <i class="icon fas fa-cog"></i>
  </div>
  <div class="panel" *ngIf="expanded">
    <div class="header">
      <i class="far fa-times-circle close-icon" (click)="close()"></i>
      Admin Panel
    </div>
    <div class="body">
      <strong>Language</strong>
      <div class="form-group">
        <select class="form-control" [(ngModel)]="selectedLanguage">
          <option *ngFor="let l of settings.languages" [value]="l.id">{{ l.value }}</option>
        </select>
      </div>
      <strong>Conditional Content Filter Override</strong>
      <div class="row" *ngFor="let field of fields">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ field.label }}</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <i class="fa fa-spinner fa-spin" *ngIf="field.loading"></i>
            <select
              *ngIf="field.isList && !field.loading"
              class="form-control"
              (change)="field.onValueChange($event)"
              [(ngModel)]="field.value"
            >
              <option *ngFor="let i of field.data" [value]="i.id">{{ i.text }}</option>
            </select>
            <input
              *ngIf="!field.isList"
              type="text"
              class="form-control"
              [(ngModel)]="field.value"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary" (click)="apply()">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
