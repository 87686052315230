<div class="modal-body m-3 d-flex flex-column justify-content-center">
  <span [innerHtml]="content"></span>
</div>
<div class="modal-footer d-flex justify-content-end">
  <div class="d-flex justify-content-end">
    <button class="btn" type="button" (click)="activeModal.close()">
      {{ 'Close' | translate }}
    </button>
  </div>
</div>
