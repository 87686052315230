import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { PointsSummary } from 'src/app/account/points-summary-models';
import { SearchResult } from 'src/app/models/search/search-result';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { ProgramRestService } from '../api/rest/program-rest.service';
import * as fileSaver from 'file-saver';
import { DateSearch } from 'src/app/models/search/date-search';
import { ProgramPeriod } from 'src/app/models/program-period';

@Injectable({
  providedIn: 'root',
})
export class ProgramStoreService {
  private readonly _currentProgramPeriod = new BehaviorSubject<ProgramPeriod>(null);
  readonly currentProgramPeriod$ = this._currentProgramPeriod.asObservable();

  constructor(
    private programRest: ProgramRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogService,
  ) {}

  get currentProgramPeriod(): ProgramPeriod {
    return this._currentProgramPeriod.getValue();
  }

  getCurrentProgramPeriod(): void {
    this.isLoadingService.add(
      this.programRest.getCurrentProgramPeriod().subscribe((period: ProgramPeriod) => {
        this._currentProgramPeriod.next(period);
      }),
    );
  }
  
}
