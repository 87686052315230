import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { BankingRestService } from '../api/rest/banking-rest.service';
import { UserKyiAccount } from '../model/user/user-kyi-account';
import { UserKyiAccountDetail } from '../model/user/user-kyi-account-detail';

@Injectable({
  providedIn: 'root',
})
export class BankingStoreService {
  private readonly _userAccounts = new BehaviorSubject<UserKyiAccount[]>(null);
  readonly userAccounts$ = this._userAccounts.asObservable();

  private readonly _currentUserAccountDetail = new BehaviorSubject<UserKyiAccountDetail>(null);
  readonly currentUserAccountDetail$ = this._currentUserAccountDetail.asObservable();

  private readonly _currentTransactions = new BehaviorSubject<any[]>(null);
  readonly currentTransactions$ = this._currentTransactions.asObservable();

  private readonly _activeBalance = new BehaviorSubject<any>(null);
  readonly activeBalance$ = this._activeBalance.asObservable();

  constructor(
    private bankingRest: BankingRestService,
    private isLoadingService: IsLoadingService,
  ) {}

  get userAccounts(): UserKyiAccount[] {
    return this._userAccounts.getValue();
  }

  get currentUserAccountDetail(): UserKyiAccountDetail {
    return this._currentUserAccountDetail.getValue();
  }

  get currentTransactions(): any[] {
    return this._currentTransactions.getValue();
  }

  get activeBalance(): any {
    return this._activeBalance.getValue();
  }

  getUserAccounts(): void {
    if (this.userAccounts) {
      return;
    }

    this.isLoadingService.add(
      this.bankingRest.getUserAccounts().subscribe((userAccounts) => {
        this._userAccounts.next(userAccounts);
      }),
    );
  }

  getUserAccountDetail(accountId: number, showSensitiveData: boolean = false): void {
    this.isLoadingService.add(
      this.bankingRest
        .getUserAccountDetail(accountId, showSensitiveData)
        .subscribe((userAccountDetail) => {
          this._currentUserAccountDetail.next(userAccountDetail);
        }),
    );
  }

  searchTransactions(searchModel: any): void {
    this.isLoadingService.add(
      this.bankingRest.searchTransactions(searchModel).subscribe((data) => {
        if (data && data.transactions) {
          this._currentTransactions.next(data.transactions);
        }
      }),
    );
  }

  requestReplacement(requestDetail: any, accountId: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.bankingRest.requestReplacement(requestDetail, accountId).subscribe(
          () => {
            resolve(true);
          },
          () => resolve(false),
        ),
      );
    });
  }

  getActiveBalance(): void {
    this.isLoadingService.add(
      this.bankingRest.getActiveBalance().subscribe((data) => {
        this._activeBalance.next(data);
      }),
    );
  }
}
