import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Settings } from '../entities/settings';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public adminFilters: any;
  public language: string;
  settings: BehaviorSubject<Settings> = new BehaviorSubject(null);
  filterChange: Subject<any> = new Subject<any>();

  constructor(private apiRestService: ApiRestService) {
    console.log('ADMIN SERVICE CONSTRUCTOR');
  }

  async getRegions(): Promise<any[]> {
    const clientCode = this.settings.value.clientCode;
    if (clientCode) {
      return await this.apiRestService
        .get<any[]>(`${environment.api.core.baseUrl}/clients/clientcodes/${clientCode}/regions`)
        .toPromise();
    }

    return [];
  }

  async getUserRoles(): Promise<any[]> {
    const clientCode = this.settings.value.clientCode;
    if (clientCode) {
      return await this.apiRestService
        .get<any[]>(`${environment.api.core.baseUrl}/clients/clientcodes/${clientCode}/userroles`)
        .toPromise();
    }

    return [];
  }

  async getCountriesByRegionCode(regionCode: string): Promise<any[]> {
    const clientCode = this.settings.value.clientCode;
    if (clientCode) {
      return await this.apiRestService
        .get<any[]>(
          `${environment.api.core.baseUrl}/clients/clientcodes/${clientCode}/regioncodes/${regionCode}/countries`,
        )
        .toPromise();
    }

    return [];
  }
}
