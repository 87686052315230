import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCoreService } from 'src/app/shared/base-core-service';
import { EmailPreference } from 'src/app/models/email-preference';
import { Profile } from 'src/app/models/profile';
import { UserRole } from 'src/app/models/user-profile';
import { TaxRequirement } from 'src/app/models/tax-requirement';
import { TaxInformation } from '../models/tax-information';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { NominatedUserProfile } from '../models/nominated-user-profile';
import { Observable } from 'rxjs';
import { UserBlock } from '../models/user-block';
import { UserPowerDetails } from '../models/user-power-details';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('users', apiRestService);
  }

  acceptFlashPrivacyPolicy(): Promise<any> {
    return this.apiRestService
      .post(`${environment.api.core.baseUrl}/members/users/privacy-policy/accept`, null)
      .toPromise();
  }

  markRegistrationComplete(): Promise<any> {
    return this.apiRestService
      .post(`${environment.api.core.baseUrl}/members/users/registration-complete`, null)
      .toPromise();
  }

  getFlashProfile(): Promise<Profile> {
    return this.apiRestService
      .get<Profile>(`${environment.api.core.baseUrl}/members/users/profile`)
      .toPromise();
  }

  acceptFlashTermsAndConditions(): Promise<any> {
    return this.apiRestService
      .post(`${environment.api.core.baseUrl}/members/users/terms-and-conditions/accept`, null)
      .toPromise();
  }

  getAddresses(): Promise<any> {
    return this.apiRestService
      .get(`${environment.api.core.baseUrl}/members/users/addresses`)
      .toPromise();
  }

  getFlashUserRole(): Promise<UserRole> {
    return this.apiRestService.get<UserRole>(`${this.memberBaseUrl}/role`).toPromise();
  }

  setFlashUserRole(role: UserRole): Promise<any> {
    return this.apiRestService.post(`${this.memberBaseUrl}/flash-role`, role).toPromise();
  }

  getPowerDetails(): Promise<UserPowerDetails> {
    return this.apiRestService.get<UserPowerDetails>(`${this.memberBaseUrl}/power-details`).toPromise();
  }

  updateFlashEmailPreference(emailPreference: EmailPreference): Promise<any> {
    return this.apiRestService
      .post(`${environment.api.core.baseUrl}/members/users/profile/send-email`, emailPreference)
      .toPromise();
  }

  getFlashTaxRequirements(): Promise<TaxRequirement> {
    return this.apiRestService
      .get<TaxRequirement>(`${this.memberBaseUrl}/tax-requirements`)
      .toPromise();
  }

  getFlashTaxInformation(): Promise<TaxInformation> {
    return this.apiRestService
      .get<TaxInformation>(`${this.memberBaseUrl}/tax-information`)
      .toPromise();
  }

  updateFlashTaxInformation(taxInformation: TaxInformation): Promise<any> {
    return this.apiRestService
      .post(`${this.memberBaseUrl}/tax-information`, taxInformation)
      .toPromise();
  }

  checkBlock(): Promise<UserBlock[]> {
    return this.apiRestService.get<UserBlock[]>(`${this.memberBaseUrl}/check-block`).toPromise();
  }

  getProgramYears(): Promise<number[]> {
    return this.apiRestService
      .get<number[]>(`${environment.api.core.baseUrl}/members/users/program-years`)
      .toPromise();
  }

  updateIbmId(ibmId: string): Promise<any> {
    const endpoint = `${this.memberBaseUrl}/ibm-id/` + ibmId;
    return this.apiRestService.post(endpoint, null).toPromise();
  }

  confirmIbmid(form: any): Promise<any> {
    const endpoint = `${this.memberBaseUrl}/confirm-ibmid`;
    return this.apiRestService.post(endpoint, form).toPromise();
  }

  getCompanyUsers(): Observable<NominatedUserProfile[]> {
    const endpointUrl = `${this.memberBaseUrl}/company-users`;
    return this.apiRestService.get<NominatedUserProfile[]>(endpointUrl);
  }

  setCompanyType(form: any): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/company-rewarded`;
    return this.apiRestService.post(endpointUrl, form).toPromise();
  }

  setNominatedUser(form: any): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/nominated-user`;
    return this.apiRestService.post(endpointUrl, form).toPromise();
  }

  setPrimaryCompany(id: number): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/primary-company/` + id;
    return this.apiRestService.post(endpointUrl, null).toPromise();
  }

  updateOfficialData(dateOfBirth: string, officialName: string): Promise<any> {
    return this.apiRestService
      .post(`${environment.api.core.baseUrl}/members/users/profile/official-data`, {
        dateOfBirth,
        officialName,
      })
      .toPromise();
  }

  watchVideo(): Promise<any> {
    return this.apiRestService
      .post(`${environment.api.core.baseUrl}/members/users/watch-flash-video`, null)
      .toPromise();
  }
}
