import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { Content } from 'src/app/models/content.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { ApplicationStatus } from '../models/application-status';
import { Subscription } from 'apollo-angular';
import _ from 'lodash';
import { ApplicationOptionItem } from '../models/application-form-options';
import { Identification } from '../models/identification';
import { IsLoadingService } from '@service-work/is-loading';
import { LayoutService } from 'src/app/layout/layout.service';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss'],
})
export class IdentificationComponent implements OnInit {
  @Input()
  baseContent: Content;

  @Input()
  applicationStatus: EventEmitter<ApplicationStatus>;

  public isLoading$ = this.isLoadingService.isLoading$;

  applicationStatusSub: Subscription;
  idTypes: ApplicationOptionItem[] = [];
  countries: ApplicationOptionItem[] = [];
  bankUser: any;

  identityForm: Identification;
  mastercardIdentificationForm = new FormGroup({
    type: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    countryOfIssue: new FormControl('', Validators.required),
    countryOfResidence: new FormControl('', Validators.required),
    issueDate: new FormControl('', Validators.required),
    expiryDate: new FormControl('', Validators.required),
  });

  body: string;
  sideTitle: string;
  formTitle: string;

  constructor(
    private layoutService: LayoutService,
    private bankingRest: BankingRestService,
    private dialog: DialogService,
    private router: Router,
    public settingsProvider: SettingsProvider,
    private isLoadingService: IsLoadingService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const sideTitleField = this.baseContent.fields.find((f) => f.name === 'Side Title');
    const formTitleField = this.baseContent.fields.find((f) => f.name === 'Form Title');

    this.bankingRest.getCountries().subscribe((data) => {
      this.countries = data;
    });

    this.bankingRest.getIdentificationOptions().subscribe((data) => {
      this.idTypes = data;
    });

    this.sideTitle = sideTitleField.value.value;
    this.formTitle = formTitleField.value.value;
    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  onSubmit(): void {
    const form = this.mastercardIdentificationForm.getRawValue();
    this.identityForm = {
      type: form.type,
      country_of_issue: form.countryOfIssue,
      country_of_residence: form.countryOfResidence,
      number: form.number,
      issue_date: form.issueDate,
      expiry_date: form.expiryDate,
    };

    this.isLoadingService.add(
      this.bankingRest.submitIdentification(this.identityForm).subscribe((account) => {
        if (account) {
          this.router.navigate(['/upload-documents']);
        } else {
          this.dialog.openModal('An error has occurred');
        }
      }),
    );
  }
}
