import { Component, OnInit, Input } from '@angular/core';
import { ParentPanel } from '../../ui/nested-accordion/parent-panel.model';
import { Panel } from '../../ui/accordion/panel.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';
import { ContentService } from 'src/app/content/content.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Settings } from 'src/app/entities/settings';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;
  categories: ParentPanel[];

  bodyContent: string;
  faqContent: Content;

  constructor(
    private layoutService: LayoutService,
    private contentService: ContentService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const faqsField = this.baseContent.fields.find((f) => f.name === 'FAQs');

    this.bodyContent = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    // console.log('FaqPageComponent.ngOnInit() this.baseContent=', this.baseContent);

    const faqId = faqsField.value.value;

    this.contentService
      .getContentById(faqId, this.translateService.currentLang, true)
      .subscribe((response) => {
        this.faqContent = response.data.contentById;

        console.log('FaqPageComponent.ngOnInit() this.faqContent=', this.faqContent);

        this.categories = this.faqContent.children.map((c) => {
          // const categoryTitleField = c.fields.find((f) => f.name === 'Title');
          console.log('FaqPageComponent.ngOnInit() c.fields=', c.fields);

          const questionField = c.fields.find((f) => {
            console.log('FaqPageComponent.ngOnInit() f=', f);
            return f.name === 'Question';
          });
          const answerField = c.fields.find((f) => f.name === 'Answer');

          console.log('FaqPageComponent.ngOnInit() questionField.id=', questionField.id);
          console.log('FaqPageComponent.ngOnInit() answerField=', answerField);

          return {
            heading: '',
            panels: [
              {
                heading: questionField?.value.value,
                body: answerField?.value.value,
              },
            ],
            // c.fields.map((q) => {
            //   console.log('FaqPageComponent.ngOnInit() q=', q);

            //   const questionField = q.name === 'Question'? q.fields.find((f) => q.name === 'Question');
            //   const answerField = q.fields.find((f) => q.name === 'Answer');

            //   return {
            //     heading: questionField.value.value,
            //     body: answerField.value.value,
            //   };
            // }),
          };
        });

        console.log('FaqPageComponent.ngOnInit() this.categories=', this.categories);
      });
  }
}
