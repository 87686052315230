<div class="top-footer">
  <div class="layout-boxed">
    <div class="row">
      <div class="col-3 link-column" *ngFor="let c of categories">
        <div class="heading">{{ c.name }}</div>
        <ul>
          <li *ngFor="let item of c.items">
            <a [href]="item.url" target="_blank">{{ item.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
