import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';
import { UserService } from 'src/app/user/user.service';
import { ItemField } from '../../entities/content-item';
import { SettingsProvider } from '../../shared/settings.provider';
import { TaxRequirement } from 'src/app/models/tax-requirement';
import { DialogNgbService, MxLoggerService } from '@motivforce/mx-library-angular';
import { NgbDateCoreParserFormatter } from '../../shared/ngb-date-core-parser-formatter';
import { TranslateService } from '@ngx-translate/core';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from 'src/app/shared/custom-ngb-date-parser-formatter';
import { filter, take } from 'rxjs/operators';
import { YearRequirementList } from 'src/app/account/points-summary-models';
import { AccountService } from 'src/app/account/account.service';
@Component({
  selector: 'app-member-profile-page',
  templateUrl: './member-profile-page.component.html',
  styleUrls: ['./member-profile-page.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }],
})
export class MemberProfilePageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;
  profileHeading: string;
  rolePreContent: string;
  communicationHeading: string;
  communicationPreContent: string;
  communicationPostContent: string;
  taxHeading: string;
  taxContent: string;
  primaryTaxIdentifierLabel: string;
  primaryTaxIdentifierInformation: string;
  secondaryTaxIdentifierLabel: string;
  dateOfBirthLabel: string;
  addressLabel: string;
  public submitted = false;
  public updating = false;
  registering = false;
  taxRequirements: TaxRequirement;
  roleEditText: string;

  regionCode: string;
  alpha2Code: string;

  profile: boolean = false;
  taxRequired: boolean = false;
  taxCompleted: boolean = false;
  criteria: boolean = false;
  privacy: boolean = false;
  communications: boolean = false;
  termsAccepted: boolean = false;
  integrityTraining: boolean = false;
  watchedVideo: boolean = false;

  yearRequirements: YearRequirementList[];

  profileForm = new FormGroup({
    title: new FormControl({ value: '', disabled: true }),
    firstName: new FormControl({ value: '', disabled: true }),
    lastName: new FormControl({ value: '', disabled: true }),
    companyName: new FormControl({ value: '', disabled: true }),
    location: new FormControl({ value: '', disabled: true }),
    email: new FormControl({ value: '', disabled: true }),

    officialName: new FormControl(null),
    dateOfBirth: new FormControl(null),

    roles: new FormGroup({
      role: new FormControl('', Validators.required),
    }),
  });

  taxForm = new FormGroup({
    primaryTaxIdentifier: new FormControl(''),
    secondaryTaxIdentifier: new FormControl(''),
    dateOfBirth: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postCode: new FormControl(''),
    taxOfficeName: new FormControl(''),
    taxOfficeAddress: new FormControl(''),
    taxOfficePostCode: new FormControl(''),
    taxOfficeTown: new FormControl(''),
  });

  communicationsForm = new FormGroup({
    sendEmail: new FormControl('', Validators.required),
  });

  privacyForm = new FormGroup({
    acceptPrivacy: new FormControl('', Validators.required),
  });

  termsForm = new FormGroup({
    acceptTerms: new FormControl('', Validators.required),
  });

  public get roleFormControl(): AbstractControl {
    return this.profileForm.get(['roles', 'role']);
  }

  public get primaryTaxIdentifierFormControl(): AbstractControl {
    return this.taxForm.get(['primaryTaxIdentifier']);
  }

  public get secondaryTaxIdentifierFormControl(): AbstractControl {
    return this.taxForm.get(['secondaryTaxIdentifier']);
  }

  public get dateOfBirthFormControl(): AbstractControl {
    return this.taxForm.get(['dateOfBirth']);
  }

  public get addressFormControl(): AbstractControl {
    return this.taxForm.get(['address1']);
  }

  public get cityFormControl(): AbstractControl {
    return this.taxForm.get(['city']);
  }

  public get stateFormControl(): AbstractControl {
    return this.taxForm.get(['state']);
  }

  public get postCodeFormControl(): AbstractControl {
    return this.taxForm.get(['postCode']);
  }

  constructor(
    public fb: FormBuilder,
    private userService: UserService,
    private settingsProvider: SettingsProvider,
    public settingsStore: SettingsStoreService,
    private ngbDateParserFormatter: NgbDateCoreParserFormatter,
    private dialog: DialogNgbService,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    this.settingsStore.settings$.pipe(filter(Boolean), take(1)).subscribe(() => {
      this.registering = !this.settingsStore.settings.user.registrationDate;
      this.regionCode = this.settingsStore.settings.user.region.regionCode;
      this.alpha2Code = this.settingsStore.settings.country.alpha2Code;

      this.userService.getFlashProfile().then((data) => {
        if (data) {
          this.profileForm.patchValue({
            lastName: data.lastName,
            firstName: data.firstName,
            email: data.email,
            location: data.location,
            companyName: data.companyName,
            title: data.title ? data.title.toLowerCase() : null,
            officialName: this.settingsStore.settings.user.officialName,
            dateOfBirth: this.ngbDateParserFormatter.parseIso(
              this.settingsStore.settings.user.dateOfBirth as any,
            ),
          });

          this.communicationsForm.patchValue({
            sendEmail: data.sendEmail.toString(),
          });
          this.communications = true;

          this.privacyForm.patchValue({
            acceptPrivacy: data.acceptedPrivacy.toString(),
          });
          if (data.acceptedPrivacy) {
            this.privacy = true;
          }

          this.termsForm.patchValue({
            acceptTerms: data.acceptedTerms.toString(),
          });
          if (data.acceptedTerms) {
            this.termsAccepted = true;
          }
        }
      });
      if (this.alpha2Code === 'US') {
        this.taxHeading = '<strong>Social Security Number (SSN)</strong>';
        this.taxContent =
          '<p>In the United States, 1099 forms will be issued to all ' +
          'eligible Business Partner employees who have redeemed KYI reward points totaling USD $600 or more income in a calendar ' +
          'year for their personal tax reporting.</p> <p>For the eligible BP employees who met the US 1099 tax reporting requirement as described above, each individual is required to provide their US social security number on the KYI website to complete the US 1099 to ensure compliance with the US tax law. </p><p>The information provided by the eligible BP employees will be for the sole purpose of completing the required US tax reporting forms. If you have questions please contact <a href="flash@kyirewards.com">flash@kyirewards.com</a>.</p>';
        this.primaryTaxIdentifierLabel = 'Social Security Number (SSN) in the format xxx-xx-xxxx';
        this.addressLabel = 'Your Address';
      }
      if (this.alpha2Code === 'CA') {
        this.taxHeading = '<strong>SOCIAL INSURANCE NUMBER (SIN)</strong>';
        this.taxContent =
          '<p>In Canada, T4A forms will be issued to all eligible Business Partner (BP) employees who have redeemed KYI reward points in aggregate with a fair market value of more than CDN $500 income in a calendar year for their personal tax reporting.</p> <p> or the eligible BP employees who met the Canadian T4A tax reporting requirement as described above, each individual is required to provide their Canadian social insurance number on the KYI website to complete the Canadian T4A tax forms to ensure compliance with Canada tax law. </p> <p>The information provided by the eligible BP employees will be for the sole purpose of completing the required Canadian tax reporting forms. If you have questions please contact <a href="flash@kyirewards.com">flash@kyirewards.com</a></p>';
        this.primaryTaxIdentifierLabel = 'SOCIAL INSURANCE NUMBER (SIN)';
        this.addressLabel = 'Your Address';
      }
    });

    this.userService.getFlashUserRole().then((role) => {
      if (role && role.name && role.name.length) {
        const roleControl = this.roleFormControl;
        roleControl.setValue(role.name.toLowerCase());
        roleControl.disable();
        this.profile = true;
      }
    });

    this.accountService.getYearRequirements().then((yearRequirements) => {
      this.yearRequirements = yearRequirements;
      if (this.yearRequirements) {
        const currentYear = new Date().getFullYear();
        let requirementsForCurrentYear = this.yearRequirements.filter(
          (x) => x.programYear === currentYear,
        );
        let itegrityTrainingPositive = requirementsForCurrentYear[0].requirements.filter(
          (x) => x.contentKey === 'Positive Integrity Training',
        )[0];

        if (itegrityTrainingPositive) {
          this.integrityTraining = true;
        }

        let videoPositive = requirementsForCurrentYear[0].requirements.filter(
          (x) => x.contentKey === 'Positive Watch video',
        )[0];

        if (videoPositive) {
          this.watchedVideo = true;
        }
      }
    });

    this.userService.getFlashTaxRequirements().then((data) => {
      this.taxRequirements = data;
      if (this.taxRequirements) {
        this.taxRequired = true;
        if (this.taxRequirements.primaryTaxIdentifier) {
          this.primaryTaxIdentifierFormControl.setValidators(Validators.required);
        }
        if (this.taxRequirements.secondaryTaxIdentifier) {
          this.secondaryTaxIdentifierFormControl.setValidators(Validators.required);
        }
        if (this.taxRequirements.dateOfBirth) {
          this.dateOfBirthFormControl.setValidators(Validators.required);
        }
        if (this.taxRequirements.address) {
          this.addressFormControl.setValidators(Validators.required);
          this.cityFormControl.setValidators(Validators.required);
          this.stateFormControl.setValidators(Validators.required);
          this.postCodeFormControl.setValidators(Validators.required);
        }
      }
    });

    this.userService.getFlashTaxInformation().then((data) => {
      if (data) {
        this.taxForm.patchValue(data);
        this.taxForm.disable();
        this.taxCompleted = true;
      }
    });

    // this.profileHeading = this.getFieldContent(profileFormHeadingField);
    // this.rolePreContent = this.getFieldContent(rolePreField);
    // this.communicationHeading = this.getFieldContent(communicationsHeadingField);
    // this.communicationPreContent = this.getFieldContent(communicationsPreField);
    // this.communicationPostContent = this.getFieldContent(communicationsPostField);

    // this.layoutService.changeBanner({
    //   title: titleField.value.value,
    //   imageUrl: bannerField.value.url,
    // });
  }

  showTerms(): void {
    this.dialog.openFeatureDialog('termsAndConditionsDialog', [], { size: 'lg' });
  }
  showPrivacy(): void {
    this.dialog.openFeatureDialog('privacyDialog', [], { size: 'lg' });
  }

  onSubmit(): void {}

  onSubmitTax(): void {
    const taxFormGroup = this.taxForm;
    if (!this.taxForm.valid) {
      this.dialog.openError(['You have not completed all the required fields'], 'Validation');
    }
    if (this.taxForm.dirty) {
      this.userService
        .updateFlashTaxInformation(taxFormGroup.value)
        .then(() => this.settingsProvider.refreshUserSettings())
        .then(() => {
          this.dialog.openNotification(['Your tax information has been updated.']);
        })
        .finally(() => (this.updating = false));
    }
  }

  submitRole(): void {
    this.updating = true;
    const roleFormControl = this.roleFormControl;

    if (this.roleFormControl.dirty) {
      let roleType = '';
      if (roleFormControl.value === 'sales') {
        roleType = 'Sales';
      } else if (roleFormControl.value === 'technical sales') {
        roleType = 'Technical Sales';
      }

      this.userService
        .setFlashUserRole({ name: roleType })
        .then(() => this.settingsProvider.refreshUserSettings())
        .then(() => {
          this.dialog.openNotification(['Your user role has been updated.']);
        })
        .finally(() => (this.updating = false));
    }
    this.updating = false;
  }

  onSubmitCommunications(): void {
    this.updating = true;
    const sendEmailFormControl = this.communicationsForm;
    const sendEmail = sendEmailFormControl.value.sendEmail.toLowerCase() === 'true';
    this.userService
      .updateFlashEmailPreference({ sendEmail })
      .then(() => this.settingsProvider.refreshUserSettings())
      .then(() => {
        this.dialog.openNotification(['Your communications preference has been updated.']);
      })
      .finally(() => (this.updating = false));
  }

  onSubmitPrivacy(): void {
    this.updating = true;

    this.userService
      .acceptFlashPrivacyPolicy()
      .then(() => this.settingsProvider.refreshUserSettings())
      .then(() => {
        this.dialog.openNotification(['Your Privacy Policy acceptance has been recorded.']);
      })
      .finally(() => (this.updating = false));
  }

  onSubmitTerms(): void {
    this.updating = true;

    this.userService
      .acceptFlashTermsAndConditions()
      .then(() => this.settingsProvider.refreshUserSettings())
      .then(() => {
        this.dialog.openNotification(['Your Terms and Conditions acceptance has been recorded.']);
      })
      .finally(() => (this.updating = false));
  }

  videoClicked(e): void {
    this.userService.watchVideo();
  }

  // public onSubmit() {
  //   this.submitted = true;
  //   if (this.profileForm.valid) {
  //     const updateActions = [];
  //     const roleFormControl = this.roleFormControl;
  //     const sendEmailFormControl = this.communicationsForm;
  //     const taxFormGroup = this.taxForm;

  //     this.mxLogger.trace(
  //       'MemberProfilePageComponent',
  //       'onSubmit() this.profileForm.value=',
  //       this.profileForm.value,
  //     );

  //     if (roleFormControl.dirty) {
  //       let roletype = '';
  //       if (roleFormControl.value === 'sales') {
  //         roletype = 'Sales';
  //       } else if (roleFormControl.value === 'technical sales') {
  //         roletype = 'Technical Sales';
  //       } else if (roleFormControl.value === 'sales and technical sales') {
  //         roletype = 'Sales and Technical Sales';
  //       }
  //       updateActions.push(this.userService.setKYIUserRole({ name: roletype }));
  //     }

  //     if (sendEmailFormControl.dirty) {
  //       const sendEmail = sendEmailFormControl.value.toLowerCase() === 'true';
  //       updateActions.push(this.userService.updateEmailPreference({ sendEmail }));
  //     }

  //     if (
  //       this.profileForm.controls.dateOfBirth.dirty ||
  //       this.profileForm.controls.officialName.dirty
  //     ) {
  //       updateActions.push(
  //         this.userService
  //           .updateOfficialData(
  //             this.ngbDateParserFormatter.convertToISOString(
  //               this.profileForm.controls.dateOfBirth.value,
  //             ),
  //             this.profileForm.controls.officialName.value,
  //           )
  //           .then(() => {
  //             this.dialog.openNotification(
  //               [this.translateService.instant('Your profile has been successfully updated.')],
  //               this.translateService.instant('Important'),
  //             );
  //           }),
  //       );
  //     }

  //     if (this.taxForm.dirty) {
  //       updateActions.push(
  //         this.userService.updateTaxInformation(taxFormGroup.value).then(() => {
  //           this.dialog.openNotification(
  //             [
  //               this.translateService.instant(
  //                 'Your tax information has been successfully updated.',
  //               ),
  //             ],
  //             this.translateService.instant('Important'),
  //           );
  //         }),
  //       );
  //     }

  //     const postUpdateAction = () => {
  //       return new Promise<any | void | void>((resolve, reject) => {
  //         if (this.registering) {
  //           this.userService
  //             .markRegistrationComplete()
  //             .then(() => this.settingsProvider.refreshUserSettings())
  //             .then(() => this.router.navigate(['/']))
  //             .then(() => resolve(null))
  //             .catch((err) => reject(err));
  //         } else {
  //           // TODO confirm save?
  //           this.profileForm.markAsPristine();
  //           this.submitted = false;
  //           resolve(null);
  //         }
  //       });
  //     };

  //     this.updating = true;
  //     Promise.all(updateActions)
  //       .then(postUpdateAction)
  //       .catch((err) => {
  //         console.error(err);

  //         this.dialog.openError(
  //           [this.translateService.instant('There has been an error. Please, try again later.')],
  //           this.translateService.instant('Important'),
  //         );
  //       })
  //       .finally(() => {
  //         this.updating = false;
  //       });
  //   } else {
  //     this.dialog.openNotification(
  //       [this.translateService.instant('You have not completed all required fields.')],
  //       this.translateService.instant('Important'),
  //     );
  //   }
  // }

  private getFieldContent(field: ItemField): string {
    return field && field.value ? field.value.value : undefined;
  }

  showPrimaryTaxIdInfo(): void {
    this.dialog.openFeatureDialog(
      'primaryTaxIdInfoDialog',
      [this.primaryTaxIdentifierInformation],
      { size: 'lg' },
    );
  }
}
