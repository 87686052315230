import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent implements OnInit {
  userIsPower: boolean = false;
  constructor(private authService: AuthenticationService, private router: Router) {}

  async ngOnInit() {
    try {
      const user = await this.authService.currentUserInfo();
      console.log(user);
      if (user) {
        // already logged in so go to sample page
        console.log('LOGIN PAGE: Redirect Home');
        this.router.navigate(['/auth/login']);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async login() {
    const l = await this.authService.ibmSignIn();
  }

  async loginFlash() {
    const l = await this.authService.flashSignIn();
    if (l) {
      this.router.navigate(['/auth/my-profile']);
    }
  }

  async loginFlashHome() {
    const l = await this.authService.flashSignIn();
    if (l) {
      this.router.navigate(['/home']);
    }
  }
}
