import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointsSummaryComponent } from './points-summary/points-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { BlockedPageComponent } from './blocked-page/blocked-page.component';
import { ChooseCompanyComponent } from './choose-company/choose-company.component';
import { MemberProfilePageComponent } from './member-profile-page/member-profile-page.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyIsFirmComponent } from './company-is-firm/company-is-firm/company-is-firm.component';

@NgModule({
  declarations: [PointsSummaryComponent, CompanyDetailsComponent, ChooseCompanyComponent, CompanyIsFirmComponent],
  imports: [CommonModule, TranslateModule, RouterModule, ReactiveFormsModule, FormsModule],
  exports: [PointsSummaryComponent, CompanyDetailsComponent, ChooseCompanyComponent],
})
export class UserModule {}
