import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDateCoreParserFormatter extends NgbDateParserFormatter {
  datePipe = new DatePipe('en-US');

  updateLocale(locale: string): void {
    try {
      this.datePipe = new DatePipe(locale);
    } catch (e) {
      console.log('There was a problem setting the locale.');
    }
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          year: toInteger(dateParts[2]),
          month: toInteger(dateParts[1]),
          day: toInteger(dateParts[0]),
        };
      }
    }
    return null;
  }

  parseIso(isoDateString: string): NgbDateStruct {
    if (isoDateString) {
      const dateHoursParts = isoDateString.trim().split('T');
      const dateParts = dateHoursParts[0].split('-');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          year: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          day: toInteger(dateParts[2]),
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
      stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
      stringDate += date.year;
    }
    return stringDate;
  }

  formatForApi(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }
    try {
      return new Date(date.year, date.month - 1, date.day).toISOString();
    } catch (e) {
      return '';
    }
  }

  convertToISOString(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      stringDate += date.year;
      stringDate += isNumber(date.month) ? '-' + padNumber(date.month) : '';
      stringDate += isNumber(date.day) ? '-' + padNumber(date.day) : '';
    }
    return stringDate;
  }
}
