import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreSalesStoreService } from 'src/app/core/store/presales-store.service';
import { toInteger } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDetailsInput } from 'src/app/models/confirmation-details-input';

@Component({
  selector: 'ps-presales-confirmation',
  templateUrl: './presales-confirmation.component.html',
  styleUrls: ['./presales-confirmation.component.scss'],
})
export class PresalesConfirmationComponent implements OnInit {
  showRejectionForm: boolean = false;
  showBrocadeForm: boolean = false;

  rejectionForm: FormGroup = this.formBuilder.group({
    reason: [''],
    otherReason: [''],
  });

  brocadeForm: FormGroup = this.formBuilder.group({
    reason: [''],
  });

  meetingId: number;
  assessmentId: number;
  technicalAssessmentId: number;
  action: string;
  actionText: string;
  identifier: string;
  clientIdentifier: string;
  reason: string;

  constructor(
    private preSalesStore: PreSalesStoreService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit() {
    this.meetingId = toInteger(this.route.snapshot.queryParamMap.get('meetingId'));
    this.assessmentId = toInteger(this.route.snapshot.queryParamMap.get('assessmentId'));
    this.technicalAssessmentId = toInteger(
      this.route.snapshot.queryParamMap.get('technicalAssessmentId'),
    );
    this.action = this.route.snapshot.queryParamMap.get('action');
    this.actionText = this.action == "confirm" ? "for approval" : "for rejection";
    this.identifier = this.route.snapshot.queryParamMap.get('identifier');
    this.clientIdentifier = this.route.snapshot.queryParamMap.get('client');
    this.reason = this.route.snapshot.queryParamMap.get('reason');

    console.log('Meeting ID', this.meetingId);

    if (this.technicalAssessmentId && this.technicalAssessmentId > 0 && this.clientIdentifier) {
      this.preSalesStore.confirmTechnicalAssessmentDetails({
        technicalAssessmentId: this.technicalAssessmentId,
        action: this.action,
        hashIdentifier: this.identifier,
        clientIdentifier: this.clientIdentifier,
        reason: this.reason,
      } as ConfirmationDetailsInput);
    } else if (this.technicalAssessmentId && this.technicalAssessmentId > 0 && this.identifier) {
      this.showBrocadeForm = true;
    } else if (this.action === 'confirm') {
      if (this.meetingId && this.meetingId > 0) {
        this.preSalesStore.confirmMeetingDetails({
          meetingId: this.meetingId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
        } as ConfirmationDetailsInput);
      } else if (this.assessmentId && this.assessmentId > 0) {
        this.preSalesStore.confirmAssessmentDetails({
          assessmentId: this.assessmentId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
        } as ConfirmationDetailsInput);
      }
    } else {
      this.showRejectionForm = true;
    }
  }

  onSubmit() {
    if (this.rejectionForm.valid) {
      const reason = this.rejectionForm.value.reason;
      const otherReason = this.rejectionForm.value.otherReason;

      if (this.meetingId && this.meetingId > 0 && this.action) {
        this.preSalesStore.confirmMeetingDetails({
          meetingId: this.meetingId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      } else if (this.assessmentId && this.assessmentId > 0 && this.action) {
        this.preSalesStore.confirmAssessmentDetails({
          assessmentId: this.assessmentId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      } else if (this.technicalAssessmentId && this.technicalAssessmentId > 0 && this.action) {
        this.preSalesStore.confirmTechnicalAssessmentDetails({
          technicalAssessmentId: this.technicalAssessmentId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason === '' ? otherReason : reason,
        } as ConfirmationDetailsInput);
      }
    }
  }

  submitBrocadeForm() {
    if (this.brocadeForm.valid) {
      const reason = this.brocadeForm.value.reason;

      if (this.technicalAssessmentId && this.technicalAssessmentId > 0 && this.action) {
        this.preSalesStore.confirmTechnicalAssessmentDetails({
          technicalAssessmentId: this.technicalAssessmentId,
          action: this.action,
          hashIdentifier: this.identifier,
          clientIdentifier: this.clientIdentifier,
          reason: reason,
        } as ConfirmationDetailsInput);
      }
    }
  }
}
