<!-- <div [innerHtml]="getFieldValue('Body')" *ngIf="contentItem && !loading"></div>
<div *ngIf="loading">
  <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0">
    <svg:rect x="0" y="0" width="30px" height="30px" />
    <svg:rect x="35" y="0" width="100%" height="5px" />
    <svg:rect x="35" y="10" width="70%" height="5px" />
    <svg:rect x="35" y="25" width="100%" height="5px" />
    <svg:rect x="0" y="35" width="100%" height="5px" />
    <svg:rect x="0" y="45" width="45%" height="5px" />
    <svg:rect x="0" y="55" width="100%" height="5px" />
    <svg:rect x="0" y="65" width="100%" height="5px" />
    <svg:rect x="0" y="75" width="30%" height="5px" />
  </content-loader>
</div> -->

<ng-template m9ContentHost></ng-template>
