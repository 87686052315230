import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { filter, take } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { YearRequirementList } from 'src/app/account/points-summary-models';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';
import { UserKyiAccount } from 'src/app/core/model/user/user-kyi-account';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { ApplicationStatus } from '../models/application-status';

@Component({
  selector: 'app-mastercard-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class MastercardLandingPageComponent implements OnInit {
  @Input()
  baseContent: Content;

  @Output()
  applicationStatus: EventEmitter<ApplicationStatus> = new EventEmitter();

  public isLoading$ = this.isLoadingService.isLoading$;

  body: string;
  topSectionSideContent: string;
  topSectionMainContent: string;
  applicationSideContent: string;
  applicationMainContent: string;
  termsAndConditionsLink: string;
  pinImage: string;

  showApplicationForm: boolean;
  accountStatementsByYear: YearRequirementList[];
  yearRequirementsComplete: boolean = false;

  constructor(
    private bankingRest: BankingRestService,
    private layoutService: LayoutService,
    private dialogService: DialogNgbService,
    private isLoadingService: IsLoadingService,
    private bankingStore: BankingStoreService,
    private router: Router,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    this.accountService.getYearRequirements().then((data) => {
      if (data) {
        this.accountStatementsByYear = data;
        if (this.accountStatementsByYear) {
          if (this.getAllCompleted(this.accountStatementsByYear[0])) {
            this.yearRequirementsComplete = true;
          }
        }
      }
    });

    this.bankingRest.getApplicationStatus().then((status) => {
      this.applicationStatus.emit(status);
      if (status) {
        if (!status.isEligible) {
          this.dialogService.openError(['You are not eligible to access the Mastercard']);
        }

        if (status.activeCardPresent) {
          this.router.navigate(['/transactions']);
        }

        if (status.applicationFormCompleted) {
          if (!status.identificationComplete) {
            this.router.navigate(['/user-identification']);
          } else if (!status.documentUploaded) {
            this.router.navigate(['/upload-documents']);
          } else if (!status.cardActivated) {
            this.router.navigate(['/activate-card']);
          }
        }

        this.showApplicationForm = true;
      }
    });

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const topSectionSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Top Section Side Content',
    );
    const topSectionMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Top Section Main Content',
    );
    const applicationSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Application Section Side Content',
    );
    const applicationMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Application Section Main Content',
    );
    const termsAndConditionsLinkField = this.baseContent.fields.find(
      (f) => f.name === 'Terms and conditions Link',
    );

    this.topSectionSideContent = topSectionSideContentField.value.value;
    this.topSectionMainContent = topSectionMainContentField.value.value;
    this.applicationSideContent = applicationSideContentField.value.value;
    this.applicationMainContent = applicationMainContentField.value.value;
    this.termsAndConditionsLink = termsAndConditionsLinkField.value.value;
    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  getAllCompleted(yearRequirementList: YearRequirementList): boolean {
    let allCompleted = true;

    yearRequirementList.requirements.forEach((yearRequirement) => {
      if (yearRequirement.sortOrder !== 0 && yearRequirement.name !== 'Integrity Training') {
        allCompleted = allCompleted && yearRequirement.completed;
      }
    });

    return allCompleted;
  }

  viewPin() {
    this.bankingStore.userAccounts$
      .pipe(filter(Boolean), take(1))
      .subscribe((userAccounts: UserKyiAccount[]) => {
        const activeUserAccount = userAccounts.find(
          (userAccount) => userAccount.statusName === 'MasterCardAccountActive',
        );

        if (activeUserAccount) {
          this.bankingRest.getPin(activeUserAccount.id).then((data) => {
            if (data) {
              this.pinImage = data;
              this.dialogService.openFeatureDialog('<img src="' + this.pinImage + '"/>', []);
            }
          });
        }
      });

    this.bankingStore.getUserAccounts();
  }
}
