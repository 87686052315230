import { Injectable } from '@angular/core';
import { ReferenceData } from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';

import { CompanyRestService } from '../api/rest/company-rest.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyStoreService {
  constructor(private companyRest: CompanyRestService, private isLoadingService: IsLoadingService) {}

  searchUsersInCompany(searchText: string): Observable<ReferenceData[]> {
    return this.isLoadingService.add(this.companyRest.searchUsersInCompany(searchText));
  }
}
