import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './components/dialog/dialog.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogNgbService } from '@motivforce/mx-library-angular';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, NgbModalModule],
  exports: [NgbModalModule],
  entryComponents: [DialogComponent],
})
export class DialogModule {
  constructor(private dialog: DialogNgbService) {
    this.dialog.registerNotificationDialog(DialogComponent);
    this.dialog.registerErrorDialog(DialogComponent);
    this.dialog.registerWarningDialog(DialogComponent);
    this.dialog.registerValidationDialog(DialogComponent);
  }
}