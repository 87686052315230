import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { ApplicationOptionItem } from '../models/application-form-options';
import { CardUpdate, MasterCardAddress } from '../models/card-update';

@Component({
  selector: 'app-request-replacement',
  templateUrl: './request-replacement.component.html',
  styleUrls: ['./request-replacement.component.scss'],
})
export class RequestReplacementComponent implements OnInit {
  @Input()
  baseContent: Content;

  public isLoading$ = this.isLoadingService.isLoading$;
  public loading: boolean;
  requestSuccessful: boolean;
  body: string;
  states: ApplicationOptionItem[];
  submitForm: CardUpdate;
  address: MasterCardAddress;
  requestReplacementForm = new FormGroup({
    addressLine1: new FormControl('', Validators.required),
    addressLine2: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl(''),
    postalCode: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
  });

  constructor(
    private layoutService: LayoutService,
    private bankingRest: BankingRestService,
    private bankingStore: BankingStoreService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
  ) {}

  ngOnInit(): void {
    this.bankingRest.getApplicationOptions().then((options) => {
      this.requestReplacementForm.controls.country.patchValue(options.countryName);
      this.states = options.states;
      if (options.countryName == 'United States' || options.countryName === 'Canada') {
        this.requestReplacementForm.controls.state.setValidators(Validators.required);
      } else {
        this.requestReplacementForm.controls.state.patchValue('Other');
      }
    });
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: '',
      imageUrl: bannerField.value.url,
    });
  }

  onSubmit() {
    this.loading = true;
    if (this.requestReplacementForm.valid) {
      const form = this.requestReplacementForm.getRawValue();

      if (this.bankingStore.currentUserAccountDetail) {
        const account = this.bankingStore.currentUserAccountDetail;

        this.address = {
          address1: form.addressLine1,
          address2: form.addressLine2,
          postCode: form.postalCode,
          state: form.state,
          city: form.city,
          countryCode: form.country,
        };
        this.submitForm = {
          cardStatus: form.cardStatus,
          cardType: account.type,
          countryId: form.country.value,
          state: [form.state],
          address: this.address,
        };

        this.bankingStore
          .requestReplacement(this.submitForm, account.id)
          .then((successful) => {
            this.requestSuccessful = successful;
          })
          .finally(() => (this.loading = false));
      }
    } else {
      this.loading = false;
      let messages: string[] = [];
      Object.keys(this.requestReplacementForm.controls).forEach((key) => {
        if (!this.requestReplacementForm.get(key).valid) {
          let firstLetter = key.substring(0, 1).toLocaleUpperCase();
          let nameWithoutFirst = key.substring(1, key.length);
          let name = firstLetter + nameWithoutFirst;
          messages.push(name + ' is required;');
        }
      });
      this.dialog.openValidation(messages, 'Please check the application');
    }
  }
}
