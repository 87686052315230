<div class="top-header">
  <div class="container">
    <app-top-nav></app-top-nav>

    <!-- <div class="row" *ngIf="showBackToLanding">
      <div class="col-sm-6">
        <img class="logo" alt="" src="../../../assets/images/ibm-logo.png" />
      </div>
      <div class="col-sm-6 text-right">
        <a [routerLink]="'/landing'">Back to Landing</a>
        &nbsp; | &nbsp;
        <a href="https://kyirewards.com">Switch to KYI Site</a>
      </div>
    </div> -->
  </div>
</div>
<div class="middle">
  <ng-container *ngIf="showBanner">
    <div class="d-none d-lg-block desktop-banner">
      <div *ngIf="bigBanner" class="banner-section big-banner">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6">
            <div class="d-flex justify-content-center align-items-center">
              <app-carousel-slider class="w-100" [banners]="carouselBanners"></app-carousel-slider>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!bigBanner && !mediumBanner" class="banner-section small-banner">
        <img class="banner-style" [src]="displayedBannerUrl" />
      </div>
      <div *ngIf="mediumBanner" class="banner-section medium-banner">
        <img class="banner-style" [src]="displayedBannerUrl" />
      </div>
      <div *ngIf="showLogo" class="container banner-content">
        <img class="banner-logo" alt="" src="../../../assets/images/flash-rewards logo.png" />
      </div>
    </div>
    <div class="d-none d-sm-block d-lg-none tablet-banner">
      <!-- <div *ngIf="bigBanner" class="banner-section big-banner">
        <img class="banner-style" [src]="displayedTabletBannerUrl" />
      </div>
      <div *ngIf="!bigBanner && !mediumBanner" class="banner-section small-banner">
        <img class="banner-style" [src]="displayedTabletBannerUrl" />
      </div>
      <div *ngIf="mediumBanner" class="banner-section medium-banner">
        <img class="banner-style" [src]="displayedTabletBannerUrl" />
      </div> -->
      <div class="container banner-content">
        <img class="banner-logo" alt="" src="../../../assets/images/flash-rewards logo.png" />
      </div>
    </div>
    <div class="d-block d-sm-none mobile-banner">
      <!-- <div *ngIf="bigBanner" class="banner-section big-banner">
        <img class="banner-style" [src]="displayedMobileBannerUrl" />
      </div>
      <div *ngIf="!bigBanner && !mediumBanner" class="banner-section small-banner">
        <img class="banner-style" [src]="displayedMobileBannerUrl" />
      </div>
      <div *ngIf="mediumBanner" class="banner-section medium-banner">
        <img class="banner-style" [src]="displayedMobileBannerUrl" />
      </div> -->
      <div class="container banner-content">
        <img class="banner-logo" alt="" src="../../../assets/images/flash-rewards logo.png" />
      </div>
    </div>

    <div class="d-none d-lg-block container banner-content">
      <div class="title" [innerHtml]="displayedTitle"></div>
      <div class="subtitle" [innerHtml]="displayedSubTitle"></div>
      <button
        class="btn btn-primary"
        type="button"
        *ngIf="showButton && buttonMode"
        (click)="buttonClicked()"
      >
        {{ buttonMode === 'sales' ? 'Claim a sale' : ('Record pre-sale activities' | translate) }}
        <span class="arrow">→</span>
      </button>
    </div>
  </ng-container>
  <app-lower-nav *ngIf="showLowerNav"></app-lower-nav>
  <div class="d-none d-lg-block mastercard-header" *ngIf="showMastercardHeader">
    <div class="menu-container">
      <div class="layout-boxed">
        <nav class="navbar navbar-expand-md">
          <div
            [ngbCollapse]="isNavbarCollapsed"
            class="collapse navbar-collapse"
            id="navbarsDefault"
          >
            <ul class="navbar-nav mr-auto">
              <ng-container>
                <li
                  class="nav-item mr-10"
                  [className]="
                    currentMastercardPage === 'Application'
                      ? 'nav-item mr-10 active'
                      : 'nav-item mr-10'
                  "
                >
                  01 <br />
                  Application details
                </li>
                <li
                  class="nav-item mr-10"
                  [className]="
                    currentMastercardPage === 'Identification'
                      ? 'nav-item mr-10 active'
                      : 'nav-item mr-10'
                  "
                >
                  02 <br />
                  User identification
                </li>
                <li
                  class="nav-item mr-10"
                  [className]="
                    currentMastercardPage === 'Documents'
                      ? 'nav-item mr-10 active'
                      : 'nav-item mr-10'
                  "
                >
                  03 <br />
                  Upload documents
                </li>
                <li
                  class="nav-item mr-10"
                  [className]="
                    currentMastercardPage === 'Activate'
                      ? 'nav-item mr-10 active'
                      : 'nav-item mr-10'
                  "
                >
                  04 <br />
                  Activate card
                </li>
              </ng-container>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div
    class="d-none d-lg-block second-level-header"
    *ngIf="secondLevelNavItems && secondLevelNavItems.length > 0"
  >
    <div class="menu-container">
      <div class="layout-boxed">
        <nav class="navbar navbar-expand-md">
          <div
            [ngbCollapse]="isNavbarCollapsed"
            class="collapse navbar-collapse"
            id="navbarsDefault"
          >
            <ul class="navbar-nav mr-auto">
              <ng-container>
                <li
                  *ngFor="let navItem of secondLevelNavItems"
                  class="nav-item mr-10"
                  [className]="navItem.active ? 'nav-item mr-10 active' : 'nav-item mr-10'"
                >
                  <a [routerLink]="navItem.url" class="nav-link">{{ navItem.label }}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <ng-content></ng-content>

  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <p>
            <a href="/terms-and-conditions">Terms and Conditions</a>&nbsp;&nbsp;&nbsp;<a
              href="/privacy-policy"
              >Privacy Policy</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
