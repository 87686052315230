<div class="layout-boxed content">
  <div class="row">
    <div class="col-sm-9">
      <div [innerHtml]="body"></div>
    </div>
    <div class="col-sm-3">
      <a [routerLink]="backLink" class="btn btn-primary full-width">{{ 'Back' | translate }}</a>
      <ng-container *ngIf="isIncentive && canClaim">
        <p>&nbsp;</p>
        <a [routerLink]="['/sales-claiming']" class="btn btn-primary full-width">{{
          'Claim A Sale' | translate
        }}</a>
      </ng-container>
      <ng-container *ngIf="submitRequestDialog">
        <p>&nbsp;</p>
        <a class="btn btn-primary full-width" (click)="onSubmitRequest()">{{
          'Submit a Sale' | translate
        }}</a>
      </ng-container>
    </div>
  </div>
</div>
