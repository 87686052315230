<div class="form-box">
  <form
    *ngIf="technicalAssessmentForm"
    [formGroup]="technicalAssessmentForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="form-title">
          <b>SAN Health Assessment</b>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isPrc">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Select a user' | translate }}:</label>
          <input
            class="form-control"
            type="text"
            formControlName="userId"
            [ngbTypeahead]="userTypeahead"
            [resultFormatter]="formatUsername"
            [inputFormatter]="formatUsername"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Date of assessment' | translate }}</label>
          <input
            class="form-control"
            formControlName="assessmentDate"
            type="date"
            [min]="programPeriod?.startDate?.substring(0, 10)"
            [max]="programPeriod?.endDate?.substring(0, 10)"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Name of customer' | translate }}</label>
          <input class="form-control" formControlName="customerName" type="text" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Assessment type' | translate }}</label>
          <select class="form-control" formControlName="technicalAssessmentTypeId">
            <option value="">{{ 'Select' | translate }}</option>
            <option *ngFor="let type of assessmentTypes" [value]="type.id">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'IBM BP Name' | translate }}</label>
          <input class="form-control" readonly type="text" value="{{ bpName }}" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'IBM BP email' | translate }}</label>
          <input class="form-control" readonly type="text" value="{{ bpEmail }}" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'Company name' | translate }}</label>
          <input class="form-control" readonly type="text" value="{{ companyName }}" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label>{{ 'Upload an eligible SAN assessment in .xls format' | translate }}: *</label>
        <input
          class="form-control"
          type="file"
          style="width: 100%"
          placeholder="Upload an eligible SAN assessment"
          accept=".xls, .xlsx"
          (change)="handleFileUpload($event)"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-9 my-auto mt-5">
        <p>
          {{
            'Upon submission a triggered mail will be sent requesting confirmation of the assessment.'
              | translate
          }}
        </p>

        <p>*Upon confirmation $ will be auto loaded to your IBM Rewards Card.</p>
      </div>
      <div class="col-md-3">
        <button
          type="submit"
          class="btn btn-primary submit"
          [disabled]="submitting ? 'disabled' : null"
        >
          {{ 'Submit' | translate }} <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </form>
</div>
