import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/content/content.service';
import { TopFooterCategory } from './top-footer-menu.model';

@Component({
  selector: 'app-top-footer',
  templateUrl: './top-footer.component.html',
  styleUrls: ['./top-footer.component.scss'],
})
export class TopFooterComponent implements OnInit {
  public categories: TopFooterCategory[];
  constructor(private contentService: ContentService) {}

  ngOnInit() {
    this.contentService.footerMenu.subscribe((footerCategories) => {
      this.categories = footerCategories;
    });
  }
}
