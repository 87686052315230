import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import _ from 'lodash';
import { Content } from 'src/app/models/content.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { AccountActivate, AccountActivateInfo } from '../models/activate';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { UserKyiAccount } from 'src/app/core/model/user/user-kyi-account';
import { filter, take } from 'rxjs/operators';
import { UserKyiAccountDetail } from 'src/app/core/model/user/user-kyi-account-detail';
import { YearRequirementList } from 'src/app/account/points-summary-models';
import { AccountService } from 'src/app/account/account.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';

@Component({
  selector: 'app-activate-card',
  templateUrl: './activate-card.component.html',
  styleUrls: ['./activate-card.component.scss'],
})
export class ActivateCardComponent implements OnInit {
  @Input()
  baseContent: Content;

  public updating = false;
  public isLoading$ = this.isLoadingService.isLoading$;
  body: string;
  accountId: number;
  submitForm: AccountActivate;
  activateInfo: AccountActivateInfo;
  activationForm = new FormGroup({
    cardNumber: new FormControl('', Validators.required),
    activationCode: new FormControl('', Validators.required),
  });
  sideTitle: string;

  accountStatementsByYear: YearRequirementList[];
  yearRequirementsComplete: boolean = false;

  constructor(
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private bankingRest: BankingRestService,
    private router: Router,
    private isLoadingService: IsLoadingService,
    private dialog: DialogService,
    private bankingStore: BankingStoreService,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const sideTitleField = this.baseContent.fields.find((f) => f.name === 'Side Title');

    this.body = bodyField.value.value;
    this.sideTitle = sideTitleField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.accountService.getYearRequirements().then((data) => {
      if (data) {
        this.accountStatementsByYear = data;
        if (this.accountStatementsByYear) {
          if (this.getAllCompleted(this.accountStatementsByYear[0])) {
            this.yearRequirementsComplete = true;
          }
        }
      }
    });

    this.bankingStore.userAccounts$
      .pipe(filter(Boolean), take(1))
      .subscribe((userAccounts: UserKyiAccount[]) => {
        const pendingActivationUserAccount = userAccounts.find(
          (userAccount) => userAccount.statusName === 'MasterCardAccountPendingActivation',
        );

        if (pendingActivationUserAccount) {
          this.bankingStore.currentUserAccountDetail$
            .pipe(filter(Boolean), take(1))
            .subscribe((userAccountDetail: UserKyiAccountDetail) => {
              this.accountId = userAccountDetail.id;
              this.activateInfo = {
                account_id: userAccountDetail.id,
                number: userAccountDetail.number,
                bin_id: userAccountDetail.binId,
                user_id: userAccountDetail.userId,
                currency: userAccountDetail.currency,
                type: userAccountDetail.type,
              };
            });

          this.bankingStore.getUserAccountDetail(pendingActivationUserAccount.id, true);
        }

        this.updating = false;
      });

    this.bankingStore.getUserAccounts();
  }

  getAllCompleted(yearRequirementList: YearRequirementList): boolean {
    let allCompleted = true;

    yearRequirementList.requirements.forEach((yearRequirement) => {
      if (yearRequirement.sortOrder !== 0 && yearRequirement.name !== 'Integrity Training') {
        allCompleted = allCompleted && yearRequirement.completed;
      }
    });

    return allCompleted;
  }

  onSubmit(): void {
    this.updating = true;
    if (this.activationForm.valid) {
      const form = this.activationForm.getRawValue();
      this.activateInfo.security = {
        activation_code: form.activationCode,
      };

      this.activateInfo.number = form.cardNumber;

      this.submitForm = {
        info: this.activateInfo,
      };
      this.isLoadingService.add(
        this.bankingRest
          .activateAccount(this.submitForm, this.accountId)
          .then((result) => {
            if (result) {
              this.router.navigate(['/transactions']);
            } else {
              this.updating = false;
              this.dialog.openModal('An error has occurred, please try again later');
            }
          })
          .catch((error) => {
            this.updating = false;
            this.dialog.openModal('An error has occurred, please try again later');
          }),
      );
    } else {
      this.updating = false;
      this.dialogService.openModal('Please supply both card number and activation code');
    }
  }
}
