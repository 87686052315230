import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from './components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  ngbModalOptions: NgbModalOptions;

  constructor(private ngbModal: NgbModal) {
    this.ngbModalOptions = {
      animation: false,
      backdrop: 'static',
      keyboard: false,
    };
  }

  openModal(message: any, title: string = ''): Promise<any> {
    const modalRef = this.ngbModal.open(DialogComponent, this.ngbModalOptions);

    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;

    return modalRef.result;
  }
}
