import { Component, OnInit, NgZone } from '@angular/core';
import { Hub } from 'aws-amplify';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import base64url from 'base64url';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent implements OnInit {
  impersonateUserId: number;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private zone: NgZone,
    private settingsProvider: SettingsProvider,
  ) {
    console.log('LOGIN REDIRECT PAGE: constructor');
  }

  async ngOnInit() {
    console.log('LOGIN REDIRECT PAGE: Setting up authComplete listener');
    // this.authService.authComplete.subscribe((complete) => {
    //   console.log('LOGIN REDIRECT PAGE: authCompletEvent', complete);
    //   if (complete) {
    //     this.zone.run(() => {
    //       console.log('LOGIN REDIRECT PAGE: Login complete redirect to home');
    //       this.router.navigate(['/']);
    //     });
    //   }
    // });
    try {
      const user = await this.authService.currentUserInfo();
      console.log(user);
      if (user) {
        this.settingsProvider.refreshUserSettings();
        // already logged in so go to home page
        this.router.navigate(['/auth/my-profile']);
      }
      // const l = await this.authService.federatedSignIn();
      // console.log(l);
    } catch (e) {
      console.log(e);
    }
  }
}
