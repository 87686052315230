import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';
import { Auth } from 'aws-amplify';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { AdminService } from './admin-panel/admin.service';
import { ErrorService } from './error/error.service';
import * as ls from 'local-storage';

const uri = `${environment.api.cms}/graphql`; // <-- add the URL of the GraphQL server here
export function createApollo(
  httpLink: HttpLink,
  adminService: AdminService,
  errorService: ErrorService,
): any {
  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        errorService.handleError(err.message, err.extensions.code, err);
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
  const auth = setContext(async (_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const context: any = { headers: {} };

      context.headers.Authorization = `Bearer ${token}`;

      if (adminService && adminService.adminFilters) {
        context.headers['admin-filters'] = JSON.stringify(adminService.adminFilters);
      }

      const impersonateUserId = ls.get<number>('m9-impersonate-user');
      if (impersonateUserId) {
        context.headers['admin-impersonate-user-id'] = impersonateUserId;
      }

      // NOT WORKING CURRENTLY
      // const kyiSystemsUserId = this.authenticationService.getKyiRedirectUserId();
      // if (kyiSystemsUserId) {
      //   context.headers['kyi-systems-user-id'] = kyiSystemsUserId;
      // }

      // Return the headers as usual
      return context;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  });
  const link = ApolloLink.from([
    errorHandler,
    auth,
    httpLink.create({ uri: `${environment.api.cms}/graphql` }),
  ]);
  return {
    link,
    cache: new InMemoryCache({
      dataIdFromObject: (object: any) => {
        if (object.children && object.children.length > 0) {
          return defaultDataIdFromObject(object) + '-withChildren';
        }

        return defaultDataIdFromObject(object) + '-withoutChildren';
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  };
}

@NgModule({
  exports: [HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AdminService, ErrorService],
    },
  ],
})
export class GraphQLModule {}
