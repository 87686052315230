import { Injectable } from '@angular/core';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { BaseSiteRestService } from './base-site-rest-service';

@Injectable({
  providedIn: 'root',
})
export class SettingsRestService extends BaseSiteRestService {
  constructor(protected apiRestService: ApiRestService) {
    super('members/users/settings', apiRestService);
  }

  getUserSettings(): Observable<any> {
    return this.apiRestService.get<any>(`${this.apiBaseUrl}`);
  }
}
