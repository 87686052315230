import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';

@Component({
  selector: 'app-company-is-firm',
  templateUrl: './company-is-firm.component.html',
  styleUrls: ['./company-is-firm.component.scss'],
})
export class CompanyIsFirmComponent implements OnInit {
  baseContent: Content;

  body: string;
  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }
}
