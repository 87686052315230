import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContentModule } from '../content/content.module';
import { SupportModule } from '../support/support.module';
import { UserModule } from '../user/user.module';
import { UiModule } from '../ui/ui.module';
import { RouterModule } from '@angular/router';
import { DefaultTemplateComponent } from './default-template/default-template.component';
import { SalesClaimModule } from '../sales-claim/sales-claim.module';
import { MainLayoutComponent } from './templates/main-layout/main-layout.component';
import { ArticleComponent } from './article/article.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { DynamicLayoutComponent } from './dynamic-layout/dynamic-layout.component';
import { LayoutHostDirective } from './layout-host.directive';
import { ContactFormPageComponent } from './contact-form-page/contact-form-page.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleListPageComponent } from './article-list-page/article-list-page.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { ErrorModule } from '../error/error.module';
import { NgbDateCoreParserFormatter } from '../shared/ngb-date-core-parser-formatter';
import { DialogNgbService, NotificationModule } from '@motivforce/mx-library-angular';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PreSalesModule } from '../presales/presales.module';
import { VideoPageComponent } from './video-page/video-page.component';
import { ApplicationStatusHeaderComponent } from './application-status-header/application-status-header.component';
import { MastercardModule } from '../mastercard/mastercard.module';
import { HomeModule } from '../home/home.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { TermsAndConditionsPageComponent } from './terms-and-conditions-page/terms-and-conditions-page.component';
import { SharedModule } from '../shared/shared.module';
import { AccountModule } from '../account/account.module';

@NgModule({
  declarations: [
    DefaultTemplateComponent,
    LandingPageComponent,
    MainLayoutComponent,
    ArticleComponent,
    ArticleListComponent,
    DynamicLayoutComponent,
    LayoutHostDirective,
    ContactFormPageComponent,
    ArticleDetailComponent,
    ArticleListPageComponent,
    PrivacyPolicyPageComponent,
    TermsAndConditionsPageComponent,
    ApplicationStatusHeaderComponent,
    VideoPageComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    ContentModule,
    UserModule,
    HomeModule,
    UiModule,
    RouterModule,
    SalesClaimModule,
    MastercardModule,
    SupportModule,
    ReactiveFormsModule,
    PreSalesModule,
    ContentLoaderModule,
    ErrorModule,
    FormsModule,
    NotificationModule,
    FontAwesomeModule,
    SharedModule,
    AccountModule,
  ],
  providers: [NgbDateCoreParserFormatter],
  exports: [],
  entryComponents: [
    ArticleListPageComponent,
    ArticleDetailComponent,
    DefaultTemplateComponent,
    ContactFormPageComponent,
    PrivacyPolicyPageComponent,
    TermsAndConditionsPageComponent,
  ],
})
export class LayoutModule {
  constructor(private dialog: DialogNgbService, private library: FaIconLibrary) {
    this.library.addIcons(faInfoCircle);
  }
}
