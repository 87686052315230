import { Component, OnInit, Input } from '@angular/core';
import { Content } from 'src/app/models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { ContentService } from 'src/app/content/content.service';
import { Settings } from 'src/app/entities/settings';
import { LayoutService } from '../layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-article-list-page',
  templateUrl: './article-list-page.component.html',
  styleUrls: ['./article-list-page.component.scss'],
})
export class ArticleListPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  articleListContent: Content;

  articles: any[];

  constructor(
    private contentService: ContentService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    console.log('INIT ArticleListPageComponent');
    this.contentService
      .getContentById(this.baseContent.id, this.translateService.currentLang, true)
      .subscribe((response) => {
        console.log(response);
        this.articleListContent = response.data.contentById;
        console.log(this.articleListContent);

        this.articles = this.articleListContent.children.map((c) => {
          const thumb = c.fields.find((f) => f.name === 'Article Thumbnail');
          const title = c.fields.find((f) => f.name === 'Title');
          return {
            imageUrl: thumb ? thumb.value.url : undefined,
            title: title ? title.value.value : undefined,
            url: c.slug,
          };
        });

        const bannerField = this.articleListContent.fields.find((f) => f.name === 'Banner');
        const headingField = this.articleListContent.fields.find((f) => f.name === 'Heading');

        this.layoutService.changeBanner({
          imageUrl: bannerField.value.url,
          title: headingField.value.value,
        });
      });
  }
}
