<div class="layout-boxed content">
  <div class="row"></div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="form-points-filter">
    <div class="row">
      <div class="col-md-3 filter-container pd-0">
        <div class="form-group">
          <select formControlName="quarterIndex" class="form-control">
            <option [value]="null" hidden>{{ 'Select quarter' | translate }}</option>
            <option *ngFor="let quarter of quarters; let i = index" [value]="i">
              Q{{ i + 1 }} {{ quarter.startDate | date: 'MMM d':undefined:locale }} -
              {{ quarter.endDate | date: 'mediumDate':undefined:locale }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-1 filter-container pd-0">
        <div class="form-group">
          <div>
            <input type="radio" value="CSV" formControlName="fileType" />&nbsp;
            <label class="form-check-label" style="margin-right: 1em"> CSV </label>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-1 filter-container pd-0">
        <div class="form-group">
          <div>
            <input type="radio" value="PDF" formControlName="fileType" />&nbsp;
            <label class="form-check-label" style="margin-right: 1em"> PDF </label>
          </div>
        </div>
      </div> -->
      <div class="col-md-3"></div>

      <div class="col-md-3 filter-container">
        <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid">
          {{ 'Download' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
