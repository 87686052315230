<app-default-layout [showMastercardHeader]="true" [currentMastercardPage]="'Application'">
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="topSectionSideContent"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div [innerHtml]="topSectionMainContent"></div>
      </div>
    </div>
  </div>
  <div class="container content" *ngIf="showApplicationForm">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="applicationSideContent"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="form-box">
          <div *ngIf="yearRequirementsComplete">
            <div class="mb-20" [innerHtml]="applicationMainContent"></div>
            <app-mastercard-application
              [applicationStatus]="applicationStatus"
            ></app-mastercard-application>
          </div>

          <div *ngIf="!yearRequirementsComplete">
            <p>
              You must have met all the eligibility criteria before you can apply for your IBM
              Rewards Card.
            </p>

            <p>
              Please visit <a [routerLink]="'/auth/my-profile'">Manage my profile</a> to check what
              is missing.
            </p>

            <p>
              If you need further support please contact us at
              <a href="mailto:flash@kyirewards.com">flash@kyirewards.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
