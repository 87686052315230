<app-default-layout>
  <div class="container content">
    <div *ngFor="let item of menu.items; let itemIndex = index">
      <span *ngIf="item.showOnMobile === 'false'">
        <a
          class="nav-link"
          [routerLink]="item.path"
          routerLinkActive="active"
          [innerHtml]="item.mobileLabel"
          style="font-size: 10px"
          ><img [src]="item.menuIcon" style="height: 20px; width: auto"
        /></a>
      </span>
    </div>
  </div>
</app-default-layout>
