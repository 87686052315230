import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaqPageComponent } from './faq-page/faq-page.component';
@NgModule({
  declarations: [ContactFormComponent, FaqPageComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [ContactFormComponent, FaqPageComponent],
})
export class SupportModule {}
