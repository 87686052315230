import { environment } from '../../environments/environment';
import { ApiRestService } from '@motivforce/mx-library-angular';

export class BaseCoreService {
  apiBaseUrl: string;
  kyiBaseUrl: string;
  memberBaseUrl: string;
  bankingBaseUrl: string;

  constructor(endpoint: string, protected apiRestService: ApiRestService) {
    this.apiBaseUrl = this.getBaseUrl(endpoint);
    this.kyiBaseUrl = this.getKyiBaseUrl(endpoint);
    this.memberBaseUrl = this.getMemberBaseUrl(endpoint);
    this.bankingBaseUrl = this.getBankingBaseUrl(endpoint);
  }

  protected getBaseUrl(endpoint: string = ''): string {
    return `${environment.api.core.baseUrl}/${endpoint}`;
  }

  protected getMemberBaseUrl(endpoint: string = ''): string {
    return this.getBaseUrl(`${environment.api.core.path.member}/clients/kyi/${endpoint}`);
  }

  protected getKyiBaseUrl(endpoint: string = ''): string {
    return this.getBaseUrl(`${environment.api.core.path.kyi}/${endpoint}`);
  }

  protected getBankingBaseUrl(endpoint: string = ''): string {
    return `${environment.api.core.bankingUrl}`;
  }
}
