import { User } from '../models/user';
import { Country } from '../models/country';

export class Settings {
  data: any;
  siteId: number;
  language: string;
  clientCode: string;
  user: User;
  country: Country;
  claims: string[];
  siteSettings: any;
  programGroupCode: string;
}
