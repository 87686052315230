<header class="header">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <img class="logo" alt="" src="assets/images/IBM-KYI_with-strap_white.png" height="60px" />
      </div>
    </div>
  </div>
</header>
<div class="middle">
  <div class="container h-100 d-flex flex-column align-items-center">
    <div
      class="p-5 w-50 d-flex flex-column justify-content-center align-items-center button-wrapper"
    >
      <div class="w-100 login-form-container">
        <form
          [formGroup]="formGroup"
          class="d-flex align-items-center justify-content-center flex-column w-100"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-row p-1 w-75">
            <input
              id="email"
              type="text"
              formControlName="email"
              placeholder="Email or Username"
              class="w-100"
            />
          </div>
          <div class="form-row p-1 w-75">
            <input
              id="password"
              type="password"
              formControlName="password"
              placeholder="Password"
              class="w-100"
            />
          </div>
          <div class="form-row pt-5 login-buttons">
            <div class="p-1 mt-5">
              <button class="btn btn-primary" type="submit" [disabled]="loading">LOG IN</button>
            </div>
          </div>
        </form>
      </div>
      <div class="separator">
        <span class="text">OR</span>
      </div>

      <button class="btn btn-primary text-uppercase google" (click)="signInWithGoogle()">
        Log in with Google
      </button>
    </div>
  </div>
</div>
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-1">
        <img
          class="mmi-logo"
          src="https://kyisite-assets.s3.eu-west-2.amazonaws.com/images/mmi-logo.png"
          alt=""
        />
      </div>
      <div class="col-sm-9">
        <p>Part of www.ibm.com/partnerworld/knowyouribm</p>
        <p>
          IBM, the IBM logo, are trademarks of IBM Corp registered in many jurisdictions worldwide.
          A current list of IBM trademarks is available on the Web at "Copyright and trademark
          information" at www.ibm.com/legal/copytrade.shtml (US) . Other company, products and
          service marks may be trade marks or service marks of others. Copyright IBM
        </p>
      </div>
      <div class="col-sm-2">
        <img
          class="ibm-logo"
          src="https://kyisite-assets.s3.eu-west-2.amazonaws.com/images/ibm-logo-white.png"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
