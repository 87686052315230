import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { filter, take } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';
import { UserKyiAccount } from 'src/app/core/model/user/user-kyi-account';
import { BankingStoreService } from 'src/app/core/store/banking-store.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { ApplicationStatus } from '../../models/application-status';

@Component({
  selector: 'app-mastercard-default-template',
  templateUrl: './mastercard-default-template.component.html',
  styleUrls: ['./mastercard-default-template.component.scss'],
})
export class MastercardDefaultTemplateComponent implements OnInit {
  @Input()
  baseContent: Content;

  @Output()
  applicationStatus: EventEmitter<ApplicationStatus> = new EventEmitter();

  public isLoading$ = this.isLoadingService.isLoading$;

  body: string;
  notEligibleContent: string;
  earnedEnoughIncentivePoints: boolean = false;
  pinImage: string;

  constructor(
    private bankingRest: BankingRestService,
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private isLoadingService: IsLoadingService,
    private bankingStore: BankingStoreService,
  ) {}

  ngOnInit(): void {
    this.bankingRest.getApplicationStatus().then((status) => {
      this.applicationStatus.emit(status);
    });

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const notEligibleField = this.baseContent.fields.find((f) => f.name === 'Not Eligible Content');

    this.notEligibleContent = notEligibleField.value.value;
    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  viewPin() {
    this.bankingStore.userAccounts$
      .pipe(filter(Boolean), take(1))
      .subscribe((userAccounts: UserKyiAccount[]) => {
        const activeUserAccount = userAccounts.find(
          (userAccount) => userAccount.statusName === 'MasterCardAccountActive',
        );

        if (activeUserAccount) {
          this.bankingRest.getPin(activeUserAccount.id).then((data) => {
            if (data) {
              this.pinImage = data;
              this.dialogService.openModal('<img src="' + this.pinImage + '"/>');
            }
          });
        }
      });

    this.bankingStore.getUserAccounts();
  }
}
