<div class="d-none d-lg-block menu-container">
  <div class="layout-boxed">
    <nav class="navbar navbar-expand-md kyi-navbar">
      <button
        class="navbar-toggler hidden-sm-up"
        type="button"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-controls="navbarsDefault"
        aria-expanded="false"
        aria-label="navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <ul class="navbar-nav mr-auto" *ngIf="menu">
          <ng-container *ngFor="let item of menu.items; let itemIndex = index">
            <!-- <li class="nav-item dropdown" *ngIf="item.items.length > 0">
              <a class="nav-link dropdown-toggle" [routerLink]="item.path" routerLinkActive="active"
                >{{ item.label }} <i class="far fa-chevron-down ml-5 mr-10"></i
              ></a>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  *ngFor="let subItem of item.items"
                  [routerLink]="subItem.path"
                  routerLinkActive="active"
                >
                  {{ subItem.label }}
                </a>
              </div>
            </li> -->
            <li class="nav-item mr-10" *ngIf="item.items.length == 0">
              <a class="nav-link" [routerLink]="item.path" routerLinkActive="active">{{
                item.label
              }}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>

<div class="d-none d-sm-block d-lg-none menu-container">
  <div class="layout-boxed">
    <nav class="navbar navbar-expand-md kyi-navbar">
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <ul class="navbar-nav" *ngIf="menu">
          <ng-container *ngFor="let item of menu.items; let itemIndex = index">
            <li
              class="nav-item mr-10"
              *ngIf="item.items.length == 0 && item.showOnMobile === 'true'"
            >
              <img [src]="item.menuIcon" style="height: 20px; width: auto" />
              <a
                class="nav-link"
                [routerLink]="item.path"
                routerLinkActive="active"
                [innerHtml]="item.tabletLabel"
              ></a>
            </li>
            <li
              class="nav-item"
              ngbDropdown
              display="dynamic"
              placement="top"
              *ngIf="item.items.length > 0 && item.showOnMobile === 'true'"
            >
              <a
                [innerHtml]="item.mobileLabel"
                class="nav-link"
                tabindex="0"
                ngbDropdownToggle
                id="navbarDropdown3"
                role="button"
              >
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                <a
                  class="dropdown-item"
                  *ngFor="let subItem of item.items"
                  [routerLink]="subItem.path"
                  routerLinkActive="active"
                >
                  <img [src]="subItem.menuIcon" />
                  <span *ngIf="subItem.label === 'Change my profile'">&nbsp;</span>
                  {{ subItem.label }}
                </a>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>

<div class="d-block d-sm-none menu-container">
  <div class="layout-boxed">
    <nav class="navbar navbar-expand-md kyi-navbar">
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <ul class="navbar-nav" *ngIf="menu">
          <ng-container *ngFor="let item of menu.items; let itemIndex = index">
            <li
              class="nav-item ml-5 mr-10"
              *ngIf="item.items.length == 0 && item.showOnMobile === 'true'"
            >
              <a
                class="nav-link"
                [routerLink]="item.path"
                routerLinkActive="active"
                [innerHtml]="item.mobileLabel"
                style="font-size: 10px"
              ></a>
            </li>

            <li
              class="nav-item"
              ngbDropdown
              display="dynamic"
              placement="top"
              *ngIf="item.items.length > 0 && item.showOnMobile === 'true'"
            >
              <a
                [innerHtml]="item.mobileLabel"
                class="nav-link"
                tabindex="0"
                ngbDropdownToggle
                id="navbarDropdown3"
                role="button"
              >
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                <a
                  class="dropdown-item"
                  *ngFor="let subItem of item.items"
                  [routerLink]="subItem.path"
                  routerLinkActive="active"
                >
                  <img [src]="subItem.menuIcon" />
                  <span *ngIf="subItem.label === 'Change my profile'">&nbsp;</span
                  >{{ subItem.label }}
                </a>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
