import { NgModule } from '@angular/core';
import { CommonModule, formatPercent } from '@angular/common';
import { SalesClaimListComponent } from './sales-claim-list/sales-claim-list.component';
import { PreviouslyClaimedSalesComponent } from './previously-claimed-sales/previously-claimed-sales.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogNgbService, GenericListModule } from '@motivforce/mx-library-angular';
import { RouterModule } from '@angular/router';
import { SaleDetailsComponent } from './sale-details/sale-details.component';
import { CharityDonationDialogComponent } from './charity-donation-dialog/charity-donation-dialog.component';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SaleClaimPageComponent } from './sale-claim-page/sale-claim-page.component';
import { SaleDetailsPageComponent } from './sale-details-page/sale-details-page.component';
import { PreviouslyClaimedPageComponent } from './previously-claimed-page/previously-claimed-page.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    SalesClaimListComponent,
    PreviouslyClaimedSalesComponent,
    SaleDetailsComponent,
    CharityDonationDialogComponent,
    SaleClaimPageComponent,
    PreviouslyClaimedPageComponent,
    SaleDetailsPageComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    GenericListModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    SharedModule,
  ],
  exports: [
    SalesClaimListComponent,
    PreviouslyClaimedSalesComponent,
    SaleDetailsComponent,
    SaleClaimPageComponent,
    PreviouslyClaimedPageComponent,
    SaleDetailsPageComponent,
  ],
})
export class SalesClaimModule {
  constructor(private dialog: DialogNgbService, private library: FaIconLibrary) {
    this.dialog.registerFeatureDialog('charityDonationDialog', CharityDonationDialogComponent);

    this.library.addIcons(faDollarSign);
  }
}
