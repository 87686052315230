<div class="col-sm-12 modal-body d-flex flex-column justify-content-center">
  <div class="row">
    <div class="col-md-12">
      <h4>Report a missing invoice</h4>
      <p>
        <strong>Step 1:</strong> Make sure the products in the invoice you’re looking for are
        eligible by checking the list of
        <a
          href="https://www-356.ibm.com/partnerworld/wps/servlet/mem/ContentHandler/annletter/ZWAM2001AAMEN"
          target="blank"
          >eligible products</a
        >
      </p>
      <p>
        <strong>Step 2: </strong>Review the date of your sale to ensure it is eligible. Eligibility
        is from the date of the Announcement letter. NOTE: The invoices are not loaded in real time,
        there is a delay from when we receive the data from IBM to when we load .
      </p>
      <p>
        <strong>Step 3: </strong>If you have already done the above two steps, please complete the
        fields below so we can help you
      </p>
      <p style="color: #da1e28">Fields with an * are mandatory</p>
    </div>
  </div>
  <form [formGroup]="missingInvoicesForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>{{ 'Incentive' }} *</label>
          <select
            class="form-control"
            #incentiveSelect
            (change)="incentiveSelected(incentiveSelect.value)"
            formControlName="incentives"
            class="form-control"
          >
            <option disabled>{{ 'Select Incentive' }}</option>
            <option *ngFor="let incentive of incentives">{{ incentive.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>{{ 'Eligible product' | translate }}:*</label>
          <select formControlName="partNumber" class="form-control" #productSelect>
            <option disabled>{{ 'Select eligible product' | translate }}</option>
            <option *ngFor="let partNumber of partNumbers">{{ partNumber.productCode }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>{{ 'Email address' | translate }} *</label>
          <input class="form-control" formControlName="email" type="email" />
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>{{ 'Invoice date' | translate }}*</label>
          <input class="form-control" formControlName="invoiceDate" type="date" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>{{ 'End customer name' | translate }}*</label>
          <input class="form-control" formControlName="endCustomerName" type="text" />
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>{{ 'Serial Number' | translate }}*</label>
          <input class="form-control" formControlName="serialNumber" type="text" />
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="col-sm-12 modal-footer d-flex justify-content-start align-items-center"
  style="margin-bottom: 150px"
>
  <button class="btn btn-primary submit-button" type="submit" (click)="onSubmit()">
    {{ 'Submit' | translate }}
    <span class="arrow">→</span>
  </button>
  <button class="btn btn-inverse" type="button" (click)="close()">
    {{ 'Cancel' | translate }}
  </button>
</div>
