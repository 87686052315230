import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { Content } from 'src/app/models/content.model';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { LayoutService } from 'src/app/layout/layout.service';
import { BankingRestService } from 'src/app/core/api/rest/banking-rest.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent implements OnInit {
  @Input()
  baseContent: Content;

  public isLoading$ = this.isLoadingService.isLoading$;
  public updating = false;

  body: string;
  documentUploaded: FormData;
  idTypes: any = [];
  sideTitle: string;
  formTitle: string;

  mastercardUploadDocumentForm = new FormGroup({
    typeId: new FormControl('', Validators.required),
    uploadDocumentFile: new FormControl(''),
    uploadDocumentImagePath: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private bankingRest: BankingRestService,
    private router: Router,
    private isLoadingService: IsLoadingService,
    private dialogService: DialogNgbService,
  ) {}

  ngOnInit(): void {
    this.bankingRest.getDocumentOptions().subscribe((data) => {
      this.idTypes = data;
    });

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');
    const sideContentField = this.baseContent.fields.find((f) => f.name === 'Side Content');
    const formTitleField = this.baseContent.fields.find((f) => f.name === 'Form Title');

    this.body = bodyField.value.value;
    this.sideTitle = sideContentField.value.value;
    this.formTitle = formTitleField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  handleDocumentUpload(e) {
    const fileToUpload = e.target.files[0] as File;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.documentUploaded = formData;
  }

  onSubmit() {
    this.updating = true;
    const file = this.documentUploaded;
    if (this.mastercardUploadDocumentForm.valid) {
      const form = this.mastercardUploadDocumentForm.value;
      this.isLoadingService.add(
        this.bankingRest
          .submitDocumentation(file, form.typeId)
          .then((result) => {
            if (!result.hasErrors) {
              this.router.navigate(['/activate-card']);
            } else {
              this.updating = false;
              this.dialogService.openError(['An error has occurred, please try again later']);
            }
          })
          .catch((error) => {
            this.updating = false;
            this.dialogService.openError(['An error has occurred, please try again later']);
          }),
      );
    } else {
      this.updating = false;

      this.dialogService.openValidation(
        ['Please ensure you have selected a Document type, and uploaded a document'],
        '',
      );
    }
  }
}
