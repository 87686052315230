import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Settings } from 'src/app/entities/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private apollo: Apollo) {}

  async getSettings(url) {
    const query = gql`
      query settings($url: String!) {
        settings(url: $url) {
          settings
        }
      }
    `;

    const response = await this.apollo
      .query<any>({
        query,
        variables: {
          url,
        },
      })
      .toPromise();

    return response.data.settings.settings;
  }
}
