import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/content/content.service';
import { Menu } from 'src/app/entities/menu';

@Component({
  selector: 'app-lower-nav',
  templateUrl: './lower-nav.component.html',
  styleUrls: ['./lower-nav.component.scss'],
})
export class LowerNavComponent implements OnInit {
  isNavbarCollapsed = false;
  public menu: Menu;
  constructor(private contentService: ContentService) {}

  ngOnInit() {
    this.contentService.menu.subscribe((menu) => {
      this.menu = menu;
    });
  }
}
