import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-blocked-page',
  templateUrl: './blocked-page.component.html',
  styleUrls: ['./blocked-page.component.scss'],
})
export class BlockedPageComponent implements OnInit {
  baseContent: Content;

  body: string;
  updating = false;
  blockMessage: string;

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private settingsProvider: SettingsProvider,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = bodyField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });

    this.userService.checkBlock().then((block) => {
      if (block) {
        this.blockMessage = '';
        block.forEach((blockType) => {
          this.blockMessage = this.blockMessage + ' ' + blockType.displayBlockReason;
        });
      }
    });
  }
}
