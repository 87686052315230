<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-md-2 side-content">
        <p><strong>Your PIN</strong></p>
      </div>
      <div class="col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9 form-box">
        <div class="row">
          <div class="col-sm-8" [innerHtml]="body"></div>
        </div>

        <div class="row">
          <div class="col-sm-8">
            <button type="button" class="btn btn-primary" (click)="viewPin()">
              {{ 'View PIN' | translate
              }}<span class="arrow"
                ><img src="../../../../assets/images/arrow-right-white.svg"
              /></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>
