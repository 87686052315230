<div class="main-wrapper">
  <form [formGroup]="formGroup">
    <div class="form-wrapper">
      <div class="row mt-20">
        <div class="col-md-3">
          <h5>{{ 'Previously claimed invoices' | translate }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="month">{{ 'Select month' | translate }}:</label>
            <select class="form-control" #monthSelect formControlName="month">
              <option [value]="null">{{ 'Choose an option' | translate }}</option>
              <option class="option" *ngFor="let month of months" [ngValue]="month.id">
                {{ month.value }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label>{{ 'End Customer' | translate }}:</label>
            <input
              class="form-control"
              type="text"
              style="width: 100%"
              formControlName="endCustomerName"
              placeholder="Full name"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="h-100 mb-20 d-flex align-items-end">
            <button
              class="w-100 btn btn-primary search-button btn-card"
              type="submit"
              (click)="searchSalesClaims()"
            >
              {{ 'Search' | translate }}
              <img src="../../../assets/images/Search.png" style="width: 14px; height: auto" />
            </button>
          </div>
        </div>
        <div class="col-md-2">
          <div class="h-100 mb-20 d-flex align-items-end">
            <button class="btn btn-link search-button btn-card" type="button">
              {{ 'Reset' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row mb-30">
    <div class="col-md-12">
      <div class="d-none d-md-block">
        <lib-generic-list
          [dataProvider]="dataProvider"
          [totalCount]="resultsLength"
          [columnsProvider]="saleListColumnsProvider"
          [noResultsMessageDisplayKey]="'&nbsp;&nbsp;&nbsp;&nbsp;No eligible sales found'"
          [serverPaging]="true"
          [tableStriped]="false"
          [actionsColumnLeft]="false"
          (stateChanged)="onListStateChanged($event)"
        >
          <ng-container *libGenericListCustomItemRenderer="'detail'; let row">
            <a class="btn btn-primary btn-sales" (click)="viewDetails.emit(row)">{{
              'View details' | translate
            }}</a>
          </ng-container>
          <ng-container *libGenericListCustomItemRenderer="'action'; let row">
            <button
              class="btn btn-primary btn-sales mr-2"
              (click)="
                actionClick({ showUnclaim: true, invoiceGroupId: row.id, options: row.options })
              "
              [disabled]="!row.showUnclaim"
            >
              {{ 'Unclaim sale' | translate }}
            </button>
          </ng-container>
        </lib-generic-list>
      </div>

      <div class="d-block d-sm-none">
        <div class="form-box">
          <div class="mobile-data" *ngFor="let row of dataProvider">
            <div *ngFor="let column of saleListColumnsProvider">
              <div class="col-sm-12">
                <p>
                  <span
                    class="text-left"
                    *ngIf="column.fieldName !== 'detail' && column.fieldName !== 'action'"
                  >
                    <strong>{{ column.columnNameDisplayKey }}: </strong></span
                  >
                  <span class="text-right">
                    <span
                      *ngIf="
                        column.labelFunction &&
                        column.fieldName !== 'detail' &&
                        column.fieldName !== 'action'
                      "
                      >{{
                        row[column.fieldName]
                          | date
                            : (settingsStore.settings?.user?.program?.dateFormat
                                ? settingsStore.settings?.user?.program?.dateFormat
                                : 'dd-MM-yyyy')
                      }}</span
                    >
                    <span
                      *ngIf="
                        !column.labelFunction &&
                        column.fieldName !== 'detail' &&
                        column.fieldName !== 'action'
                      "
                      >{{ row[column.fieldName] }}</span
                    >
                    <span *ngIf="column.fieldName === 'detail'">
                      <button class="btn btn-primary btn-sales" (click)="viewDetails.emit(row)">
                        {{ 'View details' | translate }}
                      </button>
                    </span>
                    <span *ngIf="column.fieldName === 'action'">
                      <button
                        class="btn btn-primary btn-sales mr-2"
                        (click)="
                          actionClick({
                            showUnclaim: true,
                            invoiceGroupId: row.id,
                            options: row.options
                          })
                        "
                        [disabled]="!row.showUnclaim"
                      >
                        {{ 'Unclaim sale' | translate }}
                      </button>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
