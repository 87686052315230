import { HtmlContentComponent } from '../html-content/html-content.component';
import { Type } from '@angular/core';

const mapper = {
  html: {
    component: HtmlContentComponent as Type<any>,
  },
};

export default mapper;
