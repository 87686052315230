import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCoreParserFormatter } from '../../shared/ngb-date-core-parser-formatter';
import { AccountService } from '../account.service';
import { PagedResult } from '../../models/search/search-result';
import { DateSearch } from '../../models/search/date-search';
import { LearnResult } from '../points-search/models';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { CustomNgbDateParserFormatter } from 'src/app/shared/custom-ngb-date-parser-formatter';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AccountStatement, AccountStatements } from '../points-summary-models';
import { Subject, Subscription } from 'rxjs';
import { Settings } from 'src/app/entities/settings';

@Component({
  selector: 'app-individual-search',
  templateUrl: './individual-statement.component.html',
  styleUrls: ['./individual-statement.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }],
})
export class IndividualStatementComponent implements OnInit {
  @Input()
  accountSearchResult: EventEmitter<PagedResult<AccountStatement>>;

  accountStatementSub: Subscription;

  selectedType = {
    label: '',
    title: '',
    type: 'none',
  };

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  showDownloadButtons: boolean = false;
  userIsSales: boolean = false;

  searchContext: DateSearch = {
    searchText: null,
    startDate: null,
    endDate: new Date().toLocaleString(),
    pageSize: 30,
    pageNumber: 1,
  };

  searchResult: PagedResult<AccountStatement> = {
    results: [],
    statistics: {
      currentPage: 1,
      totalPages: 1,
      totalRecords: 0,
    },
  };

  filtersForm = this.formBuilder.group({
    pointsType: ['incentive'],
    searchText: [null],
  });

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private ngbDateParserFormatter: NgbDateCoreParserFormatter,
    private dialogService: DialogService,
    public settingsStore: SettingsStoreService,
    private userStore: UserStoreService,
  ) {}

  ngOnInit() {
    if (this.accountSearchResult) {
      this.accountSearchResult.subscribe((data) => {
        this.searchResult = data;
      });
    }

    this.settingsStore.settings$
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe((settings: Settings) => {
        if (settings.claims) {
          this.userIsSales =
            settings.claims.find((c) => c.includes('flashrewards.users.sales')) !== undefined
              ? true
              : false;
        }
      });
  }

  onSubmit() {
    let startDateString: string = null;
    let endDateString: string = null;

    if (this.startDate != null) {
      startDateString = this.ngbDateParserFormatter.formatForApi(this.startDate);
    }

    if (this.endDate != null) {
      endDateString = this.ngbDateParserFormatter.formatForApi(this.endDate);
    }

    if (
      (this.startDate == null && this.endDate == null) ||
      (this.startDate != null && this.endDate == null) ||
      (this.startDate == null && this.endDate != null)
    ) {
      this.dialogService.openModal('Please enter a start and end date to search by.');
      console.log('Both dates must be used and not one!');
    } else {
      this.searchContext.pageNumber = 1;
      this.searchContext.startDate = startDateString;
      this.searchContext.endDate = endDateString;
      this.executeSearch();
    }
  }

  pageChanged(page: number) {
    console.log('Page changed: ' + page);
    this.searchContext.pageNumber = page;
    this.executeSearch();
  }

  downloadExcel() {
    let startDateString: string = null;
    let endDateString: string = null;

    if (this.startDate != null) {
      startDateString = this.ngbDateParserFormatter.formatForApi(this.startDate);
    }

    if (this.endDate != null) {
      endDateString = this.ngbDateParserFormatter.formatForApi(this.endDate);
    }

    if (
      (this.startDate == null && this.endDate == null) ||
      (this.startDate != null && this.endDate == null) ||
      (this.startDate == null && this.endDate != null)
    ) {
      this.dialogService.openModal('Please enter a start and end date to search by.');
      console.log('Both dates must be used and not one!');
    } else {
      this.searchContext.pageNumber = 1;
      this.searchContext.startDate = startDateString;
      this.searchContext.endDate = endDateString;
      this.searchContext.fileType = 'excel';
      this.executeDownload();
    }
  }

  downloadCsv() {
    let startDateString: string = null;
    let endDateString: string = null;

    if (this.startDate != null) {
      startDateString = this.ngbDateParserFormatter.formatForApi(this.startDate);
    }

    if (this.endDate != null) {
      endDateString = this.ngbDateParserFormatter.formatForApi(this.endDate);
    }

    if (
      (this.startDate == null && this.endDate == null) ||
      (this.startDate != null && this.endDate == null) ||
      (this.startDate == null && this.endDate != null)
    ) {
      this.dialogService.openModal('Please enter a start and end date to search by.');
      console.log('Both dates must be used and not one!');
    } else {
      this.searchContext.pageNumber = 1;
      this.searchContext.startDate = startDateString;
      this.searchContext.endDate = endDateString;
      this.searchContext.fileType = 'csv';
      this.executeDownload();
    }
  }

  executeDownload() {
    if (this.selectedType.type !== 'none' && this.selectedType.type !== null) {
      this.userStore.downloadReport(this.selectedType.type, this.searchContext);
    }
  }

  executeSearch() {
    this.searchContext.searchText = this.filtersForm.controls.searchText.value;

    this.accountService
      .getPointsSearchResults(this.searchContext)
      .then((result) => {
        if (result != null) {
          this.searchResult.results = result.results;
          this.searchResult.statistics.currentPage = result.statistics.currentPage;
          this.searchResult.statistics.totalRecords = result.statistics.totalRecords;
          this.searchResult.statistics.totalPages = result.statistics.totalPages;
        }
      })
      .catch((error) => {
        console.error(`Points search error: `, error);
      });
  }

  ngOnDestroy() {
    if (this.accountStatementSub) {
      this.accountStatementSub.unsubscribe();
    }
  }
}
