import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentInjectorComponent } from './content-injector/content-injector.component';

import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { SharedModule } from '../shared/shared.module';
import { HtmlContentComponent } from './html-content/html-content.component';
import { ContentHostDirective } from './content-host.directive';

@NgModule({
  declarations: [ContentInjectorComponent, HtmlContentComponent, ContentHostDirective],
  imports: [CommonModule, ContentLoaderModule, SharedModule],
  exports: [ContentInjectorComponent, HtmlContentComponent],
  entryComponents: [HtmlContentComponent],
})
export class ContentModule {}
