import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { PreSalesStoreService } from 'src/app/core/store/presales-store.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { MxLoggerService, ReferenceData } from '@motivforce/mx-library-angular';
import { Subject, Observable } from 'rxjs';
import { AssessmentType } from 'src/app/models/assessment-type';
import { AssessmentDetails } from 'src/app/models/assessment-details';
import { filter, takeUntil, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ProgramPeriod } from 'src/app/models/program-period';
import { ProgramStoreService } from 'src/app/core/store/program-store.service';
import { CompanyStoreService } from 'src/app/core/store/company-store.service';

@Component({
  selector: 'ps-technical-assessment-form',
  templateUrl: './technical-assessment-form.component.html',
  styleUrls: ['./technical-assessment-form.component.scss'],
})
export class TechnicalAssessmentFormComponent implements OnInit {
  user: User;
  bpName: string;
  bpEmail: string;
  companyName: string;
  sanAssessmentFile: File;
  submitting: boolean = false;
  isPrc = false;

  technicalAssessmentForm: FormGroup = this.fb.group({
    userId: [''],
    assessmentDate: ['', Validators.required],
    customerName: ['', Validators.required],
    technicalAssessmentTypeId: ['', Validators.required],
  });

  assessmentTypes: AssessmentType[];
  programPeriod: ProgramPeriod;

  userTypeahead = (text$: Observable<string>) =>
    text$.pipe(
      filter((searchText) => searchText.length > 2),
      distinctUntilChanged(),
      switchMap((searchText) => this.companyStore.searchUsersInCompany(searchText))
    );

  onDestroy$: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private settingsProvider: SettingsProvider,
    private preSalesStore: PreSalesStoreService,
    private programStore: ProgramStoreService,
    private companyStore: CompanyStoreService,
    private router: Router,
    private mxLogger: MxLoggerService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.user = this.settingsProvider.settings.data.user;
    this.bpName = this.user.firstName + ' ' + this.user.lastName;
    this.bpEmail = this.user.email;
    let userCompany = this.user.companies.find((c) => c.isPrimary);
    this.companyName = userCompany ? userCompany.name : '';
    this.isPrc = this.user?.userRole.name === 'kyi.flash.firm.prc.access';

    this.preSalesStore.technicalAssessmentTypes$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((assessmentTypes: AssessmentType[]) => {
        this.assessmentTypes = assessmentTypes;
      });

    this.preSalesStore.getTechnicalAssessmentTypes();

    this.programStore.currentProgramPeriod$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((programPeriod: ProgramPeriod) => {
        this.programPeriod = programPeriod;
        if (this.programPeriod) {
          this.programPeriod.startDate = '2022-10-01';
          this.programPeriod.endDate = '2022-12-31';
        }
      });

    this.programStore.getCurrentProgramPeriod();
  }

  onSubmit() {
    this.submitting = true;
    if (this.technicalAssessmentForm.valid) {
      if (!this.sanAssessmentFile) {
        this.dialogService.openModal('Please upload the SAN Assessment file', 'Important');
        return;
      }

      this.preSalesStore
        .submitTechnicalAssessmentPresignedUrl(this.sanAssessmentFile)
        .then((uploadedFileName) => {
          if (uploadedFileName) {
            this.preSalesStore
              .submitTechnicalAssessmentDetails({
                assessmentDate: this.technicalAssessmentForm.get('assessmentDate').value,
                customerName: this.technicalAssessmentForm.get('customerName').value,
                technicalAssessmentTypeId: this.technicalAssessmentForm.get(
                  'technicalAssessmentTypeId',
                ).value,
                userId: this.technicalAssessmentForm.get('userId').value?.id,
                fileName: uploadedFileName,
              })
              .then((result) => {
                if (result) {
                  this.technicalAssessmentForm.reset();
                }
                this.submitting = false;
              });
          } else {
            this.dialogService.openModal('Error uploading file', 'Important');
          }
        });
    } else {
      this.mxLogger.trace('formGroup:', this.technicalAssessmentForm);
      const controlkeys = Object.keys(this.technicalAssessmentForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.technicalAssessmentForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          if (controlkeys[i] === 'email') {
            errorFieldMessages.push(`A valid email address is required.`);
          } else {
            errorFieldMessages.push(`${label} is required.`);
          }
        }
      }

      this.dialogService.openModal(errorFieldMessages, 'Important');
      this.submitting = false;
    }
  }

  handleFileUpload(event) {
    this.sanAssessmentFile = event.target.files[0] as File;
  }

  formatUsername(user: ReferenceData) {
    return user.name;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
