import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout/logout.component';

import { UnauthGuard } from './unauth-gaurd';
import { AuthGuard } from './auth-gaurd';
import { Routes, RouterModule } from '@angular/router';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { LoginImpersonateComponent } from './login-impersonate/login-impersonate.component';
import { LoginAdminContainerComponent } from './components/login-admin-container/login-admin-container.component';
import { LoginContainerComponent } from './components/login-container/login-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MemberProfilePageComponent } from '../user/member-profile-page/member-profile-page.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginContainerComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'login/admin',
    component: LoginAdminContainerComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'impersonate',
    component: LoginImpersonateComponent,
  },
  {
    path: 'redirect',
    component: LoginRedirectComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-profile',
    component: MemberProfilePageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    LogoutComponent,
    LoginRedirectComponent,
    LoginImpersonateComponent,
    LoginAdminContainerComponent,
    LoginContainerComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    AmplifyAngularModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class AuthenticationModule {}
