import { Injectable } from '@angular/core';
import { BaseCoreService } from '../shared/base-core-service';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class SupportService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('support', apiRestService);
  }

  submitHelpRequest(form: any, endpoint: any) {
    const endpointUrl = `${this.memberBaseUrl}/${endpoint}`;
    return this.apiRestService.post<any>(endpointUrl, form);
  }

  getCurrentCourseList() {
    const endpointUrl = `${this.memberBaseUrl}/courses`;
    return this.apiRestService.get<any>(endpointUrl);
  }

  getCurrentIncentiveList(): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/incentives`;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  getCurrentProductsList(incentiveId: number): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/products/` + incentiveId;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }
}
