<div class="layout-boxed content">
  <div class="row"></div>
  <form [formGroup]="filtersForm" (ngSubmit)="onSubmit()" class="form-points-filter">
    <div class="row">
      <div class="col-md-2 filter-container pd-0">
        <div class="form-group">
          <select formControlName="pointsType" class="form-control">
            <option *ngFor="let pointsType of pointsTypes" [value]="pointsType.type">
              {{ pointsType.label | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 filter-container">
        <div class="form-group">
          <div class="input-group">
            <input
              #dp1
              class="form-control"
              [placeholder]="
                settingsStore.settings?.user?.program?.dateFormat
                  ? settingsStore.settings?.user?.program?.dateFormat
                  : 'dd-MM-yyyy'
              "
              name="dp1"
              [(ngModel)]="startDate"
              [ngModelOptions]="{ standalone: true }"
              ngbDatepicker
              #d="ngbDatepicker"
              placement="top"
              [positionTarget]="dp1"
              (click)="d.toggle()"
            />
          </div>
        </div>
      </div>
      <div class="col-md-2 filter-container">
        <div class="form-group">
          <div class="input-group">
            <input
              #dp2
              class="form-control"
              [placeholder]="
                settingsStore.settings?.user?.program?.dateFormat
                  ? settingsStore.settings?.user?.program?.dateFormat
                  : 'dd-MM-yyyy'
              "
              name="dp2"
              [(ngModel)]="endDate"
              [ngModelOptions]="{ standalone: true }"
              ngbDatepicker
              #dd="ngbDatepicker"
              placement="top"
              [positionTarget]="dp2"
              (click)="dd.toggle()"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3 filter-container">
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              [placeholder]="'Search by keyword' | translate"
              placement="top"
              formControlName="searchText"
            />
          </div>
        </div>
      </div>

      <div class="col-md-3 filter-container">
        <button type="submit" class="btn btn-primary">{{ 'Search' | translate }}</button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12 pd-0">
      <h4 *ngIf="selectedType">{{ selectedType.title | translate }}</h4>

      <div class="row" *ngIf="showDownloadButtons">
        <!-- <div class="col-md-3">
          <button type="button" class="btn btn-primary" (click)="downloadExcel()">
            {{ 'Download Excel' | translate }}
          </button>
        </div> -->
        <div class="col-md-3">
          <button type="button" class="btn btn-primary" (click)="downloadCsv()">
            {{ 'Download CSV' | translate }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p>&nbsp;</p>
        </div>
      </div>

      <!-- Learn -->
      <table class="table table-style" *ngIf="selectedType.type == 'learn'">
        <thead>
          <tr>
            <th>{{ 'Course' | translate }}</th>
            <th>{{ 'Completion/Achieved Date' | translate }}</th>
            <th>{{ 'Points' | translate }}</th>
            <th>{{ 'Description' | translate }}</th>
            <th>{{ 'Brand' | translate }}</th>
            <th>{{ 'Status' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="searchResult.results.length > 0">
          <tr *ngFor="let learn of searchResult.results">
            <td>{{ learn.courseCode }}</td>
            <td>
              {{
                learn.completionDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}
            </td>
            <td>{{ learn.points }}</td>
            <td>{{ learn.description }}</td>
            <td>{{ learn.brand }}</td>
            <td>{{ learn.pointsStatus | translate }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="searchResult.results.length === 0">
          <tr>
            <td colspan="4">No record found.</td>
          </tr>
        </tbody>
      </table>

      <!-- Promo -->
      <table class="table table-style" *ngIf="selectedType.type == 'promo'">
        <thead>
          <tr>
            <th>{{ 'Points Credit Date' | translate }}</th>
            <th>{{ 'Points' | translate }}</th>
            <th>{{ 'Promo Code' | translate }}</th>
            <th>{{ 'Invoice Number' | translate }}</th>
            <th>{{ 'Status' | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="searchResult.results.length > 0">
          <tr *ngFor="let promo of searchResult.results">
            <td>
              {{
                promo.creditDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}
            </td>
            <td>{{ promo.points }}</td>
            <td>{{ promo.code }}</td>
            <td>{{ promo.invoiceNumber }}</td>
            <td>{{ promo.pointsStatus | translate }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="searchResult.results.length === 0">
          <tr>
            <td colspan="4">No record found.</td>
          </tr>
        </tbody>
      </table>

      <!-- Tier -->
      <table class="table table-style" *ngIf="selectedType.type == 'tier'">
        <thead>
          <tr>
            <th>
              <strong>{{ 'Date' | translate }}</strong>
            </th>
            <th>
              <strong>{{ 'Credits' | translate }}</strong>
            </th>
            <th>
              <strong>{{ 'Behaviour Type' | translate }}</strong>
            </th>
            <th>
              <strong>{{ 'Description' | translate }}</strong>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="searchResult.results.length > 0">
          <tr *ngFor="let tier of searchResult.results">
            <td>
              {{
                tier.creditDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}
            </td>
            <td>{{ tier.credits }}</td>
            <td>{{ tier.behaviourType }}</td>
            <td>{{ tier.description }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="searchResult.results.length === 0">
          <tr>
            <td colspan="4">No record found.</td>
          </tr>
        </tbody>
      </table>

      <!-- Expired -->
      <table class="table table-style" *ngIf="selectedType.type == 'expired'">
        <thead>
          <tr>
            <th>
              <strong>{{ 'Expiry Date' | translate }}</strong>
            </th>
            <th>
              <strong>{{ 'Points' | translate }}</strong>
            </th>
            <th>
              <strong>{{ 'Description' | translate }}</strong>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="searchResult.results.length > 0">
          <tr *ngFor="let expired of searchResult.results">
            <td>{{ expired.expiryDate }}</td>
            <td>{{ expired.points }}</td>
            <td>{{ expired.description }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="searchResult.results.length === 0">
          <tr>
            <td colspan="4">{{ 'No record found.' | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" *ngIf="searchResult.results.length > 0">
      <div class="col-sm-12">
        <ngb-pagination
          (pageChange)="pageChanged($event)"
          [collectionSize]="searchResult.statistics.totalRecords"
          [pageSize]="searchContext.pageSize"
          [(page)]="searchResult.statistics.currentPage"
          [boundaryLinks]="true"
        ></ngb-pagination>
      </div>
    </div>
  </div>
</div>
