import { NgModule } from '@angular/core';
import { CommonModule, formatPercent } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogNgbService, GenericListModule } from '@motivforce/mx-library-angular';
import { RouterModule } from '@angular/router';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MastercardLandingPageComponent } from './landing/landing.component';
import { ApplicationComponent } from './application/application.component';
import { IdentificationComponent } from './identification/identification.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { ActivateCardComponent } from './activate-card/activate-card.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ReportLostComponent } from './report-lost/report-lost.component';
import { ViewPinComponent } from './view-pin/view-pin.component';
import { MastercardDefaultTemplateComponent } from './default/mastercard-default-template/mastercard-default-template.component';
import { RequestReplacementComponent } from './request-replacement/request-replacement.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    MastercardLandingPageComponent,
    ApplicationComponent,
    IdentificationComponent,
    UploadDocumentComponent,
    ActivateCardComponent,
    TransactionsComponent,
    ReportLostComponent,
    ViewPinComponent,
    MastercardDefaultTemplateComponent,
    RequestReplacementComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    GenericListModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    SharedModule,
  ],
  exports: [
    MastercardLandingPageComponent,
    ApplicationComponent,
    IdentificationComponent,
    UploadDocumentComponent,
    ActivateCardComponent,
    TransactionsComponent,
    ReportLostComponent,
    ViewPinComponent,
    MastercardDefaultTemplateComponent,
    RequestReplacementComponent,
  ],
})
export class MastercardModule {
  constructor(private dialog: DialogNgbService, private library: FaIconLibrary) {
    this.library.addIcons(faDollarSign);
  }
}
