import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';

/**
 * Component used to display top banner dynamically. The component is bound
 * to a Observable in the LayoutService and updated the banner when the observable
 * changes.
 *
 * To change the banner call the LayouteService changeBanner() method
 *
 * @export
 * @class TopBannerComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
})
export class TopBannerComponent implements OnInit {
  public title: string;
  public imageUrl: string;
  public showBanner = false;

  constructor(private layoutService: LayoutService) {
    // Subscribe to the banner Observable in the layout service
    // and update the propeties when it changes
    this.layoutService.banner.subscribe((banner) => {
      if (banner) {
        this.title = banner.title;
        this.imageUrl = banner.imageUrl;
        this.showBanner = true;
      } else {
        this.showBanner = false;
      }
    });
  }

  ngOnInit() {}
}
