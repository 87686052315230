import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsProvider } from '../shared/settings.provider';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NewUserGuard implements CanActivate {
  private prcUrl: string;
  private privacyUrl: string;
  private profileUrl: string;
  private termsAndConditionsUrl: string;
  private companyDetailsUrl: string;
  private unknownCompanyUrl: string;
  private blockedUrl: string;
  private chooseCompanyUrl: string;
  private companyFirmUrl: string;

  constructor(
    private router: Router,
    private settingsProvider: SettingsProvider,
    private userService: UserService,
  ) {
    if (settingsProvider && settingsProvider.settings && settingsProvider.settings.siteSettings) {
      //this.prcUrl = settingsProvider.settings.siteSettings.prcUrl;
      this.privacyUrl = settingsProvider.settings.siteSettings.privacyUrl;
      //this.profileUrl = settingsProvider.settings.siteSettings.profileUrl;
      this.companyFirmUrl = settingsProvider.settings.siteSettings.companyFirmUrl;
      this.termsAndConditionsUrl = settingsProvider.settings.siteSettings.termsAndConditionsUrl;
      this.companyDetailsUrl = settingsProvider.settings.siteSettings.companyDetailsUrl;
      //this.unknownCompanyUrl = settingsProvider.settings.siteSettings.unknownCompanyUrl;
      //this.blockedUrl = settingsProvider.settings.siteSettings.blockedUrl;
      //this.chooseCompanyUrl = settingsProvider.settings.siteSettings.chooseCompanyUrl;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.IsAdministrator()) {
      return true;
    }

    // if (this.blockedUrl && this.UserIsBlocked()) {
    //   if (this.AlreadyNavigatingTo(this.blockedUrl, state)) {
    //     return true;
    //   }
    //   return this.router.parseUrl(this.blockedUrl);
    // }

    if (this.companyFirmUrl && this.CompanyIsFirm()) {
      if (this.AlreadyNavigatingTo(this.companyFirmUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.companyFirmUrl);
    }

    if (this.companyDetailsUrl && !this.CompanyRegistered()) {
      if (this.AlreadyNavigatingTo(this.companyDetailsUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.companyDetailsUrl);
    }

    if (this.chooseCompanyUrl && this.MultipleCompanies()) {
      if (this.AlreadyNavigatingTo(this.chooseCompanyUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.chooseCompanyUrl);
    }

    if (this.unknownCompanyUrl && this.CompanyUnknown()) {
      if (this.AlreadyNavigatingTo(this.unknownCompanyUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.unknownCompanyUrl);
    }

    if (this.termsAndConditionsUrl && !this.TermsAndConditionsAccepted()) {
      if (this.AlreadyNavigatingTo(this.termsAndConditionsUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.termsAndConditionsUrl);
    }

    if (this.privacyUrl && !this.PrivacyPolicyAccepted()) {
      if (this.AlreadyNavigatingTo(this.privacyUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.privacyUrl);
    }

    if (this.prcUrl && this.UserIsPrc() && this.CompanyRewardLevelNotSet()) {
      if (this.AlreadyNavigatingTo(this.prcUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.prcUrl);
    }

    if (this.profileUrl && !this.Registered()) {
      if (this.AlreadyNavigatingTo(this.profileUrl, state)) {
        return true;
      }
      return this.router.parseUrl(this.profileUrl);
    }

    return true;
  }

  private IsAdministrator() {
    // TODO: need a better way of identifying Administrators
    return (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.userRole &&
      this.settingsProvider.settings.data.user.userRole.name === 'Super Administrator'
    );
  }

  private CompanyRegistered() {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.companies
    ) {
      let companies = this.settingsProvider.settings.data.user.companies;
      if ((companies.length == 1 && companies[0]) || companies.length > 1) {
        return true;
      }
    }
  }

  private CompanyIsFirm() {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.companies
    ) {
      let companies = this.settingsProvider.settings.data.user.companies;
      let company = companies[0];
      if (companies.length > 1) {
        company = companies.find((c) => c.isPrimary);
      }
      if (!company) {
        company = companies[0];
      }
      if (
        company.organizationNumber !== '18z7glxf' &&
        (!company.kyiCompanyDetails ||
          (company.kyiCompanyDetails &&
            company.kyiCompanyDetails.isCompanyRewarded != null &&
            company.kyiCompanyDetails.isCompanyRewarded))
      ) {
        return true;
      }
    }
  }

  private CompanyUnknown(): boolean {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.companies &&
      this.settingsProvider.settings.data.user.companies.length > 0
    ) {
      let companies = this.settingsProvider.settings.data.user.companies;
      let company = companies[0];
      if (companies.length > 1) {
        company = companies.find((c) => c.isPrimary);
      }
      if (
        !company.kyiCompanyDetails ||
        (company.kyiCompanyDetails &&
          (!company.kyiCompanyDetails.companyCategory ||
            company.kyiCompanyDetails.companyCategory === 'Unknown'))
      ) {
        return true;
      }
    }
    return false;
  }

  private MultipleCompanies() {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.companies
    ) {
      let companies = this.settingsProvider.settings.data.user.companies;
      if (companies.length > 1) {
        const primaryCompany = companies.find((c) => c.isPrimary);
        if (!primaryCompany || primaryCompany.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  private Registered() {
    return (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.registrationDate
    );
  }

  private PrivacyPolicyAccepted() {
    return (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.acceptedPrivacyPolicy
    );
  }

  private TermsAndConditionsAccepted() {
    return (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.acceptedTermsConditions
    );
  }

  private AlreadyNavigatingTo(fallbackUrl: string, state: RouterStateSnapshot): boolean {
    return state.url.indexOf(fallbackUrl) > -1;
  }

  private UserIsPrc() {
    return (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.userRole &&
      this.settingsProvider.settings.data.user.userRole.name === 'kyi.firm.prc.nom.prc.access'
    );
  }

  private UserIsBlocked() {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.user &&
      this.settingsProvider.settings.data.user.statusId == 3
    ) {
      return true;
    }
  }

  private CompanyRewardLevelNotSet() {
    if (
      this.settingsProvider &&
      this.settingsProvider.settings &&
      this.settingsProvider.settings.data &&
      this.settingsProvider.settings.data.claims
    ) {
      return this.settingsProvider.settings.data.claims.find(
        (c) => c === 'company.rewardLevelNotSet',
      );
    }
  }
}
