import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContentItem } from '../entities/content-item';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { TopFooterCategory } from '../shared/layout/top-footer/top-footer-menu.model';
import { faTable } from '@fortawesome/free-solid-svg-icons';

const ALL_FIELDS = `
  id,
  name,
  slug,
  type,
  sortIndex,
  published,
  publishDate,
  unpublishDate,
  languageId,
  siteId,
  renderComponent,
  children,
  properties,
  fields {
    id,
    name,
    type,
    properties,
    sortIndex,
    advancedValue,
    value,
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public menu: BehaviorSubject<any> = new BehaviorSubject<any>({ items: [] });
  public footerMenu: BehaviorSubject<TopFooterCategory[]> = new BehaviorSubject<
    TopFooterCategory[]
  >([]);

  constructor(private apollo: Apollo) {}

  public getContentByUrlPath(
    url: string,
    languageId: string,
    includeChildren: boolean = false,
    select?: string,
  ) {
    // if a select list is included use it else return all fields
    const fields = select ? select : ALL_FIELDS;

    // create the graphql query
    const query = gql`
      query contentByUrlPath($urlPath: String!, $languageId: String!, $includeChildren: Boolean) {
        contentByUrlPath(urlPath: $urlPath, languageId: $languageId, includeChildren: $includeChildren) {
          ${fields}
        }
      }
    `;
    return this.apollo.query<any>({
      query,
      variables: {
        urlPath: url,
        languageId,
        includeChildren,
      },
    });
    // .valueChanges.subscribe(response => {
    //   console.log(response);
    //   this.content.next(response.data.contentByUrlPath);
    //   this.loadingContent.next(false);
    // });
  }

  public getContentById(
    id: number,
    languageId: string,
    includeChildren: boolean = false,
    select?: string,
  ) {
    console.log('GetContentById', id, languageId, includeChildren);
    const fields = select ? select : ALL_FIELDS;

    // create the graphql query
    const query = gql`
      query contentById($id: Float!, $languageId: String!, $includeChildren: Boolean) {
        contentById(id: $id, languageId: $languageId, includeChildren: $includeChildren) {
          ${fields}
        }
      }
    `;
    console.log(includeChildren);
    return this.apollo.query<any>({
      query,
      variables: {
        id,
        languageId,
        includeChildren,
      },
    });
  }

  private transformMenu(menu: ContentItem) {
    return this.transformMenuItems(menu.children);
  }
  private transformMenuItems(menuItems: ContentItem[]) {
    return menuItems.map((mi) => {
      const labelField = mi.fields.find((f) => f.name === 'Text');
      const label = labelField ? labelField.value.value : '(no label)';
      const mobileLabelField = mi.fields.find((f) => f.name === 'Mobile Text');
      const tabletLabelField = mi.fields.find((f) => f.name === 'Tablet Text');
      const menuIconField = mi.fields.find((f) => f.name === 'Menu Item Icon');
      const mobileLabel = mobileLabelField ? mobileLabelField.value.value : '';
      const tabletLabel = tabletLabelField ? tabletLabelField.value.value : '';
      const menuIcon = menuIconField ? menuIconField.value.url : '';

      return {
        path: mi.properties.url.value,
        showOnMobile: mi.properties.showOnMobile ? mi.properties.showOnMobile.value : 'false',
        label,
        mobileLabel,
        tabletLabel,
        menuIcon,
        items: this.transformMenuItems(mi.children),
      };
    });
  }

  transformFooterMenu(menu: ContentItem): TopFooterCategory[] {
    return menu.children.map((category) => {
      const categoryField = category.fields.find((f) => f.name === 'Name');
      const categoryLabel = categoryField ? categoryField.value.value : '(no label)';

      return {
        name: categoryLabel,
        items: category.children.map((m) => {
          const labelField = m.fields.find((f) => f.name === 'Text');
          const label = labelField ? labelField.value.value : '(no label)';

          return {
            url: m.properties.url.value,
            label,
          };
        }),
      };
    });
  }

  public async initMenu(language: string) {
    const siteUrl = new URL(window.location.href);
    const menuPath = `${siteUrl.origin}/menu-top-menu`;
    const [menuResponse] = await Promise.all([
      this.getContentByUrlPath(menuPath, language, true).toPromise(),
    ]);
    console.log(menuResponse);
    const menu = {
      items: this.transformMenu(menuResponse.data.contentByUrlPath),
    };
    this.menu.next(menu);
  }
}
