import { Component, OnInit } from '@angular/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import base64url from 'base64url';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-login-impersonate',
  templateUrl: './login-impersonate.component.html',
  styleUrls: ['./login-impersonate.component.scss'],
})
export class LoginImpersonateComponent implements OnInit {
  constructor(
    private settingsProvider: SettingsProvider,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  async ngOnInit(): Promise<void> {
    const userId = this.route.snapshot.queryParams.impersonateUserId;
    // first we have to clear existing impersonation;
    this.authenticationService.stopImpersonation();

    // check if the user is logged in and has the required permissions to impersonate
    let loggedIn = false;

    try {
      await Auth.currentAuthenticatedUser();
      loggedIn = true;
    } catch (error) {
      console.log(error);
    }

    // if the user is not logged in we need to send them to the login screen but we need to pass
    // in the userId to the state

    if (loggedIn) {
      await this.authenticationService.setImpersonateUser(userId);
      await this.settingsProvider.init();

      console.log('LOGIN IMPERSONATE PAGE: Redirect Home');
      this.router.navigate(['/']);
    } else {
      const state = {
        impersonateUserId: userId,
      };
      console.log(state);
      const stateString: string = JSON.stringify(state);
      console.log(stateString);
      const base64State = base64url.encode(stateString);

      console.log(base64State);

      this.router.navigate(['/auth/login/admin']);
    }
  }
}
