import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

@Injectable({
  providedIn: 'root'
})
export class InMemoryDataService implements InMemoryDbService {
  constructor() {}

  createDb() {
    const productCategories = [
      {
        id: 1,
        name: 'Electronics & Photography',
        description: '',
        productCount: 35
      },
      {
        id: 2,
        name: 'Experience',
        description: '',
        productCount: 13
      },
      {
        id: 3,
        name: 'Gift Cards',
        description: '',
        productCount: 41
      },
      {
        id: 4,
        name: 'Kitchen & Home',
        description: '',
        productCount: 29
      },
      {
        id: 5,
        name: 'Personal Care & Accessories',
        description: '',
        productCount: 152
      }
    ];

    const products = [
      {
        id: 1,
        name: 'Viator.com e-Gift Certificate $100AUD',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
        type: 'Gift Card',
        onSpecial: false,
        modelNumber: '1234',
        sku: '1234',
        categoryId: 1,
        points: 1000,
        images: [
          {
            imageName: 'image.jpg',
            thumbImageName: 'thumb.jpg',
            blobStorageUrl: 'https://via.placeholder.com/150.jpg',
            thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
          }
        ]
      },
      {
        id: 2,
        name: 'Viator.com e-Gift Certificate $100AUD',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
        type: 'Gift Card',
        onSpecial: false,
        modelNumber: '1234',
        sku: '1234',
        categoryId: 1,
        points: 1000,
        images: [
          {
            imageName: 'image.jpg',
            thumbImageName: 'thumb.jpg',
            blobStorageUrl: 'https://via.placeholder.com/150.jpg',
            thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
          }
        ]
      },
      {
        id: 3,
        name: 'Viator.com e-Gift Certificate $100AUD',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
        type: 'Gift Card',
        onSpecial: false,
        modelNumber: '1234',
        sku: '1234',
        categoryId: 1,
        points: 1000,
        images: [
          {
            imageName: 'image.jpg',
            thumbImageName: 'thumb.jpg',
            blobStorageUrl: 'https://via.placeholder.com/150.jpg',
            thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
          }
        ]
      },
      {
        id: 4,
        name: 'Viator.com e-Gift Certificate $100AUD',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
        type: 'Gift Card',
        onSpecial: false,
        modelNumber: '1234',
        sku: '1234',
        categoryId: 1,
        points: 1000,
        images: [
          {
            imageName: 'image.jpg',
            thumbImageName: 'thumb.jpg',
            blobStorageUrl: 'https://via.placeholder.com/150.jpg',
            thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
          }
        ]
      },
      {
        id: 5,
        name: 'Viator.com e-Gift Certificate $100AUD',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
        type: 'Gift Card',
        onSpecial: false,
        modelNumber: '1234',
        sku: '1234',
        categoryId: 1,
        points: 1000,
        images: [
          {
            imageName: 'image.jpg',
            thumbImageName: 'thumb.jpg',
            blobStorageUrl: 'https://via.placeholder.com/150.jpg',
            thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
          }
        ]
      },
      {
        id: 6,
        name: 'Viator.com e-Gift Certificate $100AUD',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
        type: 'Gift Card',
        onSpecial: false,
        modelNumber: '1234',
        sku: '1234',
        categoryId: 1,
        points: 1000,
        images: [
          {
            imageName: 'image.jpg',
            thumbImageName: 'thumb.jpg',
            blobStorageUrl: 'https://via.placeholder.com/150.jpg',
            thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
          }
        ]
      }
    ];

    const cartItems = [
      {
        id: 1,
        product: {
          id: 1,
          name: 'Viator.com e-Gift Certificate $100AUD',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
          type: 'Gift Card',
          onSpecial: false,
          modelNumber: '1234',
          sku: '1234',
          categoryId: 1,
          points: 1000,
          images: [
            {
              imageName: 'image.jpg',
              thumbImageName: 'thumb.jpg',
              blobStorageUrl: 'https://via.placeholder.com/150.jpg',
              thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
            }
          ]
        },
        quantity: 1
      },
      {
        id: 2,
        product: {
          id: 2,
          name: 'Viator.com e-Gift Certificate $100AUD',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere a erat vitae placerat. Suspendisse fermentum laoreet nunc, in accumsan erat ullamcorper volutpat. Fusce porta velit sit amet magna pulvinar volutpat. Mauris rutrum lorem ut purus mollis fermentum. In semper mi massa, at blandit arcu tincidunt ac. Vivamus quis varius odio. Mauris nibh leo, suscipit sed volutpat at, convallis quis elit. Suspendisse facilisis nulla augue, at ornare justo tincidunt eget. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent ac lectus cursus, feugiat augue eget, sodales tortor. Nunc laoreet dolor ut elit gravida ullamcorper. Cras rhoncus tortor non est mollis, id consectetur lacus sodales.',
          type: 'Gift Card',
          onSpecial: false,
          modelNumber: '1234',
          sku: '1234',
          categoryId: 1,
          points: 1000,
          images: [
            {
              imageName: 'image.jpg',
              thumbImageName: 'thumb.jpg',
              blobStorageUrl: 'https://via.placeholder.com/150.jpg',
              thumbnailBlobStorageUrl: 'https://via.placeholder.com/150.jpg'
            }
          ]
        },
        quantity: 2
      }
    ];

    return { productCategories, products, cartItems };
  }
}
