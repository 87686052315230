<app-default-layout [showMastercardHeader]="true" [currentMastercardPage]="'Identification'">
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="sideTitle"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12 form-box">
        <form [formGroup]="mastercardIdentificationForm" (ngSubmit)="onSubmit()">
          <div class="mb-20" [innerHtml]="formTitle"></div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label>{{ 'ID Type' | translate }} *</label>
                <select formControlName="type" class="form-control">
                  <option disabled>{{ 'Select' | translate }}</option>
                  <option value="{{ idType.key }}" *ngFor="let idType of idTypes">
                    {{ idType.value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label>{{ 'ID Number' | translate }} *</label>
                <input class="form-control" type="text" formControlName="number" />
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label>{{ 'Country of issue' | translate }} *</label>
                <select formControlName="countryOfIssue" class="form-control">
                  <option disabled>{{ 'Select' | translate }}</option>
                  <option value="{{ country.key }}" *ngFor="let country of countries">
                    {{ country.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label>{{ 'Country of residence' | translate }} *</label>
                <select formControlName="countryOfResidence" class="form-control">
                  <option disabled>{{ 'Select' | translate }}</option>
                  <option value="{{ country.key }}" *ngFor="let country of countries">
                    {{ country.value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label>{{ 'ID Issue Date' | translate }} *</label>
                <input class="form-control" type="date" formControlName="issueDate" />
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <label>{{ 'ID Expiry Date' | translate }} *</label>
                <input class="form-control" type="date" formControlName="expiryDate" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-sm-12">
              <button type="submit" class="btn btn-primary">
                {{ 'Submit' | translate }}
                <span class="arrow"
                  ><img src="../../../../assets/images/arrow-right-white.svg"
                /></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-default-layout>
