import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiRestService,
  BaseSiteRestService,
  MxLoggerService,
} from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { PointsSummary } from 'src/app/account/points-summary-models';
import { ProgramPeriod } from 'src/app/models/program-period';
import { DateSearch } from 'src/app/models/search/date-search';
import { environment } from 'src/environments/environment';
import { resolveProjectReferencePath } from 'typescript';

@Injectable({
  providedIn: 'root',
})
export class ProgramRestService extends BaseSiteRestService {
  constructor(private apiRest: ApiRestService, private mxLogger: MxLoggerService) {
    super(environment.api.core.baseUrl, environment.api.core.path.member, 'kyi/program');
  }

  getCurrentProgramPeriod(): Observable<ProgramPeriod> {
    return this.apiRest.get<ProgramPeriod>(
      this.memberBaseUrl + '/current-program-period',
    );
  }

  
}
