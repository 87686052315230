import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Content } from 'src/app/models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { UserService } from 'src/app/user/user.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.scss'],
})
export class VideoPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;
  body: SafeHtml;
  constructor(
    private layoutService: LayoutService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.body = this.sanitizer.bypassSecurityTrustHtml(bodyField.value.value);

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  videoClicked(e): void {
    this.userService.watchVideo().then(() => {
      this.userService.markRegistrationComplete();
    });
  }
}
