<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-md-2 side-content">
        <p>
          <strong>Flash Rewards video</strong>
        </p>
      </div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9 col-sm-12">
        <p>
          <strong> You must watch this short video to be eligible for Flash Rewards.</strong>
        </p>
        <div class="d-none d-sm-block">
          <video width="700" height="auto" controls (play)="videoClicked($event)">
            <source
              src="https://mmicdn.s3.eu-central-1.amazonaws.com/KYI+CDN/Flash/2022-0126+Introducing+the+IBM+FlashSystem+and+SVC+Family.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </div>
        <div class="d-block d-sm-none">
          <video width="350" height="auto" controls (play)="videoClicked($event)">
            <source
              src="https://mmicdn.s3.eu-central-1.amazonaws.com/KYI+CDN/Flash/2022-0126+Introducing+the+IBM+FlashSystem+and+SVC+Family.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </div>
        <p>&nbsp;</p>
        <p>
          You will receive a $50 load to your existing IBM Rewards Card, or you can apply for your
          IBM Rewards Card <a [routerLink]="'/ibm-rewards-card'">here.</a>
        </p>
      </div>
    </div>
  </div>
</app-default-layout>
