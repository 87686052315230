import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { PointsSummary } from 'src/app/account/points-summary-models';
import { SearchResult } from 'src/app/models/search/search-result';
import { User } from 'src/app/models/user';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { UserRestService } from '../api/rest/user-rest.service';
import * as fileSaver from 'file-saver';
import { DateSearch } from 'src/app/models/search/date-search';
import { UserProgramDetails } from '../model/user/user-program-details';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _accountSummary = new BehaviorSubject<PointsSummary>(null);
  readonly accountSummary$ = this._accountSummary.asObservable();

  private readonly _usersInCompany = new BehaviorSubject<User[]>(null);
  readonly usersInCompany$ = this._usersInCompany.asObservable();

  private readonly _systemsDetails = new BehaviorSubject<UserProgramDetails>(null);
  readonly systemsDetails$ = this._systemsDetails.asObservable();

  private readonly _powerDetails = new BehaviorSubject<UserProgramDetails | null>(null);
  readonly powerDetails$ = this._powerDetails.asObservable();

  constructor(
    private userRest: UserRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogService,
  ) {}

  get accountSummary(): PointsSummary {
    return this._accountSummary.getValue();
  }

  get usersInCompany(): User[] {
    return this._usersInCompany.getValue();
  }

  get systemsDetails(): UserProgramDetails {
    return this._systemsDetails.getValue();
  }
  get powerDetails(): UserProgramDetails {
    return this._systemsDetails.getValue();
  }

  getAccountSummary(): void {
    this.isLoadingService.add(
      this.userRest.getAccountSummary().subscribe((accountSummary: PointsSummary) => {
        this._accountSummary.next(accountSummary);
      }),
    );
  }

  getSystemsDetails(): void {
    if (this.systemsDetails) {
      return;
    }

    this.userRest.getSystemsDetails().subscribe((systemsDetails) => {
      this._systemsDetails.next(systemsDetails);
    });
  }

  downloadReport(endpoint: string, searchContext: DateSearch): void {
    this.isLoadingService.add(
      this.userRest.downloadReport(endpoint, searchContext).subscribe((result) => {
        const blob: any = new Blob([result.content], { type: result.contentType });
        fileSaver.saveAs(blob, result.fileName);
      }),
    );
  }

  downloadStatement(fileType: string, startDate: string, endDate: string): void {
    this.isLoadingService.add(
      this.userRest.downloadStatement(fileType, startDate, endDate).subscribe((result) => {
        const blob: any = new Blob([result.content], { type: result.contentType });
        fileSaver.saveAs(blob, result.fileName);
      }),
    );
  }

  setPrimaryCompany(companyId: number): void {
    this.isLoadingService.add(
      this.userRest.setPrimaryCompany(companyId).subscribe(() => {
        window.location.reload();
      }),
    );
  }

  getPowerDetails(): void {
    if (this.powerDetails) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getPowerDetails().subscribe((powerDetails: UserProgramDetails) => {
        this._powerDetails.next(powerDetails);
      }),
    );
  }
}
