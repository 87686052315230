import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserPowerDetails } from 'src/app/models/user-power-details';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  showPower: boolean = false;

  constructor(private userService: UserService) {}
  ngOnInit() {
    this.userService.getPowerDetails().then((x: UserPowerDetails) => {
      if (x) {
        this.showPower = x.powerProgramAvailable;
      }
    });
  }
}
