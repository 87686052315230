import { Component, OnInit, Input } from '@angular/core';
import { DisplayComponent } from '../shared/display.component';
import { ItemField } from 'src/app/entities/content-item';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'm9-html-content',
  templateUrl: './html-content.component.html',
  styleUrls: ['./html-content.component.scss'],
})
export class HtmlContentComponent implements OnInit, DisplayComponent {
  @Input() field: ItemField;

  constructor() {}

  ngOnInit() {}
}
