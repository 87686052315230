import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { PointsSummary } from '../points-summary-models';

@Component({
  selector: 'app-statement-points-summary',
  templateUrl: './statement-points-summary.component.html',
  styleUrls: ['./statement-points-summary.component.scss'],
})
export class StatementPointsSummaryComponent implements OnInit, OnDestroy {
  @Input()
  pointsSummary: EventEmitter<PointsSummary>;

  @Output()
  scrollToDetail: EventEmitter<any> = new EventEmitter<any>();

  pointsSummarySub: Subscription;
  summary: PointsSummary;
  canClaim: boolean;
  companyRewarded: boolean;

  constructor(private settingsProvider: SettingsProvider, private router: Router) {}

  ngOnInit(): void {
    this.pointsSummarySub = this.pointsSummary.subscribe((summary) => {
      if (summary != null) {
        this.summary = summary;
      }
    });
  }

  ngOnDestroy() {
    if (this.pointsSummarySub) {
      this.pointsSummarySub.unsubscribe();
    }
  }

  public requestScrollToDetail() {
    this.scrollToDetail.emit();
    return false;
  }
}
