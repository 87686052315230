<div class="form-box">
  <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-12 contact-name">
        <h2>{{ settings.user.firstName }} {{ settings.user.lastName }}</h2>
        <p>
          <strong>{{ settings.user.email }}</strong>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <div class="form-group">
          <label>{{ 'Please select a category' | translate }}:</label>
          <select formControlName="category" class="form-control">
            <option *ngFor="let category of categories">{{ category.label }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label>{{ 'Subject' | translate }}: *</label>
          <input class="form-control" formControlName="subject" type="text" style="width: 100%" />
        </div>
      </div>
    </div>
    <div class="row"></div>
    <div class="row" style="margin-top: 1em; padding-bottom: 1em">
      <div class="col-md-10">
        <div class="form-group">
          <label>{{ 'Your message' | translate }}: *</label>
          <textarea class="form-control" type="text" formControlName="message" rows="10"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit">
          {{ 'Submit' | translate }}
          <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
        </button>
      </div>
    </div>
  </form>
</div>
