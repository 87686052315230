import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/content/content.service';
import { Menu } from 'src/app/entities/menu';

@Component({
  selector: 'app-more-nav',
  templateUrl: './more-nav.component.html',
  styleUrls: ['./more-nav.component.scss'],
})
export class MoreNavComponent implements OnInit {
  public menu: Menu;
  constructor(private contentService: ContentService) {}

  ngOnInit() {
    this.contentService.menu.subscribe((menu) => {
      this.menu = menu;
    });
  }
}
