<app-default-layout [title]="'<h1>FAQs</h1>'" [showBackToLanding]="true">
  <div class="container content">
    <div class="row">
      <div class="col-md-3 title-content pt-50" [innerHtml]="bodyContent"></div>
      <div class="col-md-9">
        <ngb-accordion *ngIf="categories && categories.length > 0">
          <ng-container *ngFor="let item of categories">
            <ngb-panel [title]="item.panels[0].heading" class="accordion-header">
              <ng-template ngbPanelContent>
                <div *ngIf="item.panels[0].body" [innerHTML]="item.panels[0].body"></div>
              </ng-template>
            </ngb-panel>
          </ng-container>
        </ngb-accordion>
      </div>
    </div>
  </div>
</app-default-layout>
