import { Injectable } from '@angular/core';
import {
  ApiRestService,
  BaseSiteRestService,
  MxLoggerService,
} from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { PointsSummary } from 'src/app/account/points-summary-models';
import { DateSearch } from 'src/app/models/search/date-search';
import { environment } from 'src/environments/environment';
import { UserProgramDetails } from '../../model/user/user-program-details';

@Injectable({
  providedIn: 'root',
})
export class UserRestService extends BaseSiteRestService {
  constructor(private apiRest: ApiRestService, private mxLogger: MxLoggerService) {
    super(environment.api.core.baseUrl, environment.api.core.path.member, 'kyi/users');
  }

  getAccountSummary(): Observable<PointsSummary> {
    return this.apiRest.get<PointsSummary>(
      environment.api.core.baseUrl + '/members/users/account-summary',
    );
  }

  claimSale(invoiceId: number): Observable<any> {
    return this.apiRest.put<any>(
      environment.api.core.baseUrl + '/members/sales/claim/' + invoiceId,
      {},
    );
  }

  unclaimSale(invoiceId: number): Observable<any> {
    return this.apiRest.put<any>(
      environment.api.core.baseUrl + '/members/sales/unclaim/' + invoiceId,
      {},
    );
  }

  getSystemsDetails(): Observable<UserProgramDetails> {
    return this.apiRest.get<UserProgramDetails>(`${this.memberBaseUrl}/systems-details`);
  }

  downloadReport(
    endpoint: string,
    searchContext: DateSearch,
  ): Observable<{ content: Blob; fileName: string; contentType: string }> {
    return this.apiRest
      .download(
        `${this.coreBaseUrl}/members/clients/kyi/account/points/download/${endpoint}/from/${searchContext.startDate}/to/${searchContext.endDate}/${searchContext.fileType}`,
      )
      .pipe(
        map((response) => {
          this.mxLogger.debug(
            'UserRestService',
            'downloadReport() contentType=' +
              response.headers.get('Content-Type') +
              ' fileName=' +
              response.headers.get('Filename'),
          );

          return {
            content: response.body,
            fileName: response.headers.get('Filename'),
            contentType: response.headers.get('Content-Type'),
          };
        }),
      );
  }

  downloadStatement(
    fileType: string,
    startDate: string,
    endDate: string,
  ): Observable<{ content: Blob; fileName: string; contentType: string }> {
    return this.apiRest
      .download(
        `${this.coreBaseUrl}/members/clients/kyi/account/download-company-quarter-points/from/${startDate}/to/${endDate}/${fileType}`,
      )
      .pipe(
        map((response) => {
          this.mxLogger.debug(
            'UserRestService',
            'downloadStatement() contentType=' +
              response.headers.get('Content-Type') +
              ' fileName=' +
              response.headers.get('Filename'),
          );

          return {
            content: response.body,
            fileName: response.headers.get('Filename'),
            contentType: response.headers.get('Content-Type'),
          };
        }),
      );
  }

  setPrimaryCompany(companyId: number): Observable<void> {
    return this.apiRest.post<any>(this.memberBaseUrl + '/primary-company/' + companyId, {});
  }

  checkConfirmedStatus(): Observable<boolean> {
    return this.apiRest.get<boolean>(
      environment.api.core.baseUrl + '/members/clients/kyi/users/paytm',
    );
  }

  updatePayTmDetails(mobileTelephone: string): Observable<any> {
    return this.apiRest.post(
      environment.api.core.baseUrl + '/members/clients/kyi/users/paytm/' + mobileTelephone,
      null,
    );
  }

  confirmPayTm(form: any): Observable<any> {
    return this.apiRest.post(
      environment.api.core.baseUrl + '/members/clients/kyi/users/paytm/confirm',
      form,
    );
  }

  getPowerDetails(): Observable<UserProgramDetails> {
    return this.apiRest.get<UserProgramDetails>(`${this.memberBaseUrl}/power-details`);
  }
}
