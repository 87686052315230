<app-default-layout
  [bannerUrl]="
    'https://m9-cms-dev.s3.eu-west-2.amazonaws.com/public/l071q3nnl00yw3yhclaim%20a%20sale.jpeg'
  "
  [title]="'<h1>Claim a sale:<br>View details of invoice</h1>'"
>
  <div class="container content content-bottom-border">
    <div class="row">
      <div class="col-md-2 side-content">
        <p>
          <strong>{{ 'Sale details' | translate }}</strong>
        </p>
        <div class="mt-30">
          <a class="btn btn-primary w-100" type="button" (click)="goBack()">
            {{ 'Back' | translate }}
            <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
          </a>
        </div>
      </div>
      <div class="col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9">
        <app-sale-details [saleId]="saleId"></app-sale-details>
      </div>
    </div>
  </div>
</app-default-layout>
