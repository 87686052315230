<div class="modal-body m-3 d-flex flex-column justify-content-center">
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <h3><strong>IBM Flash Rewards Sales Incentive&nbsp;</strong></h3>
  <p>
    <strong
      >(Available in American Samoa, Guam, Puerto Rico, United States, U.S. Virgin Islands,&nbsp;
      Canada)</strong
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >This IBM PartnerWorld &ndash; Know Your IBM Supplement (&ldquo;KYI Supplement&rdquo;) as
      described in the IBM&nbsp; PartnerWorld &ndash; Know Your IBM Attachment (&ldquo;KYI
      Attachment&rdquo;) conveys the details of an eligible KYI&nbsp; offering. The terms of this
      Supplement prevail over the terms of the KYI Attachment and the terms of the&nbsp; IBM
      PartnerWorld Agreement &ndash; International Basic General Terms (&ldquo;PWA&rdquo;)</span
    >
  </p>
  <p><strong>Start Date: January 1</strong><strong>st</strong><strong>, 2022&nbsp;</strong></p>
  <p><strong>End Date: December 31st, 2022&nbsp;</strong></p>
  <p><strong>The Offer&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >IBM Partner Ecosystem operates on a preferred Distributor model. To qualify for IBM Hardware
      or&nbsp; Software incentives, each Business Partner is required to select their preferred
      Distributor. Only&nbsp; purchases made from your preferred Distributor will be counted towards
      incentives described in this&nbsp; offering.&nbsp;&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >Qualifying Sellers of IBM client facing Business Partner - Resellers who acquire from an IBM
      Authorized&nbsp; Distributor and resell to a qualifying end user eligible IBM FlashSystem
      Storage, or sell eligible IBM&nbsp; Storage as a Service part numbers may qualify to earn
      reward funds that will be placed on to a reloadable&nbsp; IBM Rewards Card equal to the value
      shown on the
      <a
        href="https://www.ibm.com/partnerworld/page/pw_ast_IBMFLASHREWARDS-eligible-product-list"
        target="_blank"
        rel="noopener"
        >IBM Flash Rewards Eligible Product Table.&nbsp;&nbsp;
      </a></span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >A qualifying transaction is a sale to a single end user customer. Multiple orders for the
      same end user&nbsp; may be placed for a single transaction resulting in multiple invoices.
      These are all viewed as a single&nbsp; transaction provided all transactions are invoiced to
      the same end user and there are no more than 10&nbsp; business days between the first and last
      invoice. Maximum earnings per transaction $20,000 USD.&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >Technical Sellers can earn for Pre-Sales activities to NEW FlashSystem Clients for Client
      Meeting as well&nbsp; as performing Client Assessments. Maximum per client is $500 USD.</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >To participate in this incentive, IBM client facing Business Partner - Resellers must acquire
      and resell to a&nbsp; qualifying end user (within the valid dates of this offering) the IBM
      FlashSystem Storage, or sell eligible&nbsp; IBM Storage as a Service. See eligible products
      list at</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      ><a
        href="https://www.ibm.com/partnerworld/page/pw_ast_IBMFLASHREWARDS-eligible-product-list"
        target="_blank"
        rel="noopener"
        >https://www.ibm.com/partnerworld/page/pw_ast_IBMFLASHREWARDS-eligible-product-list.</a
      ></span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >For the purposes of this incentive, the full-time Seller or Technical Seller of the IBM
      Business Partner&nbsp; claiming a reward under this incentive represents and acts on behalf of
      their IBM Business Partner&nbsp; (Reseller) company. Reward funds earned under this incentive
      will count towards the annual maximum as&nbsp; shown on the table below.&nbsp;</span
    >
  </p>
  <p>
    <strong
      >Note
      <span style="font-weight: 400"
        >that Firm level payment option is not available under the IBM Flash Rewards
        incentive.</span
      ></strong
    >
  </p>
  <table
    style="border-collapse: collapse; width: 82.2111%; height: 222px"
    border="1"
    cellpadding="10px"
  >
    <thead>
      <tr style="height: 76px">
        <th style="width: 56.3643%; height: 76px">
          <p style="text-align: center"><strong>Market&nbsp;</strong></p>
        </th>
        <th style="width: 39.2589%; height: 76px">
          <p><strong>Individual Earnings Limit under this incentive (USD)</strong></p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 92px">
        <td style="width: 56.3643%; height: 92px; text-align: center">
          <p>
            <span style="font-weight: 400"
              >American Samoa, Guam, Puerto Rico, United States, U.S. Virgin&nbsp; Islands,
              Canada</span
            >
          </p>
        </td>
        <td style="width: 39.2589%; height: 92px; text-align: center">
          <p><span style="font-weight: 400">$20,000 per qualifying&nbsp;&nbsp;</span></p>
          <p><span style="font-weight: 400">transaction</span></p>
        </td>
      </tr>
      <tr style="height: 54px">
        <td style="width: 56.3643%; height: 54px; text-align: center">
          <p>
            <span style="font-weight: 400"
              >American Samoa, Guam, Puerto Rico, United States, U.S. Virgin&nbsp; Islands,
              Canada</span
            >
          </p>
        </td>
        <td style="width: 39.2589%; height: 54px; text-align: center">
          <p><span style="font-weight: 400">$100,000 per calendar year</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <p><br /><br /></p>
  <p><strong>Country Availability&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >American Samoa, Guam, Puerto Rico, United States, U.S. Virgin Islands, Canada <br
    /></span>
  </p>
  <p>
    <span style="font-weight: 400"
      ><strong>IBM Flash Reward Incentive Structure&nbsp;</strong>
    </span>
  </p>
  <p>
    <img
      style="max-width: 700px; height: auto"
      src="https://mmicdn.s3.eu-central-1.amazonaws.com/KYI+CDN/Flash/home-page-ppt.PNG"
    />
  </p>
  <p><strong>NEW SAN Incentive</strong></p>
  <p><strong>Start date: July 1, 2022</strong></p>
  <p>
    <img
      style="max-width: 700px; height: auto"
      src="https://mmicdn.s3.eu-central-1.amazonaws.com/KYI+CDN/Flash/broadcom-incentive.PNG"
    />
  </p>
  <p>
    <span style="font-weight: 400"
      >Note: To receive a benefit for the NEW SAN incentive the SAN units must be sold with an
      eligible IBM FlashSystem solution.&nbsp; SAN units must be sold in pairs</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >All reward funds earned will automatically be awarded to the individuals reloadable IBM
      Rewards Card.&nbsp; To participate in this incentive partners will need to register at
      <a href="www.ibm.com/partnerworld/ibmflashrewards" target="_blank" rel="noopener"
        >www.ibm.com/partnerworld/ibmflashrewards</a
      ></span
    >
  </p>
  <h3><span style="font-weight: 400"> </span><strong>Incentive Details&nbsp;</strong></h3>
  <p><strong>Getting started incentive&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >To get you started individual registrants will earn $50 USD that will be applied to their IBM
      Rewards Card&nbsp; when they complete the following:&nbsp;</span
    >
  </p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Ensure they have met all compliance requirements including</span
      >
      <ul>
        <li><span style="font-weight: 400">accepting the terms and conditions&nbsp; </span></li>
        <li><span style="font-weight: 400">completing their profile information&nbsp; </span></li>
        <li><span style="font-weight: 400">completion of the IBM Integrity Training</span></li>
      </ul>
    </li>
  </ul>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Watch the following video &lsquo;Introducing the IBM FlashSystem and SVC Family&rsquo;
        available on the IBM&nbsp; Flash Rewards portal.</span
      >
    </li>
  </ul>
  <p>
    <span style="font-weight: 400"
      >NOTE: The IBM Power Rewards incentive also has a Getting Started (GS) benefit.&nbsp; If you
      earned a GS benefit under IBM Power Rewards you are not eligible to earn again under IBM Flash
      Rewards.</span
    >
  </p>
  <p><strong>Pre-Sales Incentives&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Pre-sales activities (includes Client Meeting and Assessments) are only available for
        individuals&nbsp; who hold a Technical Seller role within their firm.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Pre-sales activity benefits are only eligible for New FlashSystem Clients or White Space
        accounts.&nbsp; Individuals can send an email to
        <a href="mailto:flash@kyirewards.com">flash@kyirewards.com</a> to verify if their potential
        client is&nbsp; considered a New Client.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >One benefit payment for first Client Meeting, and one benefit payment for a Client
        Assessment, for&nbsp; a total of $500 USD per single end user client.
      </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >For pre-sales activity to qualify an IBM Storage Seller must be present for meetings.</span
      >
    </li>
  </ul>
  <p>
    <span style="font-weight: 400"
      ><strong>How do I submit a pre-sales activity?&nbsp;</strong>
    </span>
  </p>
  <ul>
    <li>
      <span style="font-weight: 400">
        Click on the &lsquo;Pre-Sales Activity&rsquo; tab within the IBM Flash Rewards app or online
        version. You will&nbsp; be presented with two options.&nbsp;&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400"> </span
      ><strong
        >Client Meeting
        <span style="font-weight: 400"
          >- Complete the mandatory fields and upon confirmation of the meeting&nbsp; funds will be
          auto-loaded to your IBM Rewards Card.&nbsp;</span
        ></strong
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Qualifying client meetings require a Deal Registration #, along with the
        collaboration&nbsp; of an IBM Storage Seller. Meetings need to be substantive with the
        purpose of driving&nbsp; potential Storage sales&nbsp;</span
      >
      <ul>
        <li>
          <span style="font-weight: 400">In person meeting (e.g. customer premises, event)</span>
        </li>
        <li>
          <span style="font-weight: 400">Virtual meeting (e.g. via phone, web conference)</span>
        </li>
      </ul>
    </li>
  </ul>
  <ul>
    <li>
      <span style="font-weight: 400"> </span
      ><strong
        >Assessment -
        <span style="font-weight: 400"
          >Complete the mandatory fields and upon confirmation of the assessment&nbsp; funds will be
          auto-loaded to your IBM Rewards Card</span
        ></strong
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >The following activities are considered eligible assessments,</span
      >
      <ul>
        <li><span style="font-weight: 400">Onsite PoC/Benchmark&nbsp; </span></li>
        <li>
          <span style="font-weight: 400"
            >Washington Systems Center (online or at WSC) PoC/Benchmark</span
          >
        </li>
        <li>
          <span style="font-weight: 400"
            >BPIC (if BP has one, online or at BPIC) PoC/Benchmark&nbsp;</span
          >
        </li>
        <li><span style="font-weight: 400">Cyber Resiliency assessment&nbsp; </span></li>
        <li><span style="font-weight: 400">Technology Zone demo&nbsp; </span></li>
        <li><span style="font-weight: 400">Try-N-Buy&nbsp; </span></li>
        <li>
          <span style="font-weight: 400">SIO (Storage Infrastructure Optimization)&nbsp;</span>
        </li>
      </ul>
    </li>
  </ul>
  <p><strong>Submitting a SAN Health Assessment</strong></p>
  <ul>
    <li style="font-weight: 400" aria-level="1">
      <span style="font-weight: 400"
        >Under Pre-Sales Activities will be able to submit SAN details into the form.</span
      >
    </li>
    <li style="font-weight: 400" aria-level="1">
      <span style="font-weight: 400"
        >Complete the mandatory fields and upon confirmation of the assessment funds will be
        auto-loaded to your IBM Rewards Card</span
      >
    </li>
  </ul>
  <p><strong>Seller Incentives&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Sales incentives are only available for individuals who hold a Seller role within their
        firm,
      </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >Qualifying products can be found on the eligible product table found at
        <a
          href="https://www.ibm.com/partnerworld/page/pw_ast_IBMFLASHREWARDS-eligible-product-list"
          target="_blank"
          rel="noopener"
          >https://www.ibm.com/partnerworld/page/pw_ast_IBMFLASHREWARDS-eligible-product-list</a
        ></span
      >
    </li>
    <li>
      Individual sellers can earn 2X the value of the base reward value for selling to a New
      FlashSystem
    </li>
    <li>
      <span style="font-weight: 400"
        >Client or White Space account. The 2X reward value will be honoured on qualifying
        subsequent&nbsp;</span
      ><span style="font-weight: 400"
        >sales for a full 12 months after initial FlashSystem purchase. (E.g. New client purchases
        first </span
      ><span style="font-weight: 400"
        >eligible IBM FlashSystem controller June 1, 2022, they will be able to earn 2X reward value
        on any eligible IBM FlashSystem controller sales through May 31, 2023).</span
      >
    </li>
  </ul>
  <p><strong>What&rsquo;s considered an Existing or a New FlashSystem customer?&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >An &ldquo;Existing FlashSystem Customer&rdquo; is an entity that has installed, or
        upgraded, a FlashSystem&nbsp; array that is less than 36 months old. They might own arrays
        such as; FlashSystem 5x,&nbsp; FlashSystem 7x, FlashSystem V9, FlashSystem A9x, FlashSystem
        8x, FlashSystem 900 or&nbsp; Storwize (3x/5x/7x)</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >A &ldquo;New FlashSystem Customer&rdquo; is defined as an entity that has NOT purchased or
        upgraded an&nbsp; IBM FlashSystem array over the prior 36 months (Between January 1, 2019
        and December 31,&nbsp; 2021). Some customers may have old FlashSystem arrays purchased over
        three years ago, or&nbsp; other IBM Storage systems such as; ESS, DS8K, Tape, COS, DS
        systems. If these customers are&nbsp; interested in acquiring a FlashSystem array, they
        would be considered a &ldquo;New FlashSystem&nbsp; Customer&rdquo;</span
      >
    </li>
  </ul>
  <p><strong>Eligible Business Partners&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Any PartnerWorld member who under the IBM Business Partner Agreement (HW/SW Reseller
        or&nbsp; SIs) who buy from IBM Distributors to fulfill End User client sales orders.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >The Business Partner firm must have an active Know Your IBM (KYI) Attachment to allow
        their&nbsp; individual staff to participate.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >IBM reserves the right to withdraw or terminate this promotion at any time.&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >This offer can be combined with other offers and incentives&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Sales transaction must have been fulfilled via BP Channel via the preferred
        Distributor</span
      >
    </li>
  </ul>
  <p><strong>Exclusions&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >No partners who fall under an ESA contract, or are considered Managed Service
        Providers&nbsp; (MSP&rsquo;s)</span
      >
    </li>
  </ul>
  <p><strong>How do I get started with the IBM Flash Rewards application?&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Download the IBM Flash Rewards application on to your mobile device by searching&nbsp;
        &lsquo;FLASHREWARDS&rsquo; on the &lsquo;App Store&rsquo; if you use iOS, or &lsquo;Google
        Play&rsquo; if you use an Android&nbsp; device.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Follow the prompts within the application to get started.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Be sure to turn your notifications on so we can inform you when new sales transactions
        are&nbsp; available for claiming.</span
      >
    </li>
  </ul>
  <p><strong>How do I register online?&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400">
        Go to the IBM Flash Rewards website at
        <a href="www.ibm.com/partnerworld/ibmflashrewards" target="_blank" rel="noopener"
          >www.ibm.com/partnerworld/ibmflashrewards</a
        ></span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        ><a href="www.ibm.com/partnerworld/ibmflashrewards" target="_blank" rel="noopener"
          >Click on &lsquo;Explore IBM Flash Rewards&rsquo; tab to enter the Incentive
          Marketplace</a
        ></span
      >
    </li>
    <li>
      <span style="font-weight: 400"> </span
      ><strong
        >Manage my Profile
        <span style="font-weight: 400"
          >and ensure you have met all eligibility requirements, then return to the main&nbsp;
          page</span
        ></strong
      >
    </li>
    <li>
      <span style="font-weight: 400"> Choose the </span><strong>IBM Flash Rewards&nbsp;</strong>
    </li>
    <li>
      <span style="font-weight: 400">
        Read and accept the IBM Flash Rewards terms and conditions.&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400">
        Individuals will be notified via email when their eligible transactions are available for
        claiming.
      </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >No cash equivalent is available under this Sales Incentive&nbsp;</span
      >
    </li>
  </ul>
  <p><strong>How do I claim a sale?&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"> </span
      ><strong
        >NOTE:
        <span style="font-weight: 400"
          >The ability to claim a sale is reserved for Business Partner <br />Sellers only.
        </span></strong
      >
    </li>
    <li>
      <span style="font-weight: 400">
        If you are using the IBM Flash Rewards app or the online version go to &lsquo;Claim a
        Sale&rsquo; and identify&nbsp; the transaction(s) you worked on. Once the sale is identified
        click the &lsquo;Claim&rsquo; button and the benefit&nbsp; will flow to your IBM Rewards
        Card in time.&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400">
        There is a 10 day cooling period once you&rsquo;ve made your claim to allow you to correct
        any claims&nbsp; that were done in error. After the 10 day cooling period lapses your
        benefit value will flow to your&nbsp; IBM Rewards Card.&nbsp;</span
      >
    </li>
  </ul>
  <p><strong>Participant Eligibility&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >Your company must accept the KYI Attachment which grants IBM permission to reward you
        directly&nbsp;
      </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >You must belong to a firm that holds an active IBM Business Partner Agreement</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Only full-time regular Business Partner employees who qualify as eligible Seller or
        Technical Seller&nbsp; personnel may participate in IBM Flash Rewards. No other Business
        Partner employees may&nbsp; participate, including contractors, administrative, management,
        and part-time sales or technical&nbsp; personnel</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >All firms participating must be certified as completing the IBM Code of Conduct&nbsp;
      </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >You must have an IBM ID associated with your company&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Participants must use their company email address for their id&rsquo;s</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >It is the participant&rsquo;s responsibility to ensure they secure approval from the
        principal officer, senior&nbsp; manager, or executive of your Business Partner Company
        (Business) with the authority to make&nbsp; personnel decisions on behalf of your
        company</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >KYI Attachment contract documents may be electronically accepted, or for certain
        countries&nbsp; designated by IBM, a hard copy signature may be required by the APA
        (Authorised Profile&nbsp; Administrator) or PRC (Primary Relationship Contact) within the
        PartnerWorld program, to grant&nbsp; permission for the qualifying employees of your company
        to earn and redeem points through the&nbsp; KYI offering</span
      >
    </li>
  </ul>
  <p><strong>Government Entities&nbsp;&nbsp;</strong></p>
  <p><strong>Government Entities Business Partners:&nbsp;&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >All Business Partners who are Government Entities (GE), a part of a GE, or controlled by a
      GE, as defined&nbsp; in the KYI Attachment, are prohibited from participating in the KYI
      offering. IBM reserves the right to reverse&nbsp; points awarded to Business Partners who are
      Government Entities. For a definition of what IBM includes&nbsp; as a GE please
      <a
        href="https://www.ibm.com/partnerworld/wps/servlet/RedirectServlet?attachmentName=Government-Entity-Determination-Appeal-Form.xls&amp;cmsId=pw_ast_res-government-owned-entity-definition"
        target="_blank"
        rel="noopener"
        >click here</a
      ></span
    >
  </p>
  <p><strong>Sales to Government Entities:&nbsp;&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >Sales to Government Entities, a part of a GE, or controlled by a GE, as defined in the KYI
      Attachment do&nbsp; not qualify for this Sales Incentive. IBM will not import these invoices
      onto the offering website. It is the&nbsp; responsibility of the participant to not claim any
      such invoices that were imported to the offering website in&nbsp; error. IBM reserves the
      right to reverse benefit awarded for any such invoice. For a definition of what IBM&nbsp;
      includes as a GE please
      <a
        href="https://www.ibm.com/partnerworld/wps/servlet/RedirectServlet?attachmentName=Government-Entity-Determination-Appeal-Form.xls&amp;cmsId=pw_ast_res-government-owned-entity-definition"
        target="_blank"
        rel="noopener"
        >click here</a
      ></span
    >
  </p>
  <p><strong>Integrity Training&nbsp;&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400"
        >To earn a benefit through this offering, participants must have completed
        <a
          href="https://www.ibm.com/partnerworld/program/compliance/integrity-education"
          target="_blank"
          rel="noopener"
          >IBM Integrity Training</a
        >
        within the last two years</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Integrity Training is free of charge and approximately 30 minutes in length</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >A user cannot redeem any earned KYI points or participate in any Sales Incentive until this
        training&nbsp; is fulfilled/validated&nbsp;</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >To request access to the IBM Integrity Training participants can email
        <a href="mailto:service@kyirewards.com">service@kyirewards.com</a> <br /><strong
          >NOTE: Integrity training is mandatory for ALL participating users.&nbsp;</strong
        >
      </span>
    </li>
  </ul>
  <p><strong>Termination&nbsp;&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >IBM may terminate the involvement of any registered participant in the IBM Flash Reward
      incentive immediately in case of 1) a breach of the KYI Attachment and these Terms or 2) when
      IBM reasonably&nbsp; believes such a breach has occurred or is likely to occur</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >Failure of a registered participant(s) to comply with these IBM Flash Reward incentive terms
      and conditions&nbsp; may result in:&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >The termination of a registered participant&rsquo;s involvement in the IBM Flash Reward
      incentive and/or&nbsp; The cancellation of all or part of any accrued reward funds earned by
      the registered participant&nbsp; Participation in the offering is automatically terminated
      upon:&nbsp;</span
    >
  </p>
  <ul>
    <li>
      <span style="font-weight: 400">The death of the registered participant&nbsp;&nbsp; </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >Termination of the registered participant&rsquo;s employment with an IBM Business
        Partner&nbsp;
      </span>
    </li>
    <li>
      <span style="font-weight: 400"
        >Termination of the Business Partner Agreement or PartnerWorld Agreement with IBM</span
      >
    </li>
  </ul>
  <p>
    <span style="font-weight: 400"
      >Each participant must comply with the highest ethical principles in performing and activities
      related to the&nbsp; IBM Flash Rewards incentive. A participant must not offer or make
      payments or gifts (monetary or&nbsp; otherwise) to anyone for the purpose of wrongfully
      influencing decisions in favour of IBM, directly or&nbsp; indirectly. IBM may terminate a
      participant&rsquo;s participation under the offering immediately in case of 1) a&nbsp; breach
      of this clause or 2) when IBM reasonably believes such a breach has occurred or is likely to
      occur</span
    >
  </p>
  <ul>
    <li>
      <span style="font-weight: 400">
        A registered participant may terminate their participation in the IBM Flash Rewards
        incentive by&nbsp; providing notice in writing to
        <a href="mailto:flash@kyirewards.com">flash@kyirewards.com</a></span
      >
    </li>
  </ul>
  <p><strong>Privacy&nbsp;</strong></p>
  <ul>
    <li>
      <span style="font-weight: 400">
        IBM will provide to its contractors and agents for the purpose of administering the
        offering, certain&nbsp; personal information about participants and their entries
        appropriate for the calculation of awards and&nbsp; prizes. Any information relating to the
        IBM Flash Rewards incentive subsequently disclosed by a&nbsp; participant to IBM or its
        contractors and agents may be sought, collected, used or disclosed in&nbsp; accordance with
        IBM&rsquo;s Privacy Policy available at
        <a href="https://www.ibm.com/privacy" target="_blank" rel="noopener"
          >https://www.ibm.com/privacy</a
        >, for one or more of the&nbsp; following purposes:</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >Administering the IBM Flash Rewards incentive and the provision or performance of
        services&nbsp; relating to the offering; and Planning, research and the promotion of goods
        or services of IBM&nbsp; associated with the offering.</span
      >
    </li>
    <li>
      <span style="font-weight: 400"
        >The agency managing the offering and handling personal information on behalf of IBM
        is&nbsp; MotivForce Marketing &amp; Incentives (MMI)</span
      >
    </li>
    <li><span style="font-weight: 400"> All data is retained for a period of three years</span></li>
    <li>
      <span style="font-weight: 400">
        Each participant authorizes IBM and any of its contractors or agents to seek access to,
        collect and use&nbsp; that information or disclose that information between themselves for
        the above purposes</span
      >
    </li>
  </ul>
  <p><strong>Tax Liability&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >Participants are solely responsible for their own social security contributions and taxes.
      IBM accepts no&nbsp; liability for any consequential tax or social security resulting from the
      receipt of compensation under this&nbsp; program&nbsp;</span
    >
  </p>
  <p>
    <span style="font-weight: 400"
      >For individual country tax requirements see <strong>below&nbsp;</strong>
    </span>
  </p>
  <p><strong>Canada</strong><strong>&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >The IBM Flash Rewards incentive Coordinator will provide each individual participant who has
      earned more than CDN $500 in reward funds within the 2022 calendar year with a T4A form for
      tax purposes.&nbsp; You will be required to share your Social Insurance Number for filing
      purposes on the IBM Flash Rewards&nbsp; app.</span
    >
  </p>
  <p><strong>USA</strong><strong>&nbsp;</strong></p>
  <p>
    <span style="font-weight: 400"
      >The IBM Flash Rewards incentive Coordinator will provide each sales rep who has earned more
      than&nbsp; USD $600 in reward funds within the 2022 calendar year with a 1099-Misc form for
      tax purposes. You will&nbsp; be required to share your Social Security Number for filing
      purposes on the IBM Flash Rewards app.</span
    >
  </p>
</div>
<div class="modal-footer d-flex justify-content-end">
  <div class="d-flex justify-content-end">
    <button class="btn" type="button" (click)="activeModal.close()">
      {{ 'Close' | translate }}
    </button>
  </div>
</div>
