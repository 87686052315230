<app-default-layout>
  <div class="container content content-bottom-border">
    <div class="row">
      <div class="col-lg-3 col-md-12 side-content">
        <p>
          <strong>{{ 'Previously claimed invoices' | translate }}</strong>
        </p>
        <div class="mt-30">
          <a class="btn btn-primary" type="button" [routerLink]="'/claim-a-sale'">
            {{ 'Back to eligible invoices' | translate }}
            <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
          </a>
        </div>
      </div>

      <div class="col-md-9">
        <div class="mb-20 mt-20" [innerHtml]="content"></div>
        <previously-claimed-sales (viewDetails)="viewDetails($event)"></previously-claimed-sales>
      </div>
    </div>
  </div>
</app-default-layout>
