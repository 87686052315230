<a [routerLink]="[article.url]" class="link-box">
  <div class="article-box">
    <div class="img-box">
      <img alt="" [src]="article.imageUrl" />
    </div>
    <div class="article-heading">{{ article.title }}</div>
    <div class="article-read-more">
      <i class="fas fa-arrow-right"></i> {{ 'Learn More' | translate }}
    </div>
  </div>
</a>
