<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content">
        <p><strong>Avaliable balance</strong></p>
        <ng-container *ngIf="availableBalance">
          <div class="mt-2 balance">
            <span class="me-1 symbol"
              ><strong>{{ currencySymbol }}</strong></span
            ><strong>{{ availableBalance }}</strong>
          </div>
        </ng-container>
      </div>
      <div class="d-none d-lg-block col-lg-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12">
        <div [innerHtml]="topContent"></div>
        <app-individual-search [accountSearchResult]="accountSearchResult"></app-individual-search>
      </div>
    </div>
  </div>
</app-default-layout>
