import { DynamicLayoutComponent } from './dynamic-layout/dynamic-layout.component';
import { MainLayoutComponent } from './templates/main-layout/main-layout.component';
import { NewUserGuard } from '../user/new-user.guard';
import { SaleDetailsPageComponent } from '../sales-claim/sale-details-page/sale-details-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

export const LayoutRoutes = [
  {
    path: 'landing',
    component: LandingPageComponent,
    canActivate: [NewUserGuard],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'sale-details/:id',
        component: SaleDetailsPageComponent,
        canActivate: [NewUserGuard],
      },
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: DynamicLayoutComponent,
        canActivate: [NewUserGuard],
      },
    ],
  },
];
