import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.scss'],
})
export class CarouselSliderComponent {
  @Input() banners: any[];
  @Input() showNavigationArrows = false;
}
