import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() title = '';
  @Input() message;

  singleMessage = true;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.singleMessage = !Array.isArray(this.message);
  }

  public close(response: any): void {
    this.activeModal.close(response);
  }

  public dismiss(reason: string): void {
    this.activeModal.dismiss(reason);
  }
}
