import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserKyiSaleGroup } from 'src/app/core/model/user/user-kyi-sale-group';

@Component({
  selector: 'app-sale-details-page',
  templateUrl: './sale-details-page.component.html',
  styleUrls: ['./sale-details-page.component.scss'],
})
export class SaleDetailsPageComponent implements OnInit {
  saleDetails: UserKyiSaleGroup;
  saleId: number;

  constructor(private activatedRoute: ActivatedRoute, private location: Location) {}

  ngOnInit(): void {
    this.saleId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  goBack(): void {
    this.location.back();
  }
}
