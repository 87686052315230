import {
  Component,
  OnInit,
  forwardRef,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
} from '@angular/core';
import { ContentService } from 'src/app/content/content.service';
import { LayoutHostDirective } from '../layout-host.directive';
import mapper from '../../shared/component-mapper';
import { Content } from '../../models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Settings } from 'src/app/entities/settings';
import { Route, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LayoutService } from '../layout.service';
import { AdminService } from 'src/app/admin-panel/admin.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-layout',
  templateUrl: './dynamic-layout.component.html',
  styleUrls: ['./dynamic-layout.component.scss'],
})
export class DynamicLayoutComponent implements OnInit, OnDestroy {
  componentMap = mapper;
  @ViewChild(LayoutHostDirective, { static: true }) layoutHost: LayoutHostDirective;

  content: Content;
  settings: Settings;
  componentRef: any;
  loading = true;
  adminFilter: any;
  routerEventsSubscription: Subscription;

  constructor(
    private contentService: ContentService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private layoutService: LayoutService,
    private router: Router,
    settingsProvider: SettingsProvider,
    private adminService: AdminService,
  ) {
    this.settings = settingsProvider.settings;
  }

  ngOnInit() {
    this.routerEventsSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.loading = true;
        console.log(window.location.href);
        this.resetContent();
      }
    });
    this.getContent();

    this.adminService.filterChange.subscribe((r) => {
      if (r && r !== this.adminFilter) {
        console.log('REFRESHING', r, this.adminFilter);
        this.loading = true;
        this.adminFilter = r;
        console.log(this.adminFilter);
        this.resetContent();
      }
    });
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  resetContent() {
    this.layoutService.changeBanner(null);
    const viewContainerRef = this.layoutHost.viewContainerRef;

    viewContainerRef.clear(); // clear the component reference
    this.componentRef = null; // remove the component from memory;

    this.getContent();
  }

  getContent() {
    console.log(window.location.href);
    let language = 'en-US';
    if (this.settings && this.settings.data) {
      language = this.settings.data.language;
    }

    if (this.adminService.language) {
      console.log('Override with admin language', this.adminService.language);
      language = this.adminService.language;
    }

    // TODO: should we always get all children?
    this.contentService
      .getContentByUrlPath(window.location.href, language, false)
      .subscribe((response) => {
        this.content = response.data.contentByUrlPath;
        console.log('calling load component');
        this.loadComponent();
      });
  }

  loadComponent(): void {
    console.log('loadCompoent');
    console.log(this.content);
    const component = this.content.renderComponent || 'default';
    console.log(component);
    console.log(this.componentMap);
    console.log(this.componentMap[component]);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.componentMap[component],
    );

    const viewContainerRef = this.layoutHost.viewContainerRef;
    // viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (this.componentRef.instance as DynamicLayout).baseContent = this.content;
    this.loading = false;
  }
}
