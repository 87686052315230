import { Injectable } from '@angular/core';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseCoreService } from 'src/app/shared/base-core-service';
import { UserKyiAccount } from '../../model/user/user-kyi-account';
import { UserKyiAccountDetail } from '../../model/user/user-kyi-account-detail';

@Injectable({
  providedIn: 'root',
})
export class BankingRestService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('users', apiRestService);
  }

  getApplicationStatus(): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/application/status`;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  getApplicationOptions(): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/application/options`;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  submitApplication(form: any): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/application`;
    return this.apiRestService.post<any>(endpointUrl, form).toPromise();
  }

  getPin(accountId: number): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/accounts/forgot-pin/` + accountId;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  getBankUser(): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/users`;
    return this.apiRestService.get<any>(endpointUrl).toPromise();
  }

  submitIdentification(identification: any): any {
    const endpointUrl = `${this.bankingBaseUrl}/users/identification/`;
    return this.apiRestService.post<any>(endpointUrl, identification);
  }

  submitDocumentation(documentUpload: FormData, typeId: number): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/users/document/` + typeId;
    return this.apiRestService.post<any>(endpointUrl, documentUpload).toPromise();
  }

  activateAccount(accountActivate: any, accountId: number): Promise<any> {
    const endpointUrl = `${this.bankingBaseUrl}/accounts/activate/` + accountId;
    return this.apiRestService.post<any>(endpointUrl, accountActivate).toPromise();
  }

  getCountries(): any {
    const endpointUrl = `${this.bankingBaseUrl}/references/countries`;
    return this.apiRestService.get<any>(endpointUrl);
  }

  getIdentificationOptions(): any {
    const endpointUrl = `${this.bankingBaseUrl}/references/identification-type`;
    return this.apiRestService.get<any>(endpointUrl);
  }

  getDocumentOptions(): any {
    const endpointUrl = `${this.bankingBaseUrl}/references/document-type`;
    return this.apiRestService.get<any>(endpointUrl);
  }

  reportMissing(missingDetail: any, accountId: number): any {
    const endpointUrl = `${this.bankingBaseUrl}/accounts/report-lost-stolen/` + accountId;
    return this.apiRestService.post<any>(endpointUrl, missingDetail);
  }

  getMissingTypes(): any {
    const endpointUrl = `${this.bankingBaseUrl}/references/missing-types`;
    return this.apiRestService.get<any>(endpointUrl);
  }

  getUserAccounts(showSensitiveData: boolean = true): Observable<UserKyiAccount[]> {
    const endpointUrl = `${this.bankingBaseUrl}/accounts/${showSensitiveData}`;
    return this.apiRestService.get<UserKyiAccount[]>(endpointUrl);
  }

  getUserAccountDetail(
    accountId: number,
    showSensitiveData: boolean = false,
  ): Observable<UserKyiAccountDetail> {
    const endpointUrl = `${this.bankingBaseUrl}/accounts/${accountId}/${showSensitiveData}`;
    return this.apiRestService.get<any>(endpointUrl).pipe(map((data) => data.info));
  }

  searchTransactions(transactionSearch: any): any {
    const endpointUrl = `${this.bankingBaseUrl}/transactions/search`;
    return this.apiRestService.post<any>(endpointUrl, transactionSearch);
  }

  requestReplacement(requestDetail: any, accountId: number): any {
    const endpointUrl = `${this.bankingBaseUrl}/accounts/report-expired/` + accountId;
    return this.apiRestService.post<any>(endpointUrl, requestDetail);
  }

  getActiveBalance(): Observable<any> {
    return this.apiRestService.get(`${this.bankingBaseUrl}/accounts/active-reload/balance`);
  }
}
