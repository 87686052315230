import { Component, OnInit, Input } from '@angular/core';
import { Panel } from './panel.model';

@Component({
  selector: 'ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() panels: Panel[];

  constructor() { }

  ngOnInit() {
  }

}
