<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12 side-content" [innerHtml]="topSectionSideTitle"></div>
      <div class="d-none d-lg-block col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9">
        <div [innerHtml]="topSectionMainContent"></div>
      </div>
    </div>
  </div>
  <div class="container content">
    <div class="row">
      <div class="col-lg-3 col-md-12 side-content">
        <p [innerHtml]="transactionSectionSideTitle"></p>

        <div *ngIf="userAccountDetail">
          <p>
            <strong>{{ 'Account information' | translate | uppercase }}</strong>
          </p>
          <ng-container *ngIf="expired && (hasPoints || hasBalance)">
            <br />
            <button
              type="button"
              class="btn btn-primary mt-auto"
              [routerLink]="'/request-replacement'"
            >
              {{ 'My card expired' | translate }}
            </button>
            <br />
            <span>{{ 'Mastercard My Card Expired Explanation' | translate }}</span>
            <br />
          </ng-container>

          <br />
          <span
            ><label>{{ 'Account Id' | translate }}</label> {{ userAccountDetail.id }}</span
          >
          <br />
          <span
            ><label>{{ 'Card Number' | translate }}</label> {{ userAccountDetail.number }}</span
          >
          <br />
          <span
            ><label>{{ 'Account Name' | translate }}</label> {{ userAccountDetail.name }}</span
          >
          <br />
          <span
            ><label>{{ 'Account Status' | translate }}</label> {{ userAccountDetail.status }}</span
          >
          <br />
          <span
            ><label>{{ 'Currency' | translate }}</label> {{ userAccountDetail.currency }}</span
          >
          <br />
          <span
            ><label>{{ 'Available Balance' | translate }}</label>
            {{ userAccountDetail.availableBalance }}</span
          >
          <br />

          <ng-container *ngIf="expiring">
            <br />
            <br />
            <span
              >{{ 'Your Mastercard will be expiring on' | translate }}
              {{
                expiryDate
                  | date
                    : (settingsStore.settings?.user?.program?.dateFormat
                        ? settingsStore.settings?.user?.program?.dateFormat
                        : 'dd-MM-yyyy')
              }}
              {{
                "Once the card expires, you will see a blue button 'My card expired' above. Click on that button and follow the instructions to request a replacement card."
                  | translate
              }}</span
            >
          </ng-container>
        </div>

        <button class="btn btn-primary btn-side" [routerLink]="'/upload-documents'">
          Re-upload documents
          <span class="arrow"><img src="../../../../assets/images/arrow-right-white.svg" /></span>
        </button>
        <br />
        <button class="btn btn-inverse btn-side" [routerLink]="'/report-missing'">
          Report missing card
          <span class="arrow"><img src="../../../../assets/images/arrow-right-blue.svg" /></span>
        </button>
        <br />
        <button class="btn btn-inverse btn-side" [routerLink]="'/view-pin'">
          View PIN
          <span class="arrow"><img src="../../../../assets/images/arrow-right-blue.svg" /></span>
        </button>
      </div>

      <div class="col-lg-9 col-md-12">
        <div class="form-box">
          <div class="row pb-20">
            <div class="row">
              <div class="col-lg-4 col-md-12">
                <label class="w-100">{{ 'Choose card' | translate }}</label>
                <select [(ngModel)]="selectedAccountId" class="form-control">
                  <option [value]="null" disabled hidden>{{ 'Select' | translate }}</option>
                  <option [value]="card.id" *ngFor="let card of usedAccounts">
                    {{ card.accountType | translate }} - {{ card.cardNumber }} -
                    {{ card.statusName | translate }}
                  </option>
                </select>
              </div>
              <div class="col-lg-3 col-md-12 pt-25">
                <button
                  type="button"
                  class="btn btn-primary mt-auto btn-card"
                  (click)="selectAccount()"
                >
                  Select card<span class="arrow"
                    ><img src="../../../../assets/images/arrow-right-white.svg"
                  /></span>
                </button>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="userAccountDetail">
            <form [formGroup]="transactionSearchForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-3">
                  <label class="w-100">{{ 'From' | translate }}</label>
                  <input type="date" formControlName="fromDate" class="form-control" />
                </div>

                <div class="col-md-3">
                  <label class="w-100">{{ 'To' | translate }}</label>
                  <input type="date" formControlName="toDate" class="form-control" />
                </div>

                <div class="col-md-3">
                  <label>{{ 'Transaction Type' | translate }}</label>
                  <select formControlName="status" class="form-control">
                    <option disabled hidden>{{ 'Select' | translate }}</option>
                    <option value="0">{{ 'Completed transactions' | translate }}</option>
                    <option value="1">{{ 'Pending transactions' | translate }}</option>
                  </select>
                </div>

                <div class="col-md-3 pt-25 d-flex">
                  <button type="submit" class="btn btn-primary mt-auto btn-card">
                    {{ 'Search' | translate }}
                  </button>
                </div>
              </div>
            </form>
            <br />
            <div class="col-md-12 mt-20">
              <div class="row">
                <div class="d-none d-md-block">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ 'Posting Date' | translate }}</th>
                        <th>{{ 'Description' | translate }}</th>
                        <th>{{ 'Debit' | translate }} [-]</th>
                        <th>{{ 'Credit' | translate }} [+]</th>
                        <th>{{ 'Posting Balance' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="transactions && transactions.length > 0">
                      <ng-container *ngFor="let transaction of transactions">
                        <tr>
                          <td>
                            {{
                              transaction.info.server_date
                                | date
                                  : (settingsStore.settings?.user?.program?.dateFormat
                                      ? settingsStore.settings?.user?.program?.dateFormat
                                      : 'dd-MM-yyyy')
                            }}
                          </td>
                          <td>{{ transaction.info.notes }}</td>

                          <td>
                            <span *ngIf="transaction.info.operation == 'Debit'">{{
                              transaction.info.amount
                            }}</span>
                          </td>

                          <td>
                            <span *ngIf="transaction.info.operation == 'Credit'">{{
                              transaction.info.amount
                            }}</span>
                          </td>

                          <td>{{ transaction.info.balance }}</td>
                        </tr>
                        <tr *ngIf="transaction.fee && transaction.fee.id">
                          <td>
                            {{
                              transaction.info.server_date
                                | date
                                  : (settingsStore.settings?.user?.program?.dateFormat
                                      ? settingsStore.settings?.user?.program?.dateFormat
                                      : 'dd-MM-yyyy')
                            }}
                          </td>
                          <td>{{ transaction.fee.notes }}</td>

                          <td>
                            <span *ngIf="transaction.fee.operation == 'Debit'">{{
                              transaction.fee.amount
                            }}</span>
                          </td>

                          <td>
                            <span *ngIf="transaction.fee.operation == 'Credit'">{{
                              transaction.fee.amount
                            }}</span>
                          </td>

                          <td>{{ transaction.fee.balance }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody *ngIf="!transactions || transactions.length === 0">
                      <tr>
                        <td colspan="5">
                          {{ 'No matching transactions' | translate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="d-block d-sm-none">
                  <div *ngIf="transactions && transactions.length > 0">
                    <div *ngFor="let transaction of transactions">
                      <div class="row">
                        <div class="col-sm-6">
                          <p>
                            <strong>{{ 'Posting Date' | translate }}</strong>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p>
                            {{
                              transaction.info.server_date
                                | date
                                  : (settingsStore.settings?.user?.program?.dateFormat
                                      ? settingsStore.settings?.user?.program?.dateFormat
                                      : 'dd-MM-yyyy')
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <p>
                            <strong>{{ 'Description' | translate }}</strong>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p>{{ transaction.info.notes }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <p>
                            <strong>{{ 'Debit' | translate }} [-]</strong>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p>
                            <span *ngIf="transaction.info.operation == 'Debit'">{{
                              transaction.info.amount
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <p>
                            <strong>{{ 'Credit' | translate }} [+]</strong>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p>
                            <span *ngIf="transaction.info.operation == 'Credit'">{{
                              transaction.info.amount
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <p>
                            <strong>{{ 'Posting Balance' | translate }}</strong>
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p>{{ transaction.info.balance }}</p>
                        </div>
                      </div>
                      <div *ngIf="transaction.fee && transaction.fee.id">
                        <div class="row">
                          <div class="col-sm-6">
                            <p>
                              <strong>{{ 'Posting Date' | translate }}</strong>
                            </p>
                          </div>
                          <div class="col-sm-6">
                            <p>
                              {{
                                transaction.info.server_date
                                  | date
                                    : (settingsStore.settings?.user?.program?.dateFormat
                                        ? settingsStore.settings?.user?.program?.dateFormat
                                        : 'dd-MM-yyyy')
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <p>
                              <strong>{{ 'Description' | translate }}</strong>
                            </p>
                          </div>
                          <div class="col-sm-6">
                            <p>{{ transaction.fee.notes }}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <p>
                              <strong>{{ 'Debit' | translate }} [-]</strong>
                            </p>
                          </div>
                          <div class="col-sm-6">
                            <p>
                              <span *ngIf="transaction.fee.operation == 'Debit'">{{
                                transaction.fee.amount
                              }}</span>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <p>
                              <strong>{{ 'Credit' | translate }} [+]</strong>
                            </p>
                          </div>
                          <div class="col-sm-6">
                            <p>
                              <span *ngIf="transaction.fee.operation == 'Credit'">{{
                                transaction.fee.amount
                              }}</span>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <p>
                              <strong>{{ 'Posting Balance' | translate }}</strong>
                            </p>
                          </div>
                          <div class="col-sm-6">
                            <p>{{ transaction.fee.balance }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!transactions || transactions.length === 0">
                    <p>
                      <strong>
                        {{ 'No matching transactions' | translate }}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-20 mt-20" [innerHtml]="transactionsSectionMainContent"></div>
      </div>
    </div>
  </div>
</app-default-layout>
