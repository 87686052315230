import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';

@Component({
  selector: 'app-previously-submitted-activities-page',
  templateUrl: './previously-submitted-activities-page.component.html',
  styleUrls: ['./previously-submitted-activities-page.component.scss'],
})
export class PreviouslySubmittedActivitiesPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  content: string;

  showTechnicalAssessments: boolean = false;

  constructor(private layoutService: LayoutService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.showTechnicalAssessments = (this.route.snapshot.queryParamMap.get('showTechnicalAssessments') === '1');

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');

    const contentField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.content = contentField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }
}
