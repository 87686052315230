import { Component, OnInit, SystemJsNgModuleLoaderConfig } from '@angular/core';
import { LayoutService } from '../layout/layout.service';
import { SettingsProvider } from '../shared/settings.provider';
import { AdminService } from './admin.service';
import { Apollo } from 'apollo-angular';
import { Settings } from '../entities/settings';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from '../content/content.service';
import * as ls from 'local-storage';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {
  expanded = false;
  selectedLanguage = 'en-US';

  languages: any[];
  settings: Settings;
  showAdminPanel = false;

  fields: any[] = [
    {
      name: 'region',
      label: 'Region',
      value: 'NA',
      isList: true,
      data: [],
      onValueChange: (event) => this.regionValueChange(event.target.value),
      loading: false,
    },
    {
      name: 'country',
      label: 'Country',
      value: 'US',
      isList: true,
      data: [],
      onValueChange: (value) => {},
      loading: false,
    },
    {
      name: 'role',
      label: 'User Group',
      value: '',
      isList: true,
      data: [],
      onValueChange: (value) => {},
      loading: false,
    },
    // {
    //   name: 'is_company_reward',
    //   label: 'Firm Level',
    //   value: 'no',
    //   isList: false,
    //   data: [],
    //   onValueChange: (value) => {},
    //   loading: false,
    // },
    // {
    //   name: 'program_year',
    //   label: 'Program Year',
    //   value: '2020',
    //   isList: false,
    //   data: [],
    //   onValueChange: (value) => {},
    //   loading: false,
    // },
    // {
    //   name: 'countryiso',
    //   label: 'countryiso',
    //   value: 'us',
    //   isList: false,
    //   data: [],
    //   onValueChange: (value) => {},
    //   loading: false,
    // },
  ];

  countries: any[] = [];
  regions: any[] = [];
  roles: any[] = [];

  constructor(
    private layoutService: LayoutService,
    private adminService: AdminService,
    private apollo: Apollo,
    private settingsProvider: SettingsProvider,
    private translateService: TranslateService,
    private contentService: ContentService,
  ) {}

  async ngOnInit() {
    this.adminService.settings.subscribe(async (value) => {
      console.log('ADMIN SERVICE Settings updated', value);
      if (value) {
        this.settings = value;
        await this.validateSettings();
      }
    });
  }

  async init() {
    const regionField = this.fields.find((f) => f.name === 'region');
    const countryField = this.fields.find((f) => f.name === 'country');
    const roleField = this.fields.find((f) => f.name === 'role');

    if (!regionField || !countryField || !roleField) {
      // the country and region field should be here or something really weird is going on
      throw Error('Field configuration error');
    }

    regionField.loading = true;
    countryField.loading = true;
    roleField.loading = true;
    const [regions, countries, roles] = await Promise.all([
      this.adminService.getRegions(),
      this.adminService.getCountriesByRegionCode(regionField.value),
      this.adminService.getUserRoles(),
    ]);

    this.countries = countries;
    this.regions = regions;
    this.roles = roles;

    if (countryField) {
      countryField.data = this.countries.map((c) => {
        return {
          id: c.alpha2Code,
          text: c.name,
        };
      });
    }

    if (regionField) {
      regionField.data = this.regions.map((r) => {
        return {
          id: r.regionCode,
          text: r.name,
        };
      });
    }

    if (roleField) {
      roleField.data = this.roles.map((r) => {
        return {
          id: r.name,
          text: r.friendlyName,
        };
      });

      if (roleField.data.length > 0) {
        roleField.value = roleField.data[0].id;
      }
    }

    regionField.loading = false;
    countryField.loading = false;
    roleField.loading = false;
  }

  async regionValueChange(value) {
    const countryField = this.fields.find((f) => f.name === 'country');
    if (!countryField) {
      // the country field should be here or something really weird is going on
      throw Error('Country field is not defined');
    }
    countryField.loading = true;

    const countries = await this.adminService.getCountriesByRegionCode(value);
    this.countries = countries;

    if (countryField) {
      countryField.data = this.countries.map((c) => {
        return {
          id: c.alpha2Code,
          text: c.name,
        };
      });

      if (this.countries.length > 0) {
        countryField.value = this.countries[0].alpha2Code;
      }
    }

    countryField.loading = false;
  }

  async validateSettings() {
    const impersonate = ls.get<number>('m9-impersonate-user');
    if (this.settings.data) {
      if (this.settings.data.claims) {
        const hasClaim = this.settings.data.claims.find((c) => c === 'cms.content.write');
        if (hasClaim && !impersonate) {
          this.showAdminPanel = true;
          await this.init();
          this.selectedLanguage = this.settings.language;
          return;
        }
      }
    }

    this.showAdminPanel = false;
  }

  expand() {
    this.expanded = true;
  }

  close() {
    this.expanded = false;
  }

  async apply() {
    console.log('Admin Panel Apply');
    const adminFilters = {};

    this.fields.forEach((f) => {
      adminFilters[f.name] = f.value;
    });

    this.adminService.adminFilters = adminFilters;
    this.adminService.language = this.selectedLanguage;

    await this.apollo.getClient().resetStore();
    this.translateService.use(this.selectedLanguage);
    this.translateService.reloadLang(this.selectedLanguage);
    this.contentService.initMenu(this.selectedLanguage);
    console.log('CALLING REFRESH FROM APPLY');
    this.adminService.filterChange.next({
      ...adminFilters,
      ...{ language: this.selectedLanguage },
    });
  }
}
