import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { UserKyiSaleGroup } from 'src/app/core/model/user/user-kyi-sale-group';
import { LayoutService } from 'src/app/layout/layout.service';
import { Content } from 'src/app/models/content.model';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SupportService } from 'src/app/support/support.service';

@Component({
  selector: 'app-sale-claim-page',
  templateUrl: './sale-claim-page.component.html',
  styleUrls: ['./sale-claim-page.component.scss'],
})
export class SaleClaimPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  sectionOneMainContent: string;
  sectionOneSideContent: string;
  sectionTwoMainContent: string;
  sectionTwoSideContent: string;
  bottomLeftBoxContent: string;
  bottomMiddleBoxContent: string;
  bottomRightBoxContent: string;
  charityDonationDialogContent: string;

  saleDetails: UserKyiSaleGroup;

  contactUsForm = this.formBuilder.group({
    companyName: [null, Validators.required],
    country: [null, Validators.required],
  });

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private dialog: DialogNgbService,
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Banner Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');

    const sectionOneMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section One Main Content',
    );
    const sectionOneSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section One Side Content',
    );
    const sectionTwoMainContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Main Content',
    );
    const sectionTwoSideContentField = this.baseContent.fields.find(
      (f) => f.name === 'Section Two Side Content',
    );
    const bottomLeftBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Left Box Content',
    );
    const bottomMiddleBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Middle Box Content',
    );
    const bottomRightBoxContent = this.baseContent.fields.find(
      (f) => f.name === 'Bottom Right Box Content',
    );
    const charityDonationDialogContentField = this.baseContent.fields.find(
      (f) => f.name === 'Charity Donation Popup',
    );

    this.sectionOneMainContent = sectionOneMainContentField.value.value;
    this.sectionOneSideContent = sectionOneSideContentField.value.value;
    this.sectionTwoMainContent = sectionTwoMainContentField.value.value;
    this.sectionTwoSideContent = sectionTwoSideContentField.value.value;
    this.bottomLeftBoxContent = bottomLeftBoxContent.value.value;
    this.bottomMiddleBoxContent = bottomMiddleBoxContent.value.value;
    this.bottomRightBoxContent = bottomRightBoxContent.value.value;
    this.charityDonationDialogContent = charityDonationDialogContentField.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }

  viewDetails(sale: UserKyiSaleGroup): void {
    this.router.navigate(['/sale-details/' + sale.id]);
  }

  openMissingInvoice() {
    this.dialog.openFeatureDialog('missingInvoiceDialog', [], { size: 'lg' });
  }

  onSubmit() {
    if (this.contactUsForm.valid) {
      this.supportService
        .submitHelpRequest(this.contactUsForm.value, 'flash/customer-check')
        .subscribe((data) => {
          this.dialogService.openModal('Your Contact Form has been submitted.');
          this.contactUsForm.reset();
        });
    } else {
      const controlkeys = Object.keys(this.contactUsForm.controls);
      const errorFieldMessages = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controlkeys.length; i++) {
        if (this.contactUsForm.controls[controlkeys[i]].status === 'INVALID') {
          let label = controlkeys[i].replace(/([A-Z])/g, ' $1');
          label = label.charAt(0).toUpperCase() + label.slice(1);

          errorFieldMessages.push(`${label} is required.`);
        }
      }

      this.dialogService.openModal(errorFieldMessages, '');
    }
  }
}
