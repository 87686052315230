import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CurrencyPipe, registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { SupportModule } from './support/support.module';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// TODO: remove the in memory data (JUST FOR DEMO);
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { CoreTokenInterceptor } from './authentication/core-token.interceptor';
import { InMemoryDataService } from './in-memory-data.service';
import { HttpClientModule } from '@angular/common/http';
import { UserModule } from './user/user.module';
import { M8TranslationLoader } from './translation/m8-translation-loader';
import { GraphQLModule } from './graphql.module';
import { SettingsProvider } from './shared/settings.provider';
import { Apollo } from 'apollo-angular';
import { AuthenticationModule } from './authentication/authentication.module';
import { ContentService } from './content/content.service';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from './error/error.module';
import { DialogModule } from './core/dialog/dialog.module';
import { MxLibraryAngularModule } from '@motivforce/mx-library-angular';
import { environment } from 'src/environments/environment';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeKo from '@angular/common/locales/ko';
import localeJa from '@angular/common/locales/ja';
import localeZh from '@angular/common/locales/zh';
import { MastercardModule } from './mastercard/mastercard.module';

registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeRu);
registerLocaleData(localeKo);
registerLocaleData(localeJa);
registerLocaleData(localeZh);

export function M8TranslationLoaderFactory(apollo: Apollo, settingsProvider: SettingsProvider) {
  return new M8TranslationLoader(apollo, settingsProvider);
}

export function settingsProviderFactory(settingsProvider: SettingsProvider) {
  return () => settingsProvider.init();
}

@NgModule({
  declarations: [AppComponent, AdminPanelComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyAngularModule,
    NgbModule,

    LayoutModule,
    ErrorModule,
    SupportModule,
    UserModule,
    HttpClientModule,
    AuthenticationModule,
    ReactiveFormsModule,
    FormsModule,
    MxLibraryAngularModule.forRoot({ environment }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: M8TranslationLoaderFactory,
        deps: [Apollo, SettingsProvider],
      },
    }),
    HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      passThruUnknownUrl: true,
      dataEncapsulation: false,
      delay: 1000,
    }),
    GraphQLModule,
    DialogModule,
  ],
  providers: [
    AmplifyService,
    ContentService,
    SettingsProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: settingsProviderFactory,
      deps: [SettingsProvider],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreTokenInterceptor,
      multi: true,
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
