import { Injectable } from '@angular/core';
import {
  BasePage,
  DialogNgbService,
  MxLoggerService,
  SearchResult,
} from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { UserKyiSaleGroup } from '../model/user/user-kyi-sale-group';
import { UserKyiSaleGroupSearch } from '../model/user/user-kyi-sale-group-search';
import { SalesClaimRestService } from '../api/rest/sales-claim-rest.service';
import { UserKyiSaleAction } from '../model/user/user-kyi-sale-action';
import { UserKyiSale } from '../model/user/user-kyi-sale';
import { TranslateService } from '@ngx-translate/core';
import { UserUnclaimedSalesCount } from '../model/user/user-kyi-unclaimed-sales-count';

@Injectable({
  providedIn: 'root',
})
export class SalesClaimStoreService {
  private readonly _currentSaleGroupSearchResult = new BehaviorSubject<
    SearchResult<UserKyiSaleGroup>
  >(null);
  readonly currentSaleGroupSearchResult$ = this._currentSaleGroupSearchResult.asObservable();

  private readonly _currentSaleSearchResult = new BehaviorSubject<SearchResult<UserKyiSale>>(null);
  readonly currentSaleSearchResult$ = this._currentSaleSearchResult.asObservable();

  private readonly _currentSaleGroup = new BehaviorSubject<UserKyiSaleGroup>(null);
  readonly currentSaleGroup$ = this._currentSaleGroup.asObservable();

  private readonly _currentSaleAction = new BehaviorSubject<UserKyiSaleAction>(null);
  readonly currentSaleAction$ = this._currentSaleAction.asObservable();

  private readonly _currentUnclaimedSalesCount = new BehaviorSubject<UserUnclaimedSalesCount>(null);
  readonly currentUnclaimedSalesCount$ = this._currentUnclaimedSalesCount.asObservable();

  constructor(
    private salesClaimRest: SalesClaimRestService,
    private mxLogger: MxLoggerService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
    private translateService: TranslateService,
  ) {}

  get currentSaleGroupSearchResult(): SearchResult<UserKyiSaleGroup> {
    return this._currentSaleGroupSearchResult.value;
  }

  get currentSaleSearchResult(): SearchResult<UserKyiSale> {
    return this._currentSaleSearchResult.value;
  }

  get currentSaleGroup(): UserKyiSaleGroup {
    return this._currentSaleGroup.value;
  }

  get currentSaleAction(): UserKyiSaleAction {
    return this._currentSaleAction.value;
  }

  searchUserSaleGroups(saleGroupSearch: UserKyiSaleGroupSearch): void {
    this.isLoadingService.add(
      this.salesClaimRest
        .searchUserSaleGroups(saleGroupSearch)
        .subscribe((searchResult: SearchResult<UserKyiSaleGroup>) => {
          this.mxLogger.debug(
            'SalesClaimStoreService',
            'searchUserSaleGroups() searchResult=',
            searchResult,
          );

          this._currentSaleGroupSearchResult.next(searchResult);
        }),
    );
  }

  getUserUnclaimedSalesCount(): void {
    this.isLoadingService.add(
      this.salesClaimRest
        .getUserUnclaimedSalesCount()
        .subscribe((count: UserUnclaimedSalesCount) => {
          this.mxLogger.debug(
            'SalesClaimStoreService',
            'getUserUnclaimedSalesCount() count=',
            count,
          );

          this._currentUnclaimedSalesCount.next(count);
        }),
    );
  }

  doSaleAcion(claimAction: UserKyiSaleAction): void {
    const doSaleActionRest = () => {
      this.isLoadingService.add(
        this.salesClaimRest.doSaleAcion(claimAction).subscribe((saleAction: UserKyiSaleAction) => {
          let message = 'Your sale has been successfully claimed.';
          if (claimAction.showUnclaim) {
            message =
              'You have successfully unclaimed the invoice.  This is now available to be claimed.';
            this.dialog.openNotification(
              [this.translateService.instant(message)],
              this.translateService.instant('Important'),
            );
          } else {
            this.dialog.openNotification(
              [this.translateService.instant(message)],
              this.translateService.instant('Important'),
            );
          }

          this._currentSaleAction.next(saleAction);
        }),
      );
    };

    if (!claimAction.showUnclaim && claimAction.options && claimAction.options.includes('FIE')) {
      this.dialog.openFeatureDialog('fieIncentiveDialog', [claimAction], { size: 'lg' }).then(
        () => {
          doSaleActionRest();
        },
        (reason) => {
          this.mxLogger.trace(
            'SalesClaimStoreService',
            'doSaleAcion() fieIncentiveDialog dismissed',
          );
        },
      );
    } else {
      doSaleActionRest();
    }
  }

  searchUserSales(saleGroupId: number, saleSearch: BasePage): void {
    this.isLoadingService.add(
      this.salesClaimRest
        .searchUserSales(saleGroupId, saleSearch)
        .subscribe((searchResult: SearchResult<UserKyiSale>) => {
          this.mxLogger.debug(
            'SalesClaimStoreService',
            'searchUserSales() searchResult=',
            searchResult,
          );

          this._currentSaleSearchResult.next(searchResult);
        }),
    );
  }

  getUserSaleGroup(saleGroupId: number): void {
    this.isLoadingService.add(
      this.salesClaimRest.getUserSaleGroup(saleGroupId).subscribe((saleGroup: UserKyiSaleGroup) => {
        this.mxLogger.debug('SalesClaimStoreService', 'getUserSaleGroup() saleGroup=', saleGroup);

        this._currentSaleGroup.next(saleGroup);
      }),
    );
  }

  donate(claimAction: UserKyiSaleAction): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.salesClaimRest.donate(claimAction).subscribe(
          () => {
            resolve(true);
          },
          () => resolve(false),
        ),
      );
    });
  }

  submitSale(formDetails: any): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.salesClaimRest.submitSale(formDetails).subscribe(
          () => {
            this.dialog
              .openNotification(
                [
                  this.translateService.instant(
                    'Your form has been successfully submitted. Please check your email for further details.',
                  ),
                ],
                this.translateService.instant('Important'),
              )
              .finally(() => resolve(true));
          },
          () => resolve(false),
        ),
      );
    });
  }
}
