<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="singleMessage" [innerHtml]="message"></div>
  <div *ngIf="!singleMessage">
    <div *ngFor="let messageItem of message">{{ messageItem }}</div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="submit" (click)="close('Save click')">OK</button>
</div>
