<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-sm-12 pd-0 pt-50" [innerHtml]="body"></div>
    </div>
    <div class="row" *ngIf="!accepted">
      <div class="col-sm-12 pd-0">
        <button type="submit" (click)="accept()" class="btn btn-primary">
          <i class="fa fa-spinner fa-spin" *ngIf="updating"></i>
          {{ 'Agree, and continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-default-layout>
