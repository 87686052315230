import { Injectable } from '@angular/core';
import { ApiRestService, BasePage, SearchResult } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { BaseCoreService } from '../../../shared/base-core-service';
import { UserKyiSale } from '../../model/user/user-kyi-sale';
import { UserKyiSaleAction } from '../../model/user/user-kyi-sale-action';
import { UserKyiSaleGroup } from '../../model/user/user-kyi-sale-group';
import { UserKyiSaleGroupSearch } from '../../model/user/user-kyi-sale-group-search';
import { UserUnclaimedSalesCount } from '../../model/user/user-kyi-unclaimed-sales-count';

@Injectable({
  providedIn: 'root',
})
export class SalesClaimRestService extends BaseCoreService {
  kyiApiUrl = `${this.memberBaseUrl}`;

  constructor(protected apiRestService: ApiRestService) {
    super('salesclaim', apiRestService);
  }

  searchUserSaleGroups(
    saleGroupSearch: UserKyiSaleGroupSearch,
  ): Observable<SearchResult<UserKyiSaleGroup>> {
    return this.apiRestService.post<SearchResult<UserKyiSaleGroup>>(
      `${this.kyiApiUrl}/search/`,
      saleGroupSearch,
    );
  }

  getUserUnclaimedSalesCount(): Observable<UserUnclaimedSalesCount> {
    return this.apiRestService.get<UserUnclaimedSalesCount>(
      `${this.kyiApiUrl}/unclaimed/count/`,
      null,
    );
  }

  doSaleAcion(claimAction: UserKyiSaleAction): Observable<UserKyiSaleAction> {
    return this.apiRestService.post<UserKyiSaleAction>(
      `${this.kyiApiUrl}/claim/` + claimAction.invoiceGroupId,
      claimAction,
    );
  }

  searchUserSales(saleGroupId: number, listPage: BasePage): Observable<SearchResult<UserKyiSale>> {
    return this.apiRestService.post<SearchResult<UserKyiSale>>(
      `${this.kyiApiUrl}/invoice-group-sales-details/${saleGroupId}`,
      listPage,
    );
  }

  getUserSaleGroup(saleGroupId: number): Observable<UserKyiSaleGroup> {
    return this.apiRestService.get<UserKyiSaleGroup>(
      `${this.kyiApiUrl}/invoice-group/${saleGroupId}`,
    );
  }

  donate(claimAction: UserKyiSaleAction): Observable<void> {
    return this.apiRestService.post<void>(`${this.kyiApiUrl}/donate`, claimAction);
  }

  submitSale(formDetails: any): Observable<void> {
    return this.apiRestService.post<void>(`${this.kyiApiUrl}/sale-form`, formDetails);
  }
}
