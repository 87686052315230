<app-default-layout>
  <div class="container content">
    <div class="row">
      <div class="col-md-2 side-content">
        <p><strong>Report missing card</strong></p>
      </div>
      <div class="col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-md-9 form-box">
        <form [formGroup]="reportMissingForm" (ngSubmit)="onSubmit()" *ngIf="!reportSuccessful">
          <div class="row">
            <div class="col-sm-8" [innerHtml]="body"></div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'Missing Reason' | translate }} *</label>
                <select formControlName="cardStatus" class="form-control">
                  <!-- <option disabled>{{ 'Select' | translate }}</option> -->
                  <option value="{{ idType.key }}" *ngFor="let idType of lostTypes">
                    {{ idType.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'Address Line 1' | translate }} *</label>
                <input class="form-control" type="text" formControlName="addressLine1" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'Address Line 2' | translate }}</label>
                <input class="form-control" type="text" formControlName="addressLine2" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'City' | translate }} *</label>
                <input class="form-control" type="text" formControlName="city" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'State' | translate }}</label>
                <select formControlName="state" class="form-control">
                  <option disabled>{{ 'Select' | translate }}</option>
                  <option value="{{ state.key }}" *ngFor="let state of states">
                    {{ state.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'Zip / Postal Code' | translate }} *</label>
                <input class="form-control" type="text" formControlName="postalCode" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="form-group">
                <label>{{ 'Country' | translate }}</label>
                <input class="form-control" type="text" formControlName="country" readonly />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-8">
              <button type="submit" class="btn btn-primary">
                {{ 'Report Missing' | translate
                }}<span class="arrow"
                  ><img src="../../../../assets/images/arrow-right-white.svg"
                /></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-default-layout>

<div class="layout-boxed content">
  <div *ngIf="reportSuccessful">
    <p>{{ 'Reported missing complete' | translate }}</p>
  </div>
</div>
