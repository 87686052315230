import { Injectable } from '@angular/core';
import { Settings } from '../entities/settings';
import Auth from '@aws-amplify/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ContentService } from '../content/content.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SettingsService } from '../content/shared/settings.service';
import { AdminService } from '../admin-panel/admin.service';
import { SettingsStoreService } from '../core/store/settings-store.service';

@Injectable()
export class SettingsProvider {
  public settings: Settings = new Settings();
  public settingsChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public initComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public refresUserSettingsSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private contentService: ContentService,
    private settingsService: SettingsService,
    private adminService: AdminService,
    private settingsStore: SettingsStoreService,
  ) {}

  async init(): Promise<any> {
    // TODO: set the language from the settings api when it is done
    this.settings.language = 'en-US';

    let currentUser;

    try {
      currentUser = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.log('User not authenticated');
    }

    const settings = await this.settingsService.getSettings(window.location.href);

    this.settings = { ...this.settings, ...settings };

    console.log(settings);

    if (currentUser) {
      await this.refreshUserSettings();
    }

    this.settingsChanged.next(true);
    this.adminService.settings.next(this.settings);

    this.initComplete.next(true);
    return this.settings;
  }

  async refreshUserSettings() {
    const settings = await this.http
      .get(`${environment.api.core.baseUrl}/members/users/settings`, {})
      .toPromise();
    this.settings = { ...this.settings, ...settings };
    this.adminService.settings.next(this.settings);
    await this.contentService.initMenu(this.settings.data.language);
    this.settingsChanged.next(true);
    console.log(this.settings);

    this.settingsStore.getUserSettings();
  }

  async getRemoteUserSettings() {
    const settings: any = await this.http
      .get(`${environment.api.core.baseUrl}/members/users/settings`, {})
      .toPromise();

    return settings;
  }
}
