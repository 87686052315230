<app-default-layout [title]="'<h1>Contact Us</h1>'" [showBackToLanding]="true">
  <div class="container content">
    <div class="row">
      <div class="col-lg-2 col-md-12" [innerHtml]="sideContent"></div>
      <div class="col-md-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-8 col-md-12">
        <div [innerHtml]="bodyContent"></div>
        <sp-contact-form></sp-contact-form>
      </div>
      <div class="col-lg-1">
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</app-default-layout>
