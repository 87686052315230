<app-default-layout
  [secondLevelNavItems]="[
    { url: '/pre-sale-activities', label: 'Flash Pre-sale Activities' | translate },
    { url: '/san-health', label: 'SAN Health Assessments' | translate }
  ]"
>
  <div class="container content content-bottom-border">
    <div class="row">
      <div class="col-md-3 side-content mt-25">
        <p>
          <strong>{{ 'View your previous pre-sale activities' | translate }}</strong>
        </p>
        <div class="mt-30">
          <a
            class="btn btn-primary"
            type="button"
            [routerLink]="showTechnicalAssessments ? '/san-health' : '/pre-sale-activities'"
          >
            {{ 'Back' | translate }}
            <span class="arrow"><img src="../../../../assets/images/arrow-left-white.svg" /></span>
          </a>
        </div>
      </div>

      <div class="col-md-9" *ngIf="!showTechnicalAssessments">
        <div class="mb-20" [innerHtml]="content"></div>
        <previously-submitted-meetings></previously-submitted-meetings>
        <previously-submitted-assessments></previously-submitted-assessments>
      </div>
      <div class="col-md-9" *ngIf="showTechnicalAssessments">
        <div class="mb-20" [innerHtml]="content"></div>
        <previously-submitted-technical-assessments></previously-submitted-technical-assessments>
      </div>
    </div>
  </div>
</app-default-layout>
