<div class="layout-boxed">
  <div class="terms">
    <a [routerLink]="['/terms-and-conditions']">{{ 'Terms & Conditions' | translate }}</a> |
    <a [routerLink]="['/privacy-policy']">{{ 'Privacy' | translate }}</a>
  </div>
  <div class="lower-footer-container">
    <a href="https://twitter.com/ibmpartners" target="_blank"><i class="fab fa-twitter"></i></a>
    <a href="https://www.linkedin.com/m/login/" target="_blank"><i class="fab fa-linkedin-in ml-5"></i></a>
  </div>
</div>
