import { Component, OnInit } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-and-conditions-dialog-component',
  templateUrl: './terms-and-conditions-dialog-component.component.html',
  styleUrls: ['./terms-and-conditions-dialog-component.component.scss'],
})
export class TermsAndConditionsDialogComponentComponent
  extends AbstractDialogComponent
  implements OnInit
{
  content: string;

  constructor(public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs.length > 0) {
      this.content = this.inputs[0];
    }
  }
}
