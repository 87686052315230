import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from '../layout.service';
import { DynamicLayout } from 'src/app/models/dynamic-layout.model';
import { Content } from 'src/app/models/content.model';

@Component({
  selector: 'app-contact-form-page',
  templateUrl: './contact-form-page.component.html',
  styleUrls: ['./contact-form-page.component.scss'],
})
export class ContactFormPageComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  bodyContent: string;
  sideContent: string;

  constructor(private layoutService: LayoutService) {}
  ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyContent = this.baseContent.fields.find((f) => f.name === 'Body');
    const sideContent = this.baseContent.fields.find((f) => f.name === 'Side Content');
    // this.additionalContent = additionalContent ? additionalContent.value.value : null;

    this.bodyContent = bodyContent.value.value;
    this.sideContent = sideContent.value.value;

    this.layoutService.changeBanner({
      title: titleField.value.value,
      imageUrl: bannerField.value.url,
    });
  }
}
