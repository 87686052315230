<div class="layout-boxed content">
  <div class="row">
    <div class="col-sm-12 pd-0" [innerHtml]="body"></div>
  </div>
  <div class="row">
    <div class="col-sm-12 pd-0"></div>
  </div>
</div>

<form [formGroup]="chooseCompanyForm" (ngSubmit)="onSubmit()">
  <div class="layout-boxed content">
    <div class="row" *ngIf="companyList">
      <div class="form-group col-sm-12 pd-0">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>
                {{ 'Select primary company' | translate | uppercase }}
              </label>
              <select class="form-control" formControlName="id">
                <option>{{ 'Select primary company' | translate }}</option>
                <option value="{{ company.id }}" *ngFor="let company of companyList">
                  {{ company.name }} - {{ company.organizationNumber }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary">
              <i class="fa fa-spinner fa-spin" *ngIf="updating"></i>
              {{ 'Submit' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
