<app-home-layout [showButton]="true" [bigBanner]="true" [carouselBanners]="carouselBanners">
  <div class="container content">
    <div class="row">
      <div
        class="col-lg-2 col-md-12 col-sm-12 side-content top-container"
        [innerHtml]="sectionOneSideContent"
      ></div>
      <div class="col-lg-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12">
        <h2>
          <strong>Hello {{ user?.firstName }},</strong>
        </h2>
        <div [innerHtml]="sectionOneMainContent"></div>
        <div class="mt-30">
          <ng-container *ngFor="let yearRequirementList of yearRequirements">
            <div class="row" *ngFor="let yearRequirement of yearRequirementList?.requirements">
              <div
                class="col-sm-12 year-requirement-wrapper"
                *ngIf="
                  yearRequirement.sortOrder != 0 &&
                  yearRequirement.year === currentYear &&
                  yearRequirement.contentKey
                "
              >
                <div class="status">
                  <div class="icon">
                    <img
                      src="../../../assets/images/icon-filled-correct.png"
                      *ngIf="yearRequirement.completed"
                    />
                    <img
                      src="../../../assets/images/icon-filled-error.png"
                      *ngIf="!yearRequirement.completed"
                    />
                  </div>
                  <div class="message" [innerHtml]="yearRequirement.contentKey | translate"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="container content">
    <div class="row">
      <div
        class="col-lg-2 col-md-12 col-sm-12 side-content title"
        [innerHtml]="sectionTwoSideContent"
      ></div>
      <div class="col-lg-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div class="mb-20" [innerHtml]="sectionTwoMainContentHeader"></div>
        <div class="mb-20">
          <img [src]="sectionTwoMainContentImage" style="width: 100%; height: auto" />
        </div>
        <div class="mb-20" [innerHtml]="sectionTwoMainContentFooter"></div>
        <div class="mb-20">
          <img [src]="sectionTwoSecondMainContentImage" style="width: 100%; height: auto" />
        </div>
        <div class="form-container">
          <div class="mb-20 mt-20 pt-20" [innerHtml]="sectionTwoMainContentForm"></div>
          <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Name of customer' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="companyName"
                    type="text"
                    style="width: 100%"
                    placeholder="Customer name"
                  />
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <label>{{ 'Country' | translate }}: *</label>
                  <input
                    class="form-control"
                    formControlName="country"
                    type="text"
                    style="width: 100%"
                    placeholder="Name of country"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-sm-12">
                <button class="btn btn-primary" type="submit">
                  {{ 'Submit' | translate }}
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container content">
    <div class="row bottom-container">
      <div
        class="col-lg-2 col-md-12 col-sm-12 side-content title"
        [innerHtml]="sectionThreeSideContent"
      ></div>
      <div class="col-lg-1">
        <p>&nbsp;</p>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div [innerHtml]="sectionThreeMainContent"></div>
      </div>
    </div>
  </div>
</app-home-layout>
