import { Injectable } from '@angular/core';
import { Banner } from '../entities/banner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public banner: BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null);
  public refreshed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  changeBanner(banner: Banner) {
    this.banner.next(banner);
  }

  refresh() {
    this.refreshed.next(true);
  }
}
