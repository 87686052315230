import { Component, OnInit } from '@angular/core';
import { SettingsStoreService } from 'src/app/core/store/settings-store.service';
import { AccountService } from '../../account/account.service';
import { PointsSummary } from '../../account/points-summary-models';

@Component({
  selector: 'app-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss'],
})
export class PointsSummaryComponent implements OnInit {
  public pointsSummary: PointsSummary;
  public lastYearSummary: PointsSummary;

  constructor(private accountService: AccountService, public settingsStore: SettingsStoreService) {}

  ngOnInit() {
    this.accountService.getPointsSummary().then((data) => {
      this.pointsSummary = data;
      this.accountService.getYearSummaries().subscribe((yearData) => {
        const pointsSummaryLast = yearData.filter((x) => x.programYear === 2021);
        const pointsSummaryCurrent = yearData.filter((x) => x.programYear === 2022);
        const currentSummary = this.pointsSummary;

        this.pointsSummary = {
          availableBalance:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].availablePoints
              : 0,
          earnedPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].totalPoints
              : 0,
          redeemedLearnAndPromoPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].redeemedLearnAndPromo
              : 0,
          pendingLearnAndPromo:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].pendingPoints
              : 0,
          expiredPoints: currentSummary.expiredPoints,
          incentiveAvailableBalance: currentSummary.incentiveAvailableBalance,
          incentiveEarnedPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].incentivePoints
              : 0,
          taxPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0] ? pointsSummaryCurrent[0].taxPoints : 0,
          incentiveRedeemedPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].redeemedIncentive
              : 0,
          incentivePendingPoints: currentSummary.incentivePendingPoints,
          companyPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].companyPoints
              : 0,
          learnPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].learnPoints
              : 0,
          promoPoints:
            pointsSummaryCurrent && pointsSummaryCurrent[0]
              ? pointsSummaryCurrent[0].promoPoints
              : 0,
          tierCredits: currentSummary.tierCredits,
          tierStatus: currentSummary.tierStatus,
          expirationDate: currentSummary.expirationDate,
        };

        this.lastYearSummary = {
          availableBalance:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].availablePoints : 0,
          earnedPoints:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].totalPoints : 0,
          redeemedLearnAndPromoPoints:
            pointsSummaryLast && pointsSummaryLast[0]
              ? pointsSummaryLast[0].redeemedLearnAndPromo
              : 0,
          pendingLearnAndPromo:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].pendingPoints : 0,
          expiredPoints: currentSummary.expiredPoints,
          incentiveAvailableBalance: currentSummary.incentiveAvailableBalance,
          incentiveEarnedPoints:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].incentivePoints : 0,
          taxPoints: pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].taxPoints : 0,
          incentiveRedeemedPoints:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].redeemedIncentive : 0,
          incentivePendingPoints: currentSummary.incentivePendingPoints,
          companyPoints:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].companyPoints : 0,
          learnPoints:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].learnPoints : 0,
          promoPoints:
            pointsSummaryLast && pointsSummaryLast[0] ? pointsSummaryLast[0].promoPoints : 0,
          tierCredits: currentSummary.tierCredits,
          tierStatus: currentSummary.tierStatus,
          expirationDate:
            pointsSummaryLast && pointsSummaryLast[0]
              ? pointsSummaryLast[0].expirationDate
              : currentSummary.expirationDate,
        };
      });
    });
  }
}
