<div class="header">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <img class="logo" alt="" src="assets/images/ibm-logo.png" />
      </div>
    </div>
  </div>
</div>
<div class="middle">
  <img src="assets/images/Landing-banner.jpg" style="width: 100%; height: auto" />
  <div class="d-none d-lg-block container">
    <div class="title">
      <h1>IBM Incentive</h1>
      <h1>Marketplace</h1>
    </div>
  </div>

  <div class="container content">
    <div class="boxes col-md-12">
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pr-10">
        <p>
          Click on <strong>Manage my profile</strong> to check your eligibility and to ensure you
          have met all the registration requirements to participate fully in the IBM Flash Rewards
          Program.
        </p>
        <p class="d-none d-lg-block">
          You can access the <strong>Know Your IBM Offering</strong> by clicking on the respective
          tile.
        </p>
        <p>
          If you need any support please do contact us at
          <a href="mailto:flash@kyirewards.com">flash@kyirewards.com</a> for
          <strong>Flash Rewards</strong>
          <span class="d-none d-lg-block"
            >or <a href="mailto:service@kyirewards.com">service@kyirewards.com</a> for
            <strong>Know Your IBM</strong></span
          >
          and we will be delighted to support you.
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pd-0 pr-10 mb-20">
        <!-- -->
        <a [routerLink]="'/auth/my-profile'">
          <img src="assets/images/manage-my-profile.jpg" />
          <div class="box-text">
            Manage my profile
            <span class="arrow">→</span>
          </div>
        </a>
      </div>

      <div class="d-none d-lg-block col-lg-3 col-md-3 pd-0 pr-10 mb-20">
        <a href="https://kyirewards.com">
          <img src="assets/images/kyi-image.jpg" />
          <div class="box-text">Know Your IBM<br />for Systems<span class="arrow">→</span></div>
        </a>
      </div>

      <div class="col-lg-6 col-md-6" *ngIf="showPower"></div>

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pd-0 pr-10 mb-20">
        <a [routerLink]="'/home'">
          <img src="assets/images/flash-rewards-image.jpg" />
          <div class="box-text">IBM Flash Rewards<span class="arrow">→</span></div>
        </a>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pd-0 pr-10 mb-20" *ngIf="showPower">
        <a href="https://powerrewards.kyirewards.com">
          <img src="assets/images/power-rewards-image.jpg" />
          <div class="box-text">IBM Power Rewards<span class="arrow">→</span></div>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <p>
          <strong
            >Part of www.ibm.com/partnerworld/knowyouribm and
            www.ibm/partnerworld/flashforce</strong
          ><br />
          IBM, the IBM logo, are trademarks of IBM Corp registered in many jurisdictions worldwide.
          A current list of IBM trademarks is available on the Web at “Copyright and trademark
          information” at www.ibm.com/legal/copytrade.shtml (US) . Other company, products and
          service marks may be trade marks or service marks of others. Copyright IBM.
        </p>
      </div>
    </div>
  </div>
</div>
